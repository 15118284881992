import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import RwireClickableField from "../components/common/common-functions/rwire-clickable-field";
import { setApp } from "../action/app";

const Container = (props) => {
  return <RwireClickableField {...props} />;
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onSetApp: setApp,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(Container);
