import { uuidv4 } from "../../action/app";

export const fieldOptions = [
  {
    Text: [
      {
        valuecode: "Abstract",
        sortcode: "AB",
        placeholder: "E.g. Wireless OR Mobile",
      },
      {
        valuecode: "Claims/Abstract",
        sortcode: "CLA",
        placeholder: "E.g. Wireless OR Mobile",
      },
      {
        valuecode: "Claims",
        sortcode: "CL",
        placeholder: "E.g. Wireless OR Mobile",
      },
      {
        valuecode: "First Claim",
        sortcode: "FCL",
        placeholder: "E.g. Wireless OR Mobile",
      },
      {
        valuecode: "Title",
        sortcode: "TI",
        placeholder: "E.g. Wireless OR Mobile",
      },
      {
        valuecode: "Title/Abstract",
        sortcode: "TA",
        placeholder: "E.g. Wireless OR Mobile",
      },
      {
        valuecode: "Title/Abstract/Claims",
        sortcode: "TAC",
        placeholder: "E.g. Wireless OR Mobile",
      },
      {
        valuecode: "Title/Claims",
        sortcode: "TC",
        placeholder: "E.g. Wireless OR Mobile",
      },
      {
        valuecode: "Full-Text",
        sortcode: "ALL",
        placeholder: "E.g. Wireless OR Mobile",
      },
      {
        valuecode: "Description",
        sortcode: "DESC",
        placeholder: "E.g. Wireless OR Mobile",
      },
      {
        valuecode: "Dependent Claims",
        sortcode: "DCL",
        placeholder: "E.g. Wireless OR Mobile",
      },

      {
        valuecode: "Independent Claims",
        sortcode: "ICL",
        placeholder: "E.g. Wireless OR Mobile",
      },
      {
        valuecode: "Claims/Desc",
        sortcode: "CD",
        placeholder: "E.g. Wireless OR Mobile",
      },
    ],
  },
  {
    Classification: [
      {
        valuecode: "CPC",
        sortcode: "CPC",
        placeholder: "E.g. H04W25/00",
      },
      {
        valuecode: "CPC-Primary",
        sortcode: "CPCP",
        placeholder: "E.g. H04W25/00",
      },
      {
        valuecode: "ECLA Class",
        sortcode: "ECL",
        placeholder: "E.g. H04W25/00",
      },
      {
        valuecode: "IPC",
        sortcode: "IPC",
        placeholder: "E.g. H04W25/00",
      },
      {
        valuecode: "IPC/CPC",
        sortcode: "PCL",
        placeholder: "E.g. H04W25/00",
      },
      {
        valuecode: "JP-FI/F-Terms",
        sortcode: "JCL",
        placeholder: "E.g. 3B011/AA05",
      },
      {
        valuecode: "Locarno Class",
        sortcode: "LC",
        placeholder: "E.g. 2606",
      },
      {
        valuecode: "US Class",
        sortcode: "USC",
        placeholder: "E.g. 036/83",
      },
    ],
  },
  {
    Botanic: [
      {
        valuecode: "Botanic Latin Name",
        sortcode: "BON",
        placeholder: "E.g. Cupressocyparis Leylandii",
      },
      {
        valuecode: "Botanic Variety",
        sortcode: "BOV",
        placeholder: "E.g. Green Rocket",
      },
    ],
  },
  {
    "People/Organization": [
      {
        valuecode: "Assignee/Applicant",
        sortcode: "AAP",
        placeholder: "E.g. Samsung OR Nokia",
      },
      {
        valuecode: "Attorney/Agent",
        sortcode: "AG",
        placeholder: "E.g. Alston & Bird LLP",
      },
      {
        valuecode: "Correspondent",
        sortcode: "CR",
        placeholder: "E.g. Alston & Bird LLP",
      },
      {
        valuecode: "Current Assignee-Normalized",
        sortcode: "CAN",
        placeholder: "E.g. Samsung OR Nokia",
      },
      {
        valuecode: "Current Assignee -Original",
        sortcode: "CA",
        placeholder: "E.g. Samsung OR Nokia",
      },
      {
        valuecode: " Current Assignee-Standardized",
        sortcode: "CAS",
        placeholder: "E.g. Samsung OR Nokia",
      },
      {
        valuecode: "Examiner",
        sortcode: "EX",
        placeholder: "E.g. Durand Georges",
      },
      {
        valuecode: "Inventor",
        sortcode: "IN",
        placeholder: "E.g. Durand Georges",
      },
      {
        valuecode: "Assignee/Applicant - Original",
        sortcode: "AAPO",
        placeholder: "E.g. Samsung OR (Durand Georges)",
      },
      {
        valuecode: "Assignee/Applicant - Standardized",
        sortcode: "AAPS",
        placeholder: "E.g. Samsung OR (Durand Georges)",
      },
      {
        valuecode: "Assignee/Applicant - Normalized",
        sortcode: "AAPN",
        placeholder: "E.g. Samsung OR (Durand Georges)",
      },
    ],
  },
  {
    Number: [
      {
        valuecode: "Application No.",
        sortcode: "AN",
        placeholder: "E.g. EP81401743A",
      },
      {
        valuecode: "PCT App No.",
        sortcode: "PCT",
        placeholder: "E.g. WO1997GB2698A OR WO1994021368A1",
      },
      {
        valuecode: "PCT Pub No.",
        sortcode: "PCTN",
        placeholder: "E.g. WO1997GB2698A OR WO1994021368A1",
      },
      {
        valuecode: "Priority No.",
        sortcode: "PRN",
        placeholder: "E.g. FR80023452",
      },
      {
        valuecode: "Provisional App No.",
        sortcode: "PVN",
        placeholder: "E.g. US199756910P",
      },
      {
        valuecode: "Publication No.",
        sortcode: "PN",
        placeholder: "E.g. EP51542A2",
      },
      {
        valuecode: "Complete Family",
        sortcode: "CF",
        placeholder: "E.g. EP3809991A1",
      },
      {
        valuecode: "Domestic Family",
        sortcode: "DF",
        placeholder: "E.g. EP3809991A1",
      },
      {
        valuecode: "Extended Family",
        sortcode: "EF",
        placeholder: "E.g. EP3809991A1",
      },
      {
        valuecode: "Main Family",
        sortcode: "MF",
        placeholder: "E.g. EP3809991A1",
      },
      {
        valuecode: "Simple Family",
        sortcode: "SF",
        placeholder: "E.g. EP3809991A1",
      },
    ],
  },
  {
    Citation: [
      {
        valuecode: "Cited Refs Details - NPL",
        sortcode: "BCN",
        placeholder: "E.g. Contactless Card Reader",
      },
      {
        valuecode: "Cited Refs Details - Patents",
        sortcode: "BCP",
        placeholder: "E.g. CN104012010A",
      },
      {
        valuecode: "Citing Refs Details - Patent",
        sortcode: "FCP",
        placeholder: "E.g. CN104012010A",
      },
    ],
  },
  {
    Address: [
      {
        valuecode: "Applicant Address",
        sortcode: "APA",
        placeholder: "E.g. California, US",
      },
      {
        valuecode: "Assignee Address",
        sortcode: "AA",
        placeholder: "E.g. California, US",
      },
      {
        valuecode: "Assignee Country",
        sortcode: "ACC",
        placeholder: "E.g. US OR EP",
      },
      {
        valuecode: "Attorney/Agent Address",
        sortcode: "AGA",
        placeholder: "E.g. California, US",
      },
      {
        valuecode: "Correspondent Address",
        sortcode: "CRA",
        placeholder: "E.g. California, US",
      },
      {
        valuecode: "Current Assignee Address",
        sortcode: "CAA",
        placeholder: "E.g. California, US",
      },
      {
        valuecode: "Inventor Address",
        sortcode: "INA",
        placeholder: "E.g. California, US",
      },
    ],
  },
  {
    Others: [
      {
        valuecode: "Assignee Count",
        sortcode: "ACN",
        placeholder: "E.g. 2",
      },
      {
        valuecode: "Publication Kind Code",
        sortcode: "PKC",
        placeholder: "E.g. B2",
      },
      {
        valuecode: "Publication Language",
        sortcode: "PL",
        placeholder: "E.g. eng",
      },
      {
        valuecode: "Related Documents",
        sortcode: "RD",
        placeholder: "E.g. FR80023452",
      },
    ],
  },
];
export const fieldOptionsDate = [
  {
    valuecode: "Application Date",
    sortcode: "AD",
  },
  {
    valuecode: "Application Year",
    sortcode: "AY",
  },
  {
    valuecode: "PCT App Date",
    sortcode: "PCTAD",
  },
  {
    valuecode: "PCT Pub Date",
    sortcode: "PCTPD",
  },
  {
    valuecode: "Priority Date",
    sortcode: "PRD",
  },
  {
    valuecode: "Earliest Priority Date",
    sortcode: "EPRD",
  },
  {
    valuecode: "Earliest Priority Year",
    sortcode: "EPRY",
  },
  {
    valuecode: "Priority Year",
    sortcode: "PRY",
  },
  {
    valuecode: "Publication Date",
    sortcode: "PD",
  },
  {
    valuecode: "Publication Year",
    sortcode: "PY",
  },
];

export const fieldOptionsCountry = [
  {
    valuecode: "Publication Country",
    sortcode: "PNC",
    placeholder: "E.g. US OR EP",
  },
  {
    valuecode: "Application Country",
    sortcode: "AC",
    placeholder: "E.g. US OR EP",
  },
  {
    valuecode: "Priority Country",
    sortcode: "PRC",
    placeholder: "E.g. US OR EP",
  },
  {
    valuecode: "PCT Publication Country",
    sortcode: "PCTPC",
    placeholder: "E.g. US OR EP",
  },
  {
    "Designated States-EP": [
      {
        valuecode: "Designated All States-EP",
        sortcode: "DSEP",
        placeholder: "E.g. US OR EP",
      },
      {
        valuecode: "Contracting-States - EP",
        sortcode: "DSEP_CST",
        placeholder: "E.g. US OR EP",
      },
      {
        valuecode: "Extension-States - EP",
        sortcode: "DSEP_EST",
        placeholder: "E.g. US OR EP",
      },
      {
        valuecode: "Validation-States - EP",
        sortcode: "DSEP_VST",
        placeholder: "E.g. US OR EP",
      },
      {
        valuecode: "UP-Participating-States - EP",
        sortcode: "DSEP_PST",
        placeholder: "E.g. US OR EP",
      },
    ],
  },
  {
    "Designated States-PCT": [
      {
        valuecode: "Designated All States-PCT",
        sortcode: "DSPCT",
        placeholder: "E.g. US OR EP",
      },
      {
        valuecode: "Regional Country - PCT",
        sortcode: "DSPCT_RGCN",
        placeholder: "E.g. US OR EP",
      },
      {
        valuecode: "Regional Any-Other-State - PCT",
        sortcode: "DSPCT_AOST",
        placeholder: "E.g. US OR EP",
      },
      {
        valuecode: "Regional-Region-Country - PCT",
        sortcode: "DSPCT_RGNCN",
        placeholder: "E.g. US OR EP",
      },
      {
        valuecode: "PCT-National",
        sortcode: "DSPCT_NT",
        placeholder: "E.g. US OR EP",
      },
      {
        valuecode: "New-Designation-Country - PCT",
        sortcode: "DSPCT_NDSCN",
        placeholder: "E.g. US OR EP",
      },
    ],
  },
];
export const stateValues = ["alive", "dead"];

export const statusValues = ["filed", "granted", "ceased"];

export const typeValues = ["utility", "design", "plant"];

export const pubValues = ["application", "grant"];

export const fieldList = [
  {
    id: uuidv4(),
    name: "State",
    code: "state",
    type: "dropdown",
    query: "ALD",
    filedsinput: "",
    operatersvalue: "AND",
  },
  {
    id: uuidv4(),
    name: "Status",
    type: "dropdown",
    code: "status",
    query: "LST",
    filedsinput: "",
    operatersvalue: "AND",
  },
  {
    id: uuidv4(),
    name: "Type",
    code: "type",
    type: "dropdown",
    query: "PTS",
    filedsinput: "",
    operatersvalue: "AND",
  },
  {
    id: uuidv4(),
    name: "Pub",
    type: "dropdown",
    query: "PT",
    filedsinput: "",
    operatersvalue: "AND",
    code: "pub",
  },
  {
    id: uuidv4(),
    lang: "eng",
    type: "Text",
    dattype: "Text",
    query: "ALL",
    error: "",
    operatersvalue: "AND",
    placeholder: "E.g. Wireless OR Mobile",
    filedsinput: "",
    textLabel: "Text",
  },
  {
    id: uuidv4(),
    lang: "eng",
    type: "Classes",
    dattype: "Text",
    query: "PCL",
    error: "",
    operatersvalue: "AND",
    placeholder: "E.g. H04W25/00",
    filedsinput: "",
    textLabel: "Classification",
  },
  {
    id: uuidv4(),
    lang: "eng",
    type: "Assignee/Applicant",
    dattype: "Text",
    filedsinput: "",
    error: "",
    query: "AAP",
    operatersvalue: "AND",
    placeholder: "E.g. Samsung OR Nokia",
    textLabel: "People/Organization",
  },
  {
    id: uuidv4(),
    lang: "eng",
    type: "Inventor",
    dattype: "Text",
    filedsinput: "",
    error: "",
    query: "IN",
    operatersvalue: "AND",
    placeholder: "E.g. Bikram Kumar",
    textLabel: "People/Organization",
  },
  {
    id: uuidv4(),
    lang: "eng",
    type: "Date",
    dattype: "Date",
    startdate: "",
    enddate: "",
    query: "",
    fielderror: "",
    dateerror: "",
    operatersvalue: "AND",
  },
  {
    id: uuidv4(),
    lang: "eng",
    type: "Country",
    dattype: "Country",
    filedsinput: "",
    query: "",
    error: "",
    countryerror: "",
    speacialcharacter: "",
    operatersvalue: "AND",
  },
];

export const resetFields = [
  {
    id: uuidv4(),
    name: "State",
    code: "state",
    type: "dropdown",
    query: "ALD",
    filedsinput: "",
    operatersvalue: "AND",
  },
  {
    id: uuidv4(),
    name: "Status",
    type: "dropdown",
    code: "status",
    query: "LST",
    filedsinput: "",
    operatersvalue: "AND",
  },
  {
    id: uuidv4(),
    name: "Type",
    code: "type",
    type: "dropdown",
    query: "PTS",
    filedsinput: "",
    operatersvalue: "AND",
  },
  {
    id: uuidv4(),
    name: "Pub",
    type: "dropdown",
    query: "PT",
    filedsinput: "",
    operatersvalue: "AND",
    code: "pub",
  },
  {
    id: uuidv4(),
    lang: "eng",
    type: "Text",
    dattype: "Text",
    query: "ALL",
    error: "",
    operatersvalue: "AND",
    placeholder: "E.g. Wireless OR Mobile",
    filedsinput: "",
    textLabel: "Text",
  },
  {
    id: uuidv4(),
    lang: "eng",
    type: "Classes",
    dattype: "Text",
    query: "PCL",
    error: "",
    operatersvalue: "AND",
    placeholder: "E.g. H04w25/00",
    filedsinput: "",
    textLabel: "Classification",
  },
  {
    id: uuidv4(),
    lang: "eng",
    type: "Assignee/Applicant",
    dattype: "Text",
    filedsinput: "",
    error: "",
    query: "AAP",
    operatersvalue: "AND",
    placeholder: "E.g. Samsung OR Nokia",
    textLabel: "People/Organization",
  },
  {
    id: uuidv4(),
    lang: "eng",
    type: "Inventor",
    dattype: "Text",
    filedsinput: "",
    error: "",
    query: "IN",
    operatersvalue: "AND",
    placeholder: "E.g. Bikram Kumar",
    textLabel: "People/Organization",
  },
  {
    id: uuidv4(),
    lang: "eng",
    type: "Date",
    dattype: "Date",
    startdate: "",
    enddate: "",
    query: "",
    fielderror: "",
    dateerror: "",
    operatersvalue: "AND",
  },
  {
    id: uuidv4(),
    lang: "eng",
    type: "Country",
    dattype: "Country",
    filedsinput: "",
    query: "",
    error: "",
    countryerror: "",
    speacialcharacter: "",
    operatersvalue: "AND",
  },
];

export const keywordFieldsOptions = [
  {
    title: "Abstract",
    code: "AB",
  },
  {
    title: "Claims/Abstract",
    code: "CLA",
  },
  {
    title: "Claims",
    code: "CL",
  },
  {
    title: "First Claim",
    code: "FCL",
  },
  {
    title: "Title",
    code: "TI",
  },
  {
    title: "Title/Abstract",
    code: "TA",
  },
  {
    title: "Title/Abstract/Claims",
    code: "TAC",
  },
  {
    title: "Title/Claims",
    code: "TC",
  },
  {
    title: "Full-Text",
    code: "ALL",
  },
  {
    title: "Description",
    code: "DESC",
  },
  {
    title: "Dependent Claims",
    code: "DCL",
  },

  {
    title: "Independent Claims",
    code: "ICL",
  },
  {
    title: "Claims/Desc",
    code: "CD",
  },
];

export const synonymnsCountOptions = [
  {
    title: "5",
    code: 5,
  },
  {
    title: "10",
    code: 10,
  },
  {
    title: "15",
    code: 15,
  },
  {
    title: "20",
    code: 20,
  },
  {
    title: "25",
    code: 25,
  },
];
