import { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  setReportRelevantResult,
  getReportProjectRelevantResultFromPN,
  addKeyFeaturesRelevantResult,
  fetPatentDetails,
} from "../../action/report/report-relevant-results";
import { getReportProjectNotes } from "../../action/report/report-notes";
import { setFilterFields } from "../../action/result-table";
import FirstSectionMain from "../../components/report-new/parent-componnet";

const Container = (props) => {
  const projectId = sessionStorage.getItem("projectId");
  const { onGetReportProjectRelevantResultFromPN } = props;

  useEffect(() => {
    onGetReportProjectRelevantResultFromPN({ projectId, selectedId: [] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);
  return <FirstSectionMain {...props} />;
};

const mapStateToProps = ({ reportRelevantResults, reportNotes }) => ({
  ...reportRelevantResults,
  ...reportNotes,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onSetReportRelevantResults: setReportRelevantResult,
      onGetReportProjectRelevantResultFromPN:
        getReportProjectRelevantResultFromPN,
      onGetReportProjectNotes: getReportProjectNotes,
      onAddKeyFeaturesRelevantResult: addKeyFeaturesRelevantResult,
      onSetFilter: setFilterFields,
      onRwireSearchAPI: fetPatentDetails,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Container);
