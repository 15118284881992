import { patent_detials } from "../../components/constant";
import { search } from "../../service/elasticSearch";
import { fetch } from "../../service/fetch";

const capitalizeFirstLetter = (str) => {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const setKeyword = (payload) => ({
  type: "KEYWORD_SUGGESTOR_SET",
  payload: {
    ...payload,
  },
});

export const formatUserQueryInput = (inputValues) => {
  inputValues = inputValues.toLowerCase();
  let replaceCollection = [
    ["|", " , "],
    [",", " , "],
    [";", " , "],
    [" or ", " , "],
    [" and ", " , "],
    ["*", ""],
  ];
  replaceCollection.forEach((replaceValue) => {
    inputValues = inputValues.replaceAll(replaceValue[0], replaceValue[1]);
  });

  let inputCollection = inputValues.split(" ");
  inputCollection = inputCollection.filter((tempInput) => tempInput.length > 0);
  return inputCollection.join(" ");
};

export const setSelectedRows = (selectedRows) => (dispatch) => {
  dispatch(setKeyword(selectedRows));
};

export const getWordsSynonymsArray = async (wordsToFind, arrayOfArrays) => {
  const generateWordRelations = (words, arrays) => {
    return words.map((word) => {
      const relatedWords = arrays
        .filter((subArr) => subArr.includes(word))
        .flatMap((subArr) => subArr.filter((item) => item !== word));
      const wordObj = {};
      wordObj[word] = [...new Set(relatedWords)]; // Removing duplicates
      return wordObj;
    });
  };

  return generateWordRelations(wordsToFind, arrayOfArrays);
};

export const getSynonymsOfWords =
  (words, count = 5) =>
  async (dispatch, getState) => {
    dispatch(setKeyword({ isSynonymsLoading: true }));
    try {
      var response = {};
      response = await fetch(
        {
          url: `/parser-api/synonyms`,
          body: {
            words,
            count,
          },
        },
        "POST"
      );

      const capitalizedSynonymsArray = response.synonymsArray.map((obj) => {
        const [key, synonyms] = Object.entries(obj)[0];
        return {
          [key]: synonyms.map((syn) => capitalizeFirstLetter(syn.trim())),
        };
      });

      dispatch(setKeyword({ tableData: capitalizedSynonymsArray }));
    } catch (err) {
    } finally {
      dispatch(setKeyword({ isSynonymsLoading: false }));
    }
  };

export const checkIfPatentAvailable = (id) => async (dispatch) => {
  const queryObj = {
    queryToSearch: id,
    includeFieldsOnResult: ["PN_B"],
  };
  const body = JSON.stringify(queryObj);
  const dataResponse = await search(body, patent_detials);
  if (dataResponse.data) {
    return { _source: dataResponse.data };
  } else {
    return false;
  }
};

export const findKeywordsFromText = (text) => async (dispatch) => {
  dispatch(setKeyword({ isKeywordsGeneratorLoading: true }));
  try {
    var response = {};
    response = await fetch(
      {
        url: `/parser-api/capture-keywords`,
        body: {
          text,
        },
      },
      "POST"
    );
    const keywords = response.keywords
      ? response.keywords.map(
          (keyword) => capitalizeFirstLetter(keyword) 
        )
      : [];

    return keywords;
  } catch (err) {
  } finally {
    dispatch(setKeyword({ isKeywordsGeneratorLoading: false }));
  }
};
