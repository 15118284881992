import React from "react";
import SelectFieldSection from "./select-field-section";
import SingleSummarySection from "./single-summary-section";
import LoaderGif from "../../assets/images/loader.gif";

const SummaryTabContent = (props) => {
  const { summaries, summarizedSections, isSummaryFetching, emptySummary } =
    props;
  return (
    <div className="d-flex  gap-3 w-100 h-100">
      <SelectFieldSection {...props} />
      <div className="d-flex flex-column gap-2 w-100 overflow-auto">
        {emptySummary && (
          <div className="m-auto patent-summarizer-message">
           Generate Summary Using AI
          </div>
        )}
        {isSummaryFetching && (
          <div className="m-auto">
            <img alt="Loader" width={60} height={60} src={LoaderGif} />{" "}
          </div>
        )}
        {!isSummaryFetching &&
          summaries &&
          summaries.length > 0 &&
          summaries.map((summary, index) => (
            <SingleSummarySection
              heading={summarizedSections[index]}
              content={summary}
            />
          ))}
      </div>
    </div>
  );
};

export default SummaryTabContent;
