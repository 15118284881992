import React, { useState } from "react";
import { Table } from "react-bootstrap";
import ImportClaimModal from "./import-claims-modal";
import { SlidingSidePanel, Image } from "../../ui-components";
import {
  MinusBlackIcon,
  PlusBlackIcon,
  RightBlackIcon,
} from "../../assets/images/report";
import ReportCollaboration from "../../container/report/report-collaboration";
import ContentEditableInput from "./ContentEditableInput";

const NewKeyFeatureSection = (props) => {
  const {
    reportTableData,
    onSetPatentClaims,
    onSetReportNotes,
    claimsData,
    isLoadingClaims,
    claimsDataFound,
    searchedPatentNumber,
    onSplitPatentClaims,
    relevantResultReportTableData,
    onUpdateReportProjectNotesRow,
    onAddRowToreportProject,
    onDeleteRowFromReportProject,
    onGetReportProjectNotes,
    onGenerateList,
    userInput,
    onChange,
    onClearFilters,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [commentingRowID, setCommentingRowID] = useState(null);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const handleAddRow = async (rowIndex) => {
    const _id = sessionStorage.getItem("projectId");
    await onAddRowToreportProject({
      data: [
        {
          body: "",
          order: parseInt(`${rowIndex + 1}`),
        },
      ],
      projectId: parseInt(_id),
    });
    await onGetReportProjectNotes();
  };

  const handleDeleteRow = async (id) => {
    if (reportTableData.length === 1) {
      return;
    }
    const _id = sessionStorage.getItem("projectId");
    await onDeleteRowFromReportProject(id);
    await onGetReportProjectNotes({ projectId: _id });
  };

  const handleSaveInDb = async (value, rowIndex, idx, options) => {
    if (options.isChangeText) {
      if (reportTableData[idx].body !== value) {
        await onUpdateReportProjectNotesRow({
          id: rowIndex,
          body: {
            body: value,
          },
        });
      }
    } else if (options.isChangeDesc) {
      const pubNo = value.split("/")[0];
      const appNo = value.split("/")[1];
      await onUpdateReportProjectNotesRow({
        id: rowIndex,
        body: {
          publicationCount: pubNo,
          applicationCount: appNo,
        },
      });
    }
  };

  const handleCellChange = (e) => {
    const updatedText =
      e.target.value || e.target.innerText || e.target.innerHTML;
  };

  const handleGenerateList = () => {
    if (onGenerateList) {
      onGenerateList();
      setIsFilterApplied(true);
    }
  };

  return (
    <>
      <div className="bg-white px-3 py-2">
        <div className="px-4  pt-3 pb-1 strings-section border">
          <div className="strings-table">
            <div className="table-responsive">
              <Table bordered={false}>
                <thead>
                  <tr>
                    <th className=" checkbox-column text-center text-nowrap fs-16">
                      Key Features
                    </th>
                    <th className="text-center  fs-16 border-end-0">
                      Definition
                    </th>
                    <th className="text-center action-col border-end-0  border-start-0 fs-16"></th>
                    <th className="text-center action-col border-end-0 border-start-0 fs-16"></th>
                    <th className="text-center action-col  border-start-0 fs-16"></th>
                    <th className="text-center fs-16 "> Filter</th>
                  </tr>
                </thead>
                <tbody>
                  {reportTableData.filter((i) => i.body).length > 0 ? (
                    reportTableData.map((row, index) => (
                      <tr key={index} className="table-row">
                        <td className="checkbox-column fs-16">{index + 1}</td>
                        <td className="string-column fs-16 border-end-0">
                          <ContentEditableInput
                            text={row.body}
                            onCellChange={handleCellChange}
                            rowIndex={row.id}
                            isChangeText={true}
                            onSaveInDb={handleSaveInDb}
                            idx={parseInt(row?.sortOrder)}
                            cellClassName="px-1 flex-grow-1 value-col"
                          />
                        </td>
                        <td
                          className="align-middle cursor-pointer  drag-column text-center"
                          tabIndex={0}
                          onClick={() => setCommentingRowID(row.id)}
                          title="Collaboration"
                        >
                          <div className="icon-container-rows">
                            <Image
                              width={16}
                              height={16}
                              src={RightBlackIcon}
                              className="mx-0"
                            />
                          </div>
                        </td>
                        <td
                          className="align-middle cursor-pointer  drag-column text-center"
                          tabIndex={0}
                          onClick={() => handleAddRow(index + 1)}
                          title="Add row"
                        >
                          <div className="icon-container-rows">
                            <Image
                              width={20}
                              height={20}
                              src={PlusBlackIcon}
                              className="mx-0"
                            />
                          </div>
                        </td>
                        <td
                          className=" align-middle cursor-pointer  drag-column text-center"
                          tabIndex={0}
                          onClick={() => handleDeleteRow(row.id)}
                          title="Delete row"
                        >
                          <div className="icon-container-rows">
                            <Image
                              width={20}
                              height={20}
                              src={MinusBlackIcon}
                              className="mx-0"
                            />
                          </div>
                        </td>
                        <td className="count-column fs-16">
                          <div className="form-check d-flex justify-content-center align-items-center keyfeature-checkbox">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={userInput.selectedFeatures.includes(
                                row.id
                              )}
                              onChange={(e) => onChange(e, row.id)}
                            />
                          </div>
                        </td>

                        <SlidingSidePanel
                          isOpen={row?.id === commentingRowID ? true : false}
                          onClose={() => setCommentingRowID(null)}
                          id="report-collab-modal"
                        >
                          <ReportCollaboration
                            commentingRowID={commentingRowID}
                            onClose={() => setCommentingRowID(null)}
                            workspaceId={6}
                          />
                        </SlidingSidePanel>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6" className="text-center fs-16 py-4">
                        Please add the key features
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-3">
            <button
              className="apply-filter fs-14 px-3"
              onClick={() => setIsOpen(true)}
            >
              Import Claims
            </button>
            <div>
              <span
                className="relevant-result-header-text fs-14 px-3 cursor-pointer"
                onClick={onClearFilters}
              >
                Clear
              </span>
              <button
                className="apply-filter fs-14 px-3 "
                onClick={
                  reportTableData.every((row) => !row.body)
                    ? null
                    : handleGenerateList
                }
              >
                Apply Filter
              </button>
            </div>
          </div>
        </div>
      </div>

      {isOpen && (
        <ImportClaimModal
          {...props}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          onSetPatentClaims={onSetPatentClaims}
          onSetData={onSetReportNotes}
          claimsData={claimsData}
          tableData={reportTableData}
          isLoadingClaims={isLoadingClaims}
          claimsDataFound={claimsDataFound}
          searchedPatentNumber={searchedPatentNumber}
          onSplitPatentClaims={onSplitPatentClaims}
        />
      )}
    </>
  );
};

export default NewKeyFeatureSection;
