import { Message, toaster } from "rsuite";
import { patent_detials, smart_search } from "../../components/constant";
import { search, singleSearch } from "../../service/elasticSearch";
import { fetch } from "../../service/fetch";
import { setApp } from "../app";
import { setResultTable } from "../rwire-search-api";

export const setProjectReport = (payload) => ({
  type: "PROJECT_SET",
  payload: {
    ...payload,
  },
});
export const createReportProject = (body) => async (dispatch) => {
  var response = {};
  response = await fetch(
    {
      url: `/api/project`,
      body,
    },
    "POST"
  );
  sessionStorage.setItem("projectId", response.id);
  sessionStorage.setItem("projectMode", true);
  dispatch(
    setApp({
      isProjectMode: true,
    })
  );
};

export const getReportProjects = () => async (dispatch) => {
  try {
    var response = {};
    response = await fetch(
      {
        url: `/api/project-users/projects`,
      },
      "GET"
    );
    return response;
  } catch (error) {
    showErrorPopup(error, dispatch);
  }
};

export const getReportProjectNameById = (id) => async (dispatch) => {
  try {
    if (id) {
      var response = {};
      response = await fetch(
        {
          url: `/api/project/${id}/name`,
        },
        "GET"
      );
      dispatch(
        setProjectReport({
          projectName: response.name,
        })
      );
    }
  } catch (error) {
    showErrorPopup(error, dispatch);
  }
};

export const updateReportProject =
  ({ id, body }) =>
  async (dispatch) => {
    try {
      await fetch(
        {
          url: `/api/project/${id}`,
          body,
        },
        "PUT"
      );
    } catch (error) {
      showErrorPopup(error, dispatch);
    }
  };

export const deleteReportProject = (id) => async (dispatch) => {
  try {
    await fetch(
      {
        url: `/api/project/${id}`,
      },
      "DELETE"
    );
    showSuccessPopup("Successfully deleted the project");
  } catch (error) {
    showErrorPopup(error, dispatch);
  }
};

export const getReportWorkspaceElements = () => async (dispatch) => {
  try {
    var response = {};
    response = await fetch(
      {
        url: `/api/project-workspace-element`,
      },
      "GET"
    );
    return response;
  } catch (error) {
    showErrorPopup(error, dispatch);
  }
};

export const resetProjectData = () => (dispatch) => {
  dispatch({ type: "RESET_NOTES" });
  dispatch({ type: "RESET_KEYWORDS" });
  dispatch({ type: "RESET_INVENTORS" });
  dispatch({ type: "RESET_CLASSES" });
  dispatch({ type: "RESET_ASSIGNEES" });
};

export const getReportProjectDataById = (id) => async (dispatch) => {
  try {
    if (!id) return;
    var response = {};
    response = await fetch(
      {
        url: `/api/project/${id}`,
      },
      "GET"
    );
    dispatch(
      setProjectReport({
        projectPatentNumber: response.projectPatentNumber,
        projectTechnology: response.projectTechnology,
        projectType: response.projectType,
        citedPatents: response.citedPatents || [],
      })
    );
    return response;
  } catch (error) {
    showErrorPopup(error, dispatch);
  }
};

export const getBackwardCitationsData = () => async (dispatch, getState) => {
  try {
    const {
      project: { projectPatentNumber },
    } = getState();
    if (projectPatentNumber) {
      dispatch(setResultTable({ isLoadingResult: true }));
      const queryObj = {
        queryToSearch: projectPatentNumber,
        includeFieldsOnResult: ["PN_B"],
      };
      const body = JSON.stringify(queryObj);
      const dataResponse = await search(body, patent_detials);

      const { citedReferences } = await getCitedReferencesAndFamiliesList(
        dataResponse.data.BCPN,
        dataResponse.data.BCP
      );
      dispatch(
        setProjectReport({
          citedReferences: citedReferences,
        })
      );
    } else {
      dispatch(
        setProjectReport({
          citedPatents: [],
          citedReferences: [],
        })
      );
    }
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setResultTable({ isLoadingResult: false }));
  }
};
const getCitationTableArray = (arr) => {
  if (arr.length === 0) return [];
  let result = arr.map((item) => {
    return {
      AD: item._source.AD,
      PD: item._source.PD,
      PN: item._source.PN_B,
      EPRD: item._source.EPRD,
      CA: item._source.CA_EN,
      IN: item._source.IN_EN,
    };
  });
  return result;
};

const getCitedReferencesAndFamiliesList = async (bcpDetailsList, bcpList) => {
  const citedReferences = [];
  let familiesList = [...bcpList];

  const addFamiliesToArray = (families = [], type) => {
    familiesList.push(...families);
  };

  const familiesQueryObj = {
    queryToSearch: `PN=(${bcpList.join(" OR ")} )`,
    includeFieldsOnResult: ["SF", "MF", "DF", "CF", "EF"],
    isNumberWithIncludeSearch: false,
    selectedIncludes: [],
    dataSize: bcpList.length,
    dataFrom: 0,
    sortBy: "_score",
    sortType: "desc",
    collapsableField: "PN_B",
    filters: [],
    highlightWords: [],
    require_field_match: false,
  };

  const body = JSON.stringify(familiesQueryObj);
  const dataResponse = await getSearchData(body);

  if (dataResponse.hits.hits.length > 0) {
    dataResponse.hits.hits.forEach((data) => {
      const { SF, MF, DF, CF, EF } = data._source;
      addFamiliesToArray([...SF], "Simple Family");
      addFamiliesToArray([...MF], "Main Family");
      addFamiliesToArray([...DF], "Domestic Family");
      addFamiliesToArray([...CF], "Complete Family");
      addFamiliesToArray([...EF], "Extended Family");
    });
  }

  // Convert familiesList to a Set to remove duplicates, then back to an array
  familiesList = Array.from(new Set(familiesList.flat()));
  const detailsQueryObj = {
    queryToSearch: `PN=(${familiesList.join(" OR ")} )`,
    // Publication Number,	Publication Date,	Application Date, Priority Date, Current Assignee, Inventor
    includeFieldsOnResult: ["PN_B", "PD", "AD", "EPRD", "CA_EN", "IN_EN"],
    isNumberWithIncludeSearch: false,
    selectedIncludes: [],
    dataSize: familiesList.length,
    dataFrom: 0,
    sortBy: "_score",
    sortType: "desc",
    collapsableField: "PN_B",
    filters: [],
    highlightWords: [],
    require_field_match: false,
  };

  const detailsBody = JSON.stringify(detailsQueryObj);
  // const detailsDataResponse = await search(detailsBody, smart_search);
  const detailsDataResponse = await getSearchData(detailsBody);

  if (detailsDataResponse.hits.hits.length > 0) {
    citedReferences.push(
      ...getCitationTableArray(detailsDataResponse.hits.hits)
    );
  }
  return { citedReferences, familiesList };
};

export const getBackwardCitedPatentsList =
  (bcpList = [], bcpDetailsList = [], id = "") =>
  async (dispatch) => {
    try {
      if (!id || bcpList.length === 0 || bcpDetailsList.length === 0) {
        dispatch(
          setProjectReport({
            citedPatents: [],
            citedReferences: [],
          })
        );
        return;
      }

      // Call the function and get the results
      const { citedReferences, familiesList } =
        await getCitedReferencesAndFamiliesList(bcpDetailsList, bcpList);

      await fetch(
        {
          url: `/api/project/${id}`,
          body: {
            citedReferences: citedReferences,
            citedPatents: familiesList,
          },
        },
        "PUT"
      );
      dispatch(
        setProjectReport({
          citedPatents: familiesList,
          citedReferences: citedReferences,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

export const showErrorPopup = (error, dispatch) => {
  if (error?.message === "Unauthorized") {
    // Handle unauthorized error
    dispatch(
      setApp({
        isProjectMode: false,
      })
    );
    sessionStorage.removeItem("projectId");
    sessionStorage.setItem("projectMode", false);
  } else {
    // Display generic error message
    toaster.push(
      <Message type="error">
        {error?.message || "An error occurred. Please try again."}
      </Message>
    );
  }
};

export const showSuccessPopup = (message) => {
  toaster.push(<Message type="success">{message} </Message>);
};

const getSearchData = async (body) => {
  try {
    const data = await search(body, smart_search);
    let dataResponse = data.data;
    //s3 serve file related code

    const s3data = [];
    dataResponse?.hits?.hits?.forEach((item) => {
      s3data.push(singleSearch({ id: item._id }));
    });

    const dataResponseRaw = await Promise.all(s3data);

    const structuredData = dataResponse?.hits?.hits.map((item, k) => ({
      ...item,
      _source: dataResponseRaw[k].data,
    }));

    dataResponse.hits = {
      ...dataResponse.hits,
      hits: [...structuredData],
    };
    return dataResponse;
  } catch (error) {}
};
