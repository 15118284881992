import React from "react";
import RwireClickableField from "../../../container/rwire-clickable-field";

function RwireInventor({
  inventor,
  inventorToggle,
  setInventorToggle,
  onPerformSearch,
  isViewPage = false,
}) {
  const inventorList = inventorToggle ? inventor : inventor.slice(0, 5);

  return (
    <>
      {inventor && (
        <div
          className={`d-flex justify-content-between ${
            inventor.length > 5 ? "menu" : ""
          }`}
          style={{ alignItems: "center" }}
        >
          <div>
            {inventorList &&
              inventorList.map((inventor, index) => (
                <RwireClickableField
                  key={index}
                  name={inventor.toUpperCase()}
                  isLast={inventor && inventorList.length === index + 1}
                  onPerformSearch={onPerformSearch}
                  shortCode="IN"
                  joiningCharacter=" | "
                  isViewPage={isViewPage}
                />
              ))}
          </div>
          {!inventorToggle && inventor.length > 5 ? (
            <div className="ms-1 inventor"> +{inventor.length - 5}</div>
          ) : null}
        </div>
      )}
    </>
  );
}

export default RwireInventor;
