import React from "react";

const DataRow = ({ options, isOddRow, selectedRows, onSetSelectedRows }) => {
  const handleCheckboxChange = (event, keyword) => {
    const isChecked = event.target.checked;
    if (!isChecked) {
      // If the user unchecks a row, we need to remove it from the selection list and re-render with updated state
      const updatedSelectedRows = selectedRows.filter((row) => row !== keyword);
      onSetSelectedRows(updatedSelectedRows);
    } else {
      onSetSelectedRows([...selectedRows, keyword]);
    }
  };



  return (
    <div
      className={`d-flex w-100 border border-top-0 ${
        isOddRow ? "oddRowBg" : ""
      }`}
    >
      {/* Render TableRow with 3 options */}
      {options.map((option, index) => (
        <div
          className={`table-cell ${index !== 2 ? "border-right" : ""}`}
          key={index}
        >
          <div>
            <input
              className="cursor-pointer"
              type="checkbox"
              checked={selectedRows.includes(option)}
              onChange={(event) => handleCheckboxChange(event, option)}
            />
          </div>
          <div className="text-start text-dark">{option}</div>
        </div>
      ))}
    </div>
  );
};

export default DataRow;
