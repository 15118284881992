import React, { useEffect, useState } from "react";
import HistoryBlock from "../../components/rwire-history-new";
import { Trans } from "react-i18next";
import { t } from "i18next";
import { clearFielded } from "../../common/clear-filded";
import { resetFields } from "../../resources/data/options";
import { getEditFields } from "../../action/history";
import { handleSetActiveTab } from "../../action/app";
import RwireLoader from "../../container/rwire-loader";
import Header from "../../components/report-new/header-history";
const resetData = resetFields;
const RwireHistory = (props) => {
  const {
    isNumberSearchHistory,
    clickOnEditQuery,
    editQuery,
    onSetApp,
    onSetAllField,
    chunksArray,
    operatorsArray,
    onSetQuery,
    inputsValue,
    onSetCombineQueryPopup,
    selectedRows,
    selectedRowsForCombine,
  } = props;
  const [selectedField, setSelectedField] = useState("Fielded");
  const [selectedCombineQuery, setSelectedCombineQuery] = useState("");
  const [selectedCombineQueryId, setSelectedCombineQueryId] = useState(null);

  useEffect(() => {
    if (isNumberSearchHistory) {
      const clearData = clearFielded(resetData);
      onSetAllField({ queryFields: clearData });
    } else if (
      editQuery &&
      (editQuery.includes("SS") ||
        (editQuery.includes(">") && !editQuery.includes(">=")) ||
        (editQuery.includes("<") && !editQuery.includes("<=")))
    ) {
      const clearData = clearFielded(resetData);
      onSetAllField({ queryFields: clearData });
    } else {
      if (editQuery) {
        let temp = "";
        temp = getEditFields(inputsValue, chunksArray, operatorsArray);
        setTimeout(() => {
          if (editQuery) {
            onSetAllField({ queryFields: temp });
          }
        }, 50);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickOnEditQuery]);

  useEffect(() => {
    onSetApp({ editQuery: "" });
    if (document.title === "Researchwire | History") {
      setSelectedField("Fielded");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document.title]);
  useEffect(() => {
    if (isNumberSearchHistory) {
      setSelectedField("Number");
    } else if (
      editQuery &&
      (editQuery.includes("SS") ||
        (editQuery.includes(">") && !editQuery.includes(">=")) ||
        (editQuery.includes("<") && !editQuery.includes("<=")))
    ) {
      setSelectedField("Expert");
    } else {
      setSelectedField("Fielded");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickOnEditQuery]);

  useEffect(() => {
    if (selectedField === "Combine") {
      onSetCombineQueryPopup({
        isOpenCombineQueryModal: true,
        selectedQueryRows: selectedRows,
        selectedCombineQueryRows: selectedRowsForCombine,
        selectedCombineQuery: selectedCombineQuery,
        selectedCombineQueryId: selectedCombineQueryId,
      });
      setSelectedField("");
    }
  }, [selectedField]);
  return (
    <>
      <RwireLoader />
      <Header {...props} />
      <div className="container-fluid  history-page">
        <div className="home-page-content">
          <div className="tab-containt-main">
            <HistoryBlock
              {...props}
              onSetSelectedCombineQuery={setSelectedCombineQuery}
              onSetSelectedCombineQueryId={setSelectedCombineQueryId}
              setSelectedField={setSelectedField}
              selectedField={selectedField}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default RwireHistory;
