const initialState = {
    isOpenCombineQueryModal: false,
    selectedQueryRows: [],
    selectedCombineQueryRows: [],
    selectedCombineQuery: "",
    selectedCombineQueryId: 0
};

const combineQuery = (state = initialState, { type, payload }) => {
    switch (type) {
        case "COMBINE_QUERY_SET":
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};

export default combineQuery;
