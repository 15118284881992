import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import RWIRE_IMAGES from "../common/common-functions/rwire-images";
import { HiSortAscending, HiSortDescending } from "react-icons/hi";

const TableHeader = (props) => {
  const { t } = useTranslation();
  const {
    selectedRows,
    onSetSelectedRows,
    selectedItemList,
    data,
    onSetExportSelected,
    onSetItemPerPage,
    selectedRowsForCombine,
    historySortDirection,
    onFetchHistory,
  } = props;

  const handleCheckbox = (event) => {
    let selectIds = [];
    let selectRowNumber = [];
    let selectedList = [];

    data.forEach((i) => {
      selectIds.push(`${i.id}`);
      selectRowNumber.push(`${i.userSearchNumber}`);
      selectedList.push(i);
    });

    if (event.target.checked) {
      onSetSelectedRows({ selectedRows: selectIds });
      onSetItemPerPage({ selectedRowsForCombine: selectRowNumber });
      onSetExportSelected({ selectedItemList: selectedList });
    } else {
      const difference = selectedRows.filter((x) => !selectIds.includes(x));
      const differenceRowNumber = selectedRowsForCombine.filter(
        (x) => !selectRowNumber.includes(x)
      );
      onSetSelectedRows({ selectedRows: difference });
      onSetItemPerPage({ selectedRowsForCombine: differenceRowNumber });

      const result = selectedItemList.filter(
        (element) => !difference.includes(`${element.id}`)
      );
      onSetExportSelected({ selectedItemList: result });
    }
  };

  const handleSort = async () => {
    onSetItemPerPage({
      historySortDirection: historySortDirection === "DESC" ? "ASC" : "DESC",
    });
    await onFetchHistory();
  };

  return (
    <thead className="header_wrapper table-row rwire-tableheader">
      <th className="form-check regular-checkbox table-column">
        <input
          className="form-check-input regular-checkbox"
          type="checkbox"
          onChange={handleCheckbox}
        />
      </th>
      <th className="table-column numbers">
        <span>
          {t("history-table-title-numbers")}
          <span
            className="sort-button ps-2"
            onClick={handleSort}
            style={{ cursor: "pointer" }}
          >
            {historySortDirection === "ASC" ? (
              <HiSortAscending style={{ fontSize: "18px" }} alt="Ascend" />
            ) : (
              <HiSortDescending style={{ fontSize: "18px" }} alt="Descend" />
            )}
          </span>
        </span>
      </th>
      <th className="table-column search-query">Strings</th>
      <th className="table-column date-time">
        <span>
          {t("history-table-title-date")}
          <span
            className="sort-button ps-2"
            onClick={handleSort}
            style={{ cursor: "pointer" }}
          >
            {historySortDirection === "ASC" ? (
              <HiSortAscending style={{ fontSize: "18px" }} alt="Ascend" />
            ) : (
              <HiSortDescending style={{ fontSize: "18px" }} alt="Descend" />
            )}
          </span>
        </span>
      </th>
      <th className="table-column records">Count</th>
    </thead>
  );
};

export default TableHeader;
