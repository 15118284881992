import React, { useState } from "react";
import { Table } from "react-bootstrap";
import CitedLinks from "../report/rwire-cited-references/rwire-cited-links";

const CitationSection = (props) => {
  const { citedReferences, onRwireSearchAPI, onSetFilter } = props;
  const [openTabs, setOpenTabs] = useState([]);

  const formatDate = (dateString) => {
    // Parse the date string in YYYYMMDD format
    const year = parseInt(dateString.substring(0, 4), 10);
    const month = parseInt(dateString.substring(4, 6), 10) - 1; // Month is 0-indexed
    const day = parseInt(dateString.substring(6, 8), 10);

    const date = new Date(year, month, day);
    const options = { day: "2-digit", month: "short", year: "numeric" };

    const formattedDate = date
      .toLocaleDateString("en-GB", options)
      .replace(/ /g, "-");
    return formattedDate; // Replace spaces with dashes
  };

  const handleClickPN = (queryString) => {
    sessionStorage.setItem("patentId", queryString);
    onSetFilter({ isViewPageModalOpen: false, isSmartReaderOpen: false });

    const maxTabs = 100;

    if (openTabs.length < maxTabs) {
      const frontUrl = window.location.href.includes("/front") ? "/front" : "";
      const newTab = window.open(
        `${frontUrl}/en/patent-view/${queryString}`,
        "_blank"
      );
      setOpenTabs([...openTabs, { name: queryString, tab: newTab }]);
      if (newTab) {
        newTab.focus();
      }
    }
  };
  return (
    <div className="patent-analysis-tool bg-white px-3 py-2">
      <div className="px-4 pt-1 pb-3 strings-section border">
        <div className="citations-heading py-1">Citations</div>
        <div className="strings-table">
          <div className="table-responsive">
            <Table bordered={false}>
              <thead>
                <tr>
                  <th className="checkbox-column fs-16">Sr.No</th>
                  <th className="citation-header fs-16">Publication Number</th>
                  <th className="citation-header  date-column fs-16">
                    Pub date
                  </th>
                  <th className="citation-header  date-column fs-16">
                    App date
                  </th>
                  <th className="citation-header  date-column fs-16">
                    Priority
                  </th>
                  <th className="citation-header fs-16">Current Assignee</th>
                  <th className="count-column fs-16">Inventors</th>
                </tr>
              </thead>
              {citedReferences && citedReferences.length > 0 && (
                <tbody>
                  {citedReferences.map((item, index) => (
                    <tr key={item.id}>
                      <td className="checkbox-column fs-16">{index + 1}</td>
                      <td className="string-column fs-16 text-center fw-bold">
                        <span
                          onClick={() => handleClickPN(item.PN)}
                          className="pn-hyperlink"
                        >
                          {item.PN}
                        </span>
                      </td>
                      <td className="citation-header date-column fs-16">
                        {formatDate(item.PD)}
                      </td>
                      <td className="citation-header date-column fs-16">
                        {formatDate(item.AD)}
                      </td>
                      <td className="citation-header date-column fs-16">
                        {formatDate(item.EPRD)}
                      </td>
                      <td className="citation-header fs-16 publication-no">
                        {/* {item.CA?.map((data, index) => (
                          <CitedLinks
                            onRwireSearchAPI={onRwireSearchAPI}
                            data={data}
                            index={index}
                            length={item.CA?.length}
                            parameter="CA"
                          />
                        ))} */}
                        {item.CA?.join("| ")}
                      </td>
                      <td className="citation-header fs-16 publication-no">
                        {/* {item.IN?.map((data, index) => (
                          <CitedLinks
                            onRwireSearchAPI={onRwireSearchAPI}
                            data={data}
                            index={index}
                            length={item.IN?.length}
                            parameter="IN"
                          />
                        ))} */}
                        {item.IN?.join("| ")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </Table>
            {citedReferences && citedReferences.length === 0 && (
              <div className="text-center fs-16 py-4 text-black">
                No Citations here
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CitationSection;
