import React, { useEffect, useRef, useState } from "react";
import useClickOutside from "../../common/useClickOutside";
import CreateProject from "../../container/report/create-project";
import { useNavigate } from "react-router-dom";

export default function ProjectDropdown(props) {
  const {
    userProjects,
    onGetReportProjects,
    onSetApp,
    onSetProjectReport,
    onResetProjectData,
    onGetReportProjectDataById,
    onGetAllProjectUsers,
    onGetBackwardCitationsData,
    projectName,
    isProjectMode,
  } = props;
  const [selectedProject, setSelectedProject] = useState(null);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const ref = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (isProjectMode) {
      setSelectedProject(projectName);
    } else {
      setSelectedProject(null);
    }
  }, [projectName, isProjectMode]);
  const handleSelect = async (project) => {
    setSelectedProject(project.name);
    sessionStorage.setItem("projectId", project.id);
    sessionStorage.setItem("projectMode", true);
    sessionStorage.setItem("userProjectAccess", project.access);
    onSetProjectReport({ projectName: project.name });
    onResetProjectData();

    const data = await onGetReportProjectDataById(project.id);

    await onGetAllProjectUsers(project.id);
    onSetProjectReport({
      workspaceElements: data.workspaceElements.split(",").map(Number),
      projectPatentNumber: data?.projectPatentNumber,
    });
    onSetApp({
      isProjectMode: true,
    });
    setDropdownOpen(false);
    await onGetBackwardCitationsData();
  };
  useClickOutside(ref, () => setDropdownOpen(false));

  const handleOpen = async () => {
    setDropdownOpen((prev) => !prev);
    if (!isDropdownOpen) {
      try {
        setIsLoading(true);
        let response = await onGetReportProjects();
        if (response && response.result && response.result.length > 0) {
          onSetApp({ userProjects: response.result });
        } else {
          onSetApp({ userProjects: [] });
        }
      } catch (error) {
        onSetApp({ userProjects: [] });
        // eslint-disable-next-line no-console
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleDisableProject = () => {
    const _id = sessionStorage.getItem("projectId");
    if (_id) {
      sessionStorage.setItem("projectMode", false);
      onSetApp({ isProjectMode: false });
      sessionStorage.removeItem("projectId");
      navigate("/en");
    }
  };
  return (
    <>
      {" "}
      <div className="dropdown-container">
        <div
          className="dropdown-toggle d-flex align-items-center"
          onClick={handleOpen}
          ref={ref}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className={`bi bi-arrow-down-circle ${
              isDropdownOpen ? "rotate-180" : "rotate-0"
            }`}
            viewBox="0 0 16 16"
          >
            <circle
              cx="8"
              cy="8"
              r="7"
              stroke="currentColor"
              strokeWidth="1"
              fill="none"
            />
            <path
              fillRule="evenodd"
              d="M4.293 6.293a1 1 0 0 1 1.414 0L8 8.586l2.293-2.293a1 1 0 1 1 1.414 1.414l-3 3a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 0-1.414z"
            />
          </svg>

          <span className="fs-16 mx-1">Projects / </span>
          <span
            className="fs-16 fw-bold project-name-ellipse"
            title={selectedProject ? selectedProject : "No Project"}
          >
            {selectedProject ? selectedProject : "Default"}
          </span>
        </div>

        <ul className={`dropdown-menu ${isDropdownOpen ? "display" : ""} pe-1`}>
          <div className="projects-list">
            {!isLoading && (
              <li
                className="fw-bold breadcrumb-dropdown-item fs-16"
                onClick={handleDisableProject}
              >
                Default
              </li>
            )}{" "}
            {isLoading ? (
              <p className="m-auto breadcrumb-dropdown-item fs-16">
                Loading...
              </p>
            ) : userProjects && userProjects.length > 0 ? (
              userProjects.map((project, index) => (
                <li
                  key={index}
                  onClick={() => handleSelect(project)}
                  className="breadcrumb-dropdown-item fs-16"
                >
                  {project.name}
                </li>
              ))
            ) : (
              <li className="breadcrumb-dropdown-item fs-16">
                No Projects Available
              </li>
            )}
          </div>
          <li className="dropdown-divider"></li>
          <li
            className="breadcrumb-dropdown-item fs-16 create-new cursor-pointer"
            onClick={() => {
              setShowCreateModal(true);
              setDropdownOpen(false);
            }}
          >
            Create New Project +
          </li>
        </ul>
      </div>
      {showCreateModal && (
        <CreateProject
          isOpen={showCreateModal}
          onClose={() => setShowCreateModal(false)}
        />
      )}
    </>
  );
}
