import React, { useState } from "react";
import NewKeyFeatures from "../../container/report/new-key-features";
import RwireReportRelevantResults from "../../container/report/rwire-report-relevant-results";

const FirstSectionMain = (props) => {
  const { onGetReportProjectRelevantResultFromPN } = props;

  const projectId = sessionStorage.getItem("projectId");

  const [isGenerateListCalled, setIsGenerateListCalled] = useState(false);
  const [userInput, setUserInput] = useState({
    selectedFeatures: [],
    relevantResultId: 0,
    isModalOpen: false,
    isShowFilter: false,
  });

  let selectedId = [];
  const handleCheckbox = (e, id) => {
    const selectedItem = id;
    selectedId = [...userInput.selectedFeatures, selectedItem];
    if (!e.target.checked) {
      selectedId = selectedId.filter((item) => {
        return `${item}` !== `${id}`;
      });
    }
    setUserInput({
      ...userInput,
      selectedFeatures: selectedId,
    });
  };

  const handleGenerateList = () => {
    onGetReportProjectRelevantResultFromPN({
      projectId,
      selectedId: userInput.selectedFeatures,
    });
    setIsGenerateListCalled(true);
  };

  const handleClearFilters = () => {
    setUserInput({
      ...userInput,
      selectedFeatures: [],
    });
    onGetReportProjectRelevantResultFromPN({
      projectId,
      selectedId: [],
    });
  };

  return (
    <>
      <NewKeyFeatures
        {...props}
        onGenerateList={handleGenerateList}
        selectedRows={userInput.selectedFeatures}
        userInput={userInput}
        setUserInput={setUserInput}
        onChange={handleCheckbox}
        onClearFilters={handleClearFilters}
      />
      <RwireReportRelevantResults
        {...props}
        selectedFeatures={userInput.selectedFeatures}
        userInput={userInput}
        setUserInput={setUserInput}
        isGenerateListCalled={isGenerateListCalled}
      />
    </>
  );
};

export default FirstSectionMain;
