export const dragableFields = [
    {code: "AN_B", title: "Application Number"},
    {code: "AC", title: "Application Country"},
    {code: "AY", title: "Application year"},
    {code: "SC", title: "US Series Code"},
    {code: "ACC", title: "Assignee Country"},
    {code: "AO_EN", title: "Assignee-Original"},
    {code: "ANZ_EN", title: "Assignee-Normalized"},
    {code: "AS_EN", title: "Assignee-Standardized"},
    {code: "BON", title: "Botanic Latin Name"},
    {code: "BOV", title: "Botanic Variety"},
    {code: "CPC", title: "CPC"},
    {code: "ECL", title: "ECLA Class"},
    {code: "JCL", title: "JP-FI/F-Terms"},
    {code: "IPC", title: "IPC"},
    {code: "CA_EN", title: "Current Assignee -Original"},
    {code: "CAS_EN", title: "Current Assignee-Standardized"},
    {code: "CAN_EN", title: "Current Assignee-Normalized"},
    {code: "CAA_EN", title: "Current Assignee Address"},
    {code: "DSEP_CST", title: "Contracting-States - EP"},
    {code: "DSEP_EST", title: "Extension-States - EP"},
    {code: "DSEP_VST", title: "Validation-States - EP"},
    {code: "DSEP_PST", title: "Participating-States - EP"},
    {code: "DSPCT_RGCN", title: "Regional-Country-PCT"},
    {code: "DSPCT_AOST", title: "Regional Any-Other-State- PCT"},
    {code: "DSPCT_RGNCN", title: "Regional-Region-Country -PCT"},
    {code: "DSPCT_NT", title: "National -PCT"},
    {code: "DSPCT_NDSCN", title: "New-Designation-Country -PCT"},
    {code: "AEX", title: "Assistant Examiner"},
    {code: "PEX", title: "Primary Examiner"},
    {code: "AG_EN", title: "Attorney/Agent"},
    {code: "CRA", title: "Correspondent"},
    {code: "IN_EN", title: "Inventor"},
    {code: "CF", title: "Complete Family"},
    {code: "DF", title: "Domestic Family"},
    {code: "EF", title: "Extended Family"},
    {code: "MF", title: "Main Family"},
    {code: "SF", title: "Simple Family"},
    {code: "PCTN_B", title: "PCT Pub Number"},
    {code: "PCTPD", title: "PCT Pub Date"},
    {code: "PRN", title: "Priority Numbers"},
    {code: "PRC", title: "Priority Country"},
    {code: "PRD", title: "Priority Date"},
    {code: "EPRD", title: "Earliest Priority Date"},
    {code: "PN_B", title: "Publication Number"},
    {code: "PNC", title: "Publication Country"},
    {code: "PD", title: "Publication Date"},
    {code: "PKC", title: "Publication Kind Code"},
    {code: "PY", title: "Publication Year"},
    {code: "PRY", title: "Priority Year"},
    {code:"CPCP",title:"CPC-Primary"}
];
