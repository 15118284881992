export const fields = {
  AB_EN: "Abstract (English)",
  AB_FR: "Abstract (French)",
  AB_DE: "Abstract (German)",
  AB_ES: "Abstract (Spanish)",
  AC: "Application Country",
  AD: "Application Date",
  AY: "Application Year",
  AGA: "Agent Address",
  AGC: "Agent Country",
  AGCT: "Agent City",
  AGN: "Agent Name",
  AN: "Application Number",
  AN_B: "Application Number",
  APA: "Applicant Address",
  APC: "Applicant Country",
  APCT: "Applicant City",
  APN: "Applicant Name",
  APN_EN: "Applicant Name",
  ASA: "Assignee Address",
  ASC: "Assignee Country",
  ASCT: "Assignee City",
  ASD: "Standardized Assignee Name",
  AS_EN: "Standardized Assignee Name",
  ASDC: "Standardized Current Assignee",
  ASN: "Assignee Name",
  CL_EN: "Claims (All-English)",
  CL_FR: "Claims (All-French)",
  CL_DE: "Claims(All-German)",
  CL_ES: "Claims (All-Spanish)",
  ICL_EN: "Claims (Independent-English)",
  ICL_FR: "Claims (Independent-French)",
  ICL_DE: "Claims (Independent-German)",
  ICL_ES: "Claims (Independent-Spanish)",
  DCL_EN: "Claims (Dependent-English)",
  DCL_FR: "Claims (Dependent-French)",
  DCL_DE: "Claims (Dependent-German)",
  DCL_ES: "Claims (Dependent-Spanish)",
  CPC: "CPC",
  DESC: "Description",
  DESC_EN: "Description",
  EXA: "Examiner Address",
  EXC: "Examiner Country",
  EXN: "Examiner Name",
  EPRD: "Earliest Priority Date",
  EPN: "Earliest Priority Number",
  INA: "Inventor Address",
  INC: "Inventor Country",
  INCT: "Inventor City",
  INN: "Inventor Name",
  IPC: "IPC",
  ED: "Lapse Date",
  LE: "Legal Event",
  LEDT: "Legal Event Date",
  LEC: "Legal Event Code",
  LED: "Legal Event Description",
  LOC: "Locarno Class",
  NCI: "NPL Citations",
  NED: "National Entry Date",
  PAN: "PCT Application Number",
  PCT_B: "PCT Application Number",
  PC: "Publication Country",
  PNC: "Publication Country",
  PCI: "Backward Citations",
  PCID: "Backward Citations/Date",
  PD: "Publication Date",
  PS: "Publication State",
  PY: "Publication Year",
  PY: "Publication Year",
  PFC: "PCT Filing Country",
  PFD: "PCT Filing Date",
  PFY: "PCT Filing Year",
  PPC: "PCT Publication Country",
  PPD: "PCT Publication Date",
  PPY: "PCT Publication Year",
  PPN: "PCT Publication Number",
  PCTN_B: "PCT Publication Number",
  PN: "Publication Number",
  PN_B: "Publication Number",
  PRC: "Priority Country",
  PRD: "Priority Date",
  PRN: "Priority Number",
  PRY: "Priority Year",
  PND: "Priority Number/Date",
  PNDC: "Priority Number/Date/Country",
  RAAN: "US Reassignment Assignor Name",
  RASA: "US Reassignment Assignee Address",
  RASN: "US Reassignment Assignee Name",
  RCA: "US Reassignment Correspondence Address",
  RCN: "US Reassignment Correspondence Name",
  USC: "US Class",
  APA_EN: "Applicant Address English",
  AO_EN: "Original Assignee English",
  AA_EN: "Assignee Address English",
  CAS_EN: "Current Assignee Standardized English",
  AG_EN: "Attorney/Agent English",
  AGA_EN: "Attorney/Agent Address English",
  IN_EN: "Inventor English",
  INA_EN: "Inventor Address English",
  AEX: "Assistant Examiner",
  PEX: "Primary Examiner",
  DSPCT_NT: "Designated PCT National",
  BCP: "Cited Refs Details - Patents",
  BCN: "Cited Refs Details - NPLs",
  LCM: "Locarno Class Main Classification",
  LCF: "Locarno Class Further Classification",
  AA: "Assignee/Applicant",
  TI_EN: "Title (English)",
  TI_FR: "Title (French)",
  TI_DE: "Title (German)",
  TI_ES: "Title (Spanish)",
  TAC: "Title/Abstract/Claims",
  TA: "Title/Abstract",
  TC: "Title/Claims",
  ALL: "Title/Abstract/Claims/Description (Full-Text)",
  CA: "Claims/Abstract",
  CD: "Claims/Description",
  AIC: "IPC/CPC",
  score: "Score",
};

export const exportField = {
  AB_EN: "Abstract",
  AN_B: "Application No",
  AC: "Application Country",
  AD: "Application Date",
  AY: "Application Year",
  SC: "US Series Code",
  AA_EN: "Assignee Address",
  ACC: "Assignee Country",
  AO_EN: "Original Assignee",
  AOF_EN: "Original Assignee First",
  ANZ_EN: "Assignee Normalized",
  AS_EN: "Assignee Standardized",
  ACN: "Assignee Count",
  APCN: "Applicant Count",
  BON: "Botanic Name",
  BOV: "Botanic Variety",
  USM: "US Class-Main",
  USD: "US Class-Main-Divided ",
  USF: "US Class-Further",
  USFD: "Us Class-Further-Divided",
  LCM: "Locarno Class-Main",
  LCF: "Locarno Class-Further",
  CPC: "CPC",
  CPCP: "CPC-Primary",
  CPCD: "CPC-Divided ",
  CPC12: "CPC-12 Digit",
  CPC4: "CPC-8 Digit",
  CPC8: "CPC-4 Digit",
  CPCV: "CPC-Version",
  CPCO: "CPC-Assigning Office",
  ECL: "ECL",
  ECLD: "ECLA-Divided",
  JCL: "JP-FI/F-Terms",
  IPC: "IPC",
  IPCD: "IPC-Divided",
  IPC12: "IPC-12 Digit",
  IPC8: "IPC-8 Digit",
  IPC4: "IPC-4 Digit",
  IPCRV: "IPCR-Version",
  CA_EN: "Current Assignee",
  CAS_EN: "Current Assignee Standardized",
  CAN_EN: "Current Assignee Normalized",
  CAA_EN: "Current Assignee Address",
  DSEP_CST: "Contracting-States - EP",
  DSEP_EST: "Extension-States - EP",
  DSEP_VST: "Validation-States - EP",
  DSEP_PST: "UP-Participating-States - EP",
  DSEP: "Designated All States-EP  ",
  DSPCT_RGCN: "Regional Country - PCT",
  DSPCT_AOST: "Regional Any-Other-State - PCT",
  DSPCT_RGNCN: "Regional-Region-Country - PCT",
  DSPCT_NT: "PCT-National",
  DSPCT_NDSCN: "New-Designation-Country - PCT",
  DSPCT: "Designated All States-PCT",
  AEX: "Asst. Examiner",
  PEX: "Primary Examiner",
  EX: "Examiner",
  TI_EN: "Title",
  CLCN: "Claims Count",
  AG_EN: "Attorney/Agent",
  AGA_EN: "Attorney/Agent Address",
  AP_EN: "Applicant Organization",
  API_EN: "Applicant Individual",
  APS_EN: "Applicant Organization Standardized",
  APN_EN: "Applicant Organization Normalized",
  APFO_EN: "First Applicant Organization",
  APFI_EN: "First Applicant Individual",
  APT: "Applicant Type",
  APA_EN: "Applicant Address",
  CRA_EN: "Correspondent Address",
  CR_EN: "Correspondent",
  IN_EN: "Inventor",
  INCN: "Inventor Count",
  INF_EN: "Inventor First",
  INA_EN: "Inventor Address",
  CF: "Complete Family",
  DF: "Domestic Family",
  EF: "Extended Family",
  MF: "Main Family",
  SF: "Simple Family",
  CFID: "Complete Family ID",
  DFID: "Domestic Family ID",
  EFID: "Extended Family ID",
  MFID: "Main Family ID",
  SFID: "Simple Family ID",
  PCT_B: "PCT App No.",
  PCTAD: "PCT App Date",
  PCTN_B: "PCT Pub No.",
  PCTPD: "PCT Pub Date",
  PRN_B: "Priority No.",
  PRC: "Priority Country",
  PRD: "Priority Date",
  EPRD: "Earliest Priority Date",
  EPRY: "Earliest Priority Year",
  PRY: "Priority Year",
  PN_B: "Publication No.",
  PNC: "Publication Country Code",
  PD: "Publication Date",
  PY: "Publication Year",
  PKC: "Publication Kind Code",
  BCP: "Cited Refs Details - Patents",
  BCN: "Cited Refs Details - NPLs",
  FCP: "Citing Refs Details - Patent",
  RDN: "Related Documents",
  PVN: "Provisional App No.",
  PTA: " Patent Term Adjustment (US)",
  CL_EN: "Claims",
  DESC: "Description",
  DESC_EN: "Description",
  DCL_EN: "Dependent Claims",
  FCL: "First Claim",
  ICL_EN: "Independent Claims",
  GOI: "Government Interest",
  PL: "Publication Language",
  USMS: "US Maintenance Status",
  PCTPC: "PCT Publication Country",
  LST: "Legal Status",
  ALD: "Legal State",
  PT: "Publication Type",
  OH: "Ownership History",
  FP: "Fee Payment Events",
  LIT: "Litigation",
  PCL: "IPC/CPC",
  AAPO: "Assignee/Applicant - Original",
  AAPS: "Assignee/Applicant - Standardized",
  AAPN: "Assignee/Applicant - Normalized",
};

export const fieldsArray = () => {
  return Object.entries(fields);
};

export const fieldNameFromShortCode = (fieldName) => {
  return fields[fieldName] ? fields[fieldName] : fieldName;
};

export const fieldNameFromShortCodeExport = (fieldName) => {
  return exportField[fieldName] ? exportField[fieldName] : fieldName;
};

export const yearFieldsList = ["PY", "PFY", "PPY", "PRY"];

export const numberFieldsList = [
  "PN",
  "AN",
  "PRN",
  "PA",
  "PPR",
  "APR",
  "PAPR",
  "PAN",
  "PPN",
];

export const textFieldsList = [
  "AB_EN",
  "AB_FR",
  "AB_DE",
  "AB_CN",
  "AB_ES",
  "AB_JP",
  "AB_KR",
  "AA_EN",
  "AA_FR",
  "AA_DE",
  "AA_CN",
  "AA_ES",
  "AA_JP",
  "AA_KR",
  "AAN",
  "AO_EN",
  "AO_FR",
  "AO_DE",
  "AO_CN",
  "AO_ES",
  "AO_JP",
  "AO_KR",
  "AOF_EN",
  "AOF_FR",
  "AOF_DE",
  "AOF_CN",
  "AOF_ES",
  "AOF_JP",
  "AOF_KR",
  "ANZ_EN",
  "ANZ_FR",
  "ANZ_DE",
  "ANZ_CN",
  "ANZ_ES",
  "ANZ_JP",
  "ANZ_KR",
  "AS_EN",
  "AS_FR",
  "AS_DE",
  "AS_CN",
  "AS_ES",
  "AS_JP",
  "AS_KR",
  "BON",
  "BOV",
  "CA_EN",
  "CA_FR",
  "CA_DE",
  "CA_CN",
  "CA_ES",
  "CA_JP",
  "CA_KR",
  "CAS_EN",
  "CAS_FR",
  "CAS_DE",
  "CAS_CN",
  "CAS_ES",
  "CAS_JP",
  "CAS_KR",
  "CAN_EN",
  "CAN_FR",
  "CAN_DE",
  "CAN_CN",
  "CAN_ES",
  "CAN_JP",
  "CAN_KR",
  "CAA_EN",
  "CAA_FR",
  "CAA_DE",
  "CAA_CN",
  "CAA_ES",
  "CAA_JP",
  "CAA_KR",
  "CAAN",
  "EX",
  "AEX",
  "PEX",
  "TI_EN",
  "TI_FR",
  "TI_DE",
  "TI_CN",
  "TI_ES",
  "TI_JP",
  "TI_KR",
  "AG_EN",
  "AG_FR",
  "AG_DE",
  "AG_CN",
  "AG_ES",
  "AG_JP",
  "AG_KR",
  "AGA_EN",
  "AGA_FR",
  "AGA_DE",
  "AGA_CN",
  "AGA_ES",
  "AGA_JP",
  "AGA_KR",
  "AGAN",
  "AP_EN",
  "AP_FR",
  "AP_DE",
  "AP_CN",
  "AP_ES",
  "AP_JP",
  "AP_KR",
  "API_EN",
  "API_FR",
  "API_DE",
  "API_CN",
  "API_ES",
  "API_JP",
  "API_KR",
  "APS_EN",
  "APS_FR",
  "APS_DE",
  "APS_CN",
  "APS_ES",
  "APS_JP",
  "APS_KR",
  "APN_EN",
  "APN_FR",
  "APN_DE",
  "APN_CN",
  "APN_ES",
  "APN_JP",
  "APN_KR",
  "APFO_EN",
  "APFO_FR",
  "APFO_DE",
  "APFO_CN",
  "APFO_ES",
  "APFO_JP",
  "APFO_KR",
  "APFI_EN",
  "APFI_FR",
  "APFI_DE",
  "APFI_CN",
  "APFI_ES",
  "APFI_JP",
  "APFI_KR",
  "APA_EN",
  "APA_FR",
  "APA_DE",
  "APA_CN",
  "APA_ES",
  "APA_JP",
  "APA_KR",
  "APAN",
  "CRA_EN",
  "CRA_FR",
  "CRA_DE",
  "CRA_CN",
  "CRA_ES",
  "CRA_JP",
  "CRA_KR",
  "CRAN",
  "CR_EN",
  "CR_FR",
  "CR_DE",
  "CR_CN",
  "CR_ES",
  "CR_JP",
  "CR_KR",
  "IN_EN",
  "IN_FR",
  "IN_DE",
  "IN_CN",
  "IN_ES",
  "IN_JP",
  "IN_KR",
  "INF_EN",
  "INF_FR",
  "INF_DE",
  "INF_CN",
  "INF_ES",
  "INF_JP",
  "INF_KR",
  "INA_EN",
  "INA_FR",
  "INA_DE",
  "INA_CN",
  "INA_ES",
  "INA_JP",
  "INA_KR",
  "INAN",
  "CL_EN",
  "CL_FR",
  "CL_DE",
  "CL_CN",
  "CL_ES",
  "CL_JP",
  "CL_KR",
  "CLN",
  "DESC_EN",
  "DESC_FR",
  "DESC_DE",
  "DESC_CN",
  "DESC_ES",
  "DESC_JP",
  "DESC_KR",
  "LE",
  "DCL_EN",
  "DCL_FR",
  "DCL_DE",
  "DCL_CN",
  "DCL_ES",
  "DCL_JP",
  "DCL_KR",
  "DCLN",
  "FCL_EN",
  "FCL_FR",
  "FCL_DE",
  "FCL_CN",
  "FCL_ES",
  "FCL_JP",
  "FCL_KR",
  "ICL_EN",
  "ICL_FR",
  "ICL_DE",
  "ICL_CN",
  "ICL_ES",
  "ICL_JP",
  "ICL_KR",
  "ICLN",
  "USMS",
  "AB",
  "AA",
  "AO",
  "AOF",
  "ANZ",
  "AS",
  "BON",
  "BOV",
  "CA",
  "CAS",
  "CAN",
  "CAA",
  "CAAN",
  "EX",
  "TI",
  "AG",
  "AGA",
  "AGAN",
  "AP",
  "APS",
  "APN",
  "APFO",
  "APFI",
  "APA",
  "APAN",
  "CRA",
  "CRAN",
  "CR",
  "IN",
  "INF",
  "INA",
  "INAN",
  "CL",
  "CLN",
  "DESC",
  "LE",
  "DCL",
  "DCLN",
  "FCL",
  "ICL",
  "ICLN",
  "USMS",
  "AAP",
  "AAPO",
  "AAPS",
  "AAPN",
  "TA",
  "TAC",
  "ALL",
  "TC",
  "CLA",
  "CD",
  "SS",
  "BCN",
];

export const dropdownFieldsCodes = {
  Text: "ALL",
  Abstract: "AB",
  "Claims/Abstract": "CLA",
  "Claims/Description": "CD",
  Claims: "CL",
  "Dependent Claims": "DCL",
  Description: "DESC",
  "Independent Claims": "ICL",
  Title: "TI",
  "Title/Abstract": "TA",
  "Title/Abstract/Claims": "TAC",
  "Title/Claims": "TC",
  CPC: "CPC",
  CPCP: "CPC-Primary",
  "ECLA CLASS": "ECL",
  IPC: "IPC",
  "IPC/CPC ": "PCL",
  "JP-FI/F-Terms": "JCL",
  "Locorno Class": "LC",
  "Botanic Latin Name": "BON",
  "Botanic Variety": "BOV",
  "Assignee/Applicant - Normalized": "AAPN",
  "Assignee/Applicant": "AAP",
  "Assignee/Applicant - Original": "AAPO",
  "Assignee/Applicant - Standardized": "AAPS",
  "Current Assignee-Normalized": "CAN",
  "Current Assignee-Standardized": "CAS",
  "Current Assignee-Original": "CA",
  Inventor: "IN",
  "Assistant Examiner": "AEX",
  Examiner: "EX",
  "Primary Examiner": "PEX",
  "Attorney/Agent": "AG",
  "Application Number": "AN",
  "PCT App Number": "PCT",
  "PCT Pub Number": "PCTN",
  "Priority No.": "PRN",
  "Provisional App No.": "PVN",
  "Publication No.": "PN",
  "Complete Family": "CF",
  "Domestic Family": "DF",
  "Extended Family": "EF",
  "Main Family ": "MF",
  "Simple Family": "SF",
  "Related-Child Docs": "RCHD",
  "Related-Parent Docs": "RPN",
  "Cited Refs Details - NPL": "BCN",
  "Cited Refs Details - Patents": "BCP",
  "Citing Refs Details - Patent": "FCP",
  CRA: "CRA",
  "Applicant Address": "APA",
  "Assignee Address": "ASA",
  "Attorney/Agent Address": "AGA",
  "Current Assignee Address": "CAA",
  "Inventor Address": "INA",
  "Publication Kind Code": "PKC",
  "Legal Event": "LE",
  "US Series Code": "SC",
  "Designated All States-PCT": "DSPCT",
  "Application Date": "AD",
  "Earliest Priority Date": "EPRD",
  "Earliest Priority Year": "EPRY",
  "Expiry Date": "ED",
  "PCT App Date": "PCTAD",
  "PCT Pub Date": "PCTPD",
  "Priority Date": "PRD",
  "Publication Date": "PD",
  "Related-Child Docs Pub Date": "RCPD",
  "Related-Parent Docs Pub Date": "RPD",
  "Designated All States-EP": "DSEP",
  "Contracting-States - EP": "DSEP_CST",
  "Extension-States - EP": "DSEP_EST",
  "PCT- National": "DSPCT_NT",
  "New-Designation-Country -PCT": "DSPCT_NDSCN",
  "UP-Participating-States - EP": "DSEP_PST",
  "Regional Any-Other-State- PCT": "DSPCT_AOST",
  "Validation-States - EP": "DSEP_VST",
  "Regional-Country-PCT": "DSPCT_RGCN",
  "Regional-Region-Country -PCT": "DSPCT_RGNCN",
  "Application Country": "AC",
  "Assignee Country": "ASC",
  "Priority Country": "PRC",
  "Publication Country": "PNC",
  LST: "LST",
  ALD: "ALD",
  PTS: "PTS",
  PT: "PT",
  "Abstract (English)": "AB_EN",
  "Abstract (French)": "AB_FR",
  "Abstract (German)": "AB_DE",
  "Abstract (Spanish)": "AB_ES",
  "Application Year": "AY",
  "Agent Country": "AGC",
  "Agent City": "AGCT",
  "Agent Name": "AGN",
  "Applicant Country": "APC",
  "Applicant City": "APCT",
  "Applicant Name": "APN_EN",
  "Assignee City": "ASCT",
  "Standardized Assignee Name": "AS_EN",
  "Standardized Current Assignee": "ASDC",
  "Assignee Name": "ASN",
  "Claims (All-English)": "CL_EN",
  "Claims (All-French)": "CL_FR",
  "Claims(All-German)": "CL_DE",
  "Claims (All-Spanish)": "CL_ES",
  "Claims (Independent-English)": "ICL_EN",
  "Claims (Independent-French)": "ICL_FR",
  "Claims (Independent-German)": "ICL_DE",
  "Claims (Independent-Spanish)": "ICL_ES",
  "Claims (Dependent-English)": "DCL_EN",
  "Claims (Dependent-French)": "DCL_FR",
  "Claims (Dependent-German)": "DCL_DE",
  "Claims (Dependent-Spanish)": "DCL_ES",
  "Examiner Address": "EXA",
  "Examiner Country": "EXC",
  "Examiner Name": "EXN",
  "Earliest Priority Number": "EPN",
  "Inventor Country": "INC",
  "Inventor City": "INCT",
  "Inventor Name": "INN",
  "Legal Event Date": "LEDT",
  "Legal Event Code": "LEC",
  "Legal Event Description": "LED",
  "Locarno Class": "LOC",
  "NPL Citations": "NCI",
  "National Entry Date": "NED",
  "Backward Citations": "PCI",
  "Backward Citations/Date": "PCID",
  "Publication State": "PS",
  "Publication Year": "PY",
  "PCT Filing Country": "PFC",
  "PCT Filing Date": "PFD",
  "PCT Filing Year": "PFY",
  "PCT Publication Country": "PCTPC",
  "PCT Publication Date": "PPD",
  "PCT Publication Year": "PPY",
  "PCT Publication Number": "PCTN_B",
  "Priority Year": "PRY",
  "Priority Number/Date": "PND",
  "Priority Number/Date/Country": "PNDC",
  "US Reassignment Assignor Name": "RAAN",
  "US Reassignment Assignee Address": "RASA",
  "US Reassignment Assignee Name": "RASN",
  "US Reassignment Correspondence Address": "RCA",
  "US Reassignment Correspondence Name": "RCN",
  "US Class": "USC",
  "Applicant Address English": "APA_EN",
  "Original Assignee English": "AO_EN",
  "Assignee Address English": "AA_EN",
  "Current Assignee Standardized English": "CAS_EN",
  "Attorney/Agent English": "AG_EN",
  "Attorney/Agent Address English": "AGA_EN",
  "Inventor English": "IN_EN",
  "Inventor Address English": "INA_EN",
  "Locarno Class Main Classification": "LCM",
  "Locarno Class Further Classification": "LCF",
  "Title (English)": "TI_EN",
  "Title (French)": "TI_FR",
  "Title (German)": "TI_DE",
  "Title (Spanish)": "TI_ES",
  "IPC/CPC": "AIC",
  Score: "score",
  "Assignee Count": "ACN",
  "Publication Language": "PL",
  "Related Documents": "RD",
  "First Claim": "FCL",
};

export const dropdownFieldsPlaceholders = {
  Text: "E.g. Wireless OR Mobile",
  Abstract: "E.g. Wireless OR Mobile",
  "Claims/Abstract": "E.g. Wireless OR Mobile",
  "Claims/Description": "E.g. Wireless OR Mobile",
  Claims: "E.g. Wireless OR Mobile",
  "Dependent Claims": "E.g. Wireless OR Mobile",
  Description: "E.g. Wireless OR Mobile",
  "Independent Claims": "E.g. Wireless OR Mobile",
  Title: "E.g. Wireless OR Mobile",
  "Title/Abstract": "E.g. Wireless OR Mobile",
  "Title/Abstract/Claims": "E.g. Wireless OR Mobile",
  "Title/Claims": "E.g. Wireless OR Mobile",
  CPC: "E.g. H04W25/00",
  CPCP: "E.g. H04W25/00",
  "ECLA CLASS": "E.g. H04W25/00",
  IPC: "E.g. H04W25/00",
  "IPC/CPC ": "E.g. H04W25/00",
  "JP-FI/F-Terms": "E.g. 3B011/AA05",
  "Locorno Class": "E.g. 2606",
  "Botanic Latin Name": "E.g. Cupressocyparis Leylandii",
  "Botanic Variety": "E.g. Green Rocket",
  "Assignee/Applicant - Normalized": "E.g. Samsung OR (Durand Georges)",
  "Assignee/Applicant": "E.g. Samsung OR Nokia",
  "Assignee/Applicant - Original": "E.g. Samsung OR (Durand Georges)",
  "Assignee/Applicant - Standardized": "E.g. Samsung OR (Durand Georges)",
  "Current Assignee-Normalized": "E.g. Samsung OR Nokia",
  "Current Assignee-Standardized": "E.g. Samsung OR Nokia",
  "Current Assignee-Original": "E.g. Samsung OR Nokia",
  Inventor: "E.g. Durand Georges",
  Examiner: "E.g. Durand Georges",
  "Assistant Examiner": "E.g. Durand Georges",
  "Primary Examiner": "E.g. Durand Georges",
  "Attorney/Agent": "E.g. Alston & Bird LLP",
  "Application Number": "E.g. EP81401743A",
  "PCT App Number": "E.g. WO1997GB2698A OR WO1994021368A1",
  "PCT Pub Number": "E.g. WO1997GB2698A OR WO1994021368A1",
  "Priority No.": "E.g. FR80023452",
  "Provisional App No.": "E.g. US199756910P",
  "Publication No.": "E.g. EP51542A2",
  "Cited Refs Details - NPL": "E.g. Contactless Card Reader",
  "Cited Refs Details - Patents": "E.g. CN104012010A",
  "Citing Refs Details - Patent": "E.g. CN104012010A",
  "Applicant Address": "E.g. California, US",
  "Assignee Address": "E.g. California, US",
  "Attorney/Agent Address": "E.g. California, US",
  "Current Assignee Address": "E.g. California, US",
  "Inventor Address": "E.g. California, US",
  "Publication Kind Code": "E.g. 2",
  "Designated All States-PCT": "E.g. US OR EP",
  "Regional-Country-PCT": "E.g. US OR EP",
  "Regional-Region-Country -PCT": "E.g. US OR EP",
  "Application Country": "E.g. US OR EP",
  "Assignee Country": "E.g. US OR EP",
  "Priority Country": "E.g. US OR EP",
  "Publication Country": "E.g. US OR EP",
  "Abstract (English)": "E.g. Wireless OR Mobile",
  "Abstract (French)": "E.g. Wireless OR Mobile",
  "Abstract (German)": "E.g. Wireless OR Mobile",
  "Abstract (Spanish)": "E.g. Wireless OR Mobile",
  "First Claim": "E.g. Wireless OR Mobile",
  "Application Year": "YYYY",
  "Agent Country": "E.g. US OR EP",
  "Agent City": "E.g. California",
  "Agent Name": "E.g. Durand Georges",
  "Applicant Country": "E.g. US OR EP",
  "Applicant City": "E.g. California",
  "Applicant Name": "E.g. Durand Georges",
  "Assignee City": "E.g. California",
  "Standardized Assignee Name": "E.g. Durand Georges",
  "Standardized Current Assignee": "E.g. Durand Georges",
  "Assignee Name": "E.g. Durand Georges",
  "Claims (All-English)": "E.g. Wireless OR Mobile",
  "Claims (All-French)": "E.g. Wireless OR Mobile",
  "Claims(All-German)": "E.g. Wireless OR Mobile",
  "Claims (All-Spanish)": "E.g. Wireless OR Mobile",
  "Claims (Independent-English)": "E.g. Wireless OR Mobile",
  "Claims (Independent-French)": "E.g. Wireless OR Mobile",
  "Claims (Independent-German)": "E.g. Wireless OR Mobile",
  "Claims (Independent-Spanish)": "E.g. Wireless OR Mobile",
  "Claims (Dependent-English)": "E.g. Wireless OR Mobile",
  "Claims (Dependent-French)": "E.g. Wireless OR Mobile",
  "Claims (Dependent-German)": "E.g. Wireless OR Mobile",
  "Claims (Dependent-Spanish)": "E.g. Wireless OR Mobile",
  "Examiner Address": "E.g. California, US",
  "Examiner Country": "E.g. US OR EP",
  "Examiner Name": "E.g. Durand Georges",
  "Inventor Country": "E.g. US OR EP",
  "Inventor City": "E.g. California",
  "Inventor Name": "E.g. Durand Georges",
  "Publication Year": "YYYY",
  "Priority Year": "YYYY",
  "US Class": "E.g. 036/83",
  "Assignee Count": "E.g. 2",
  "Publication Language": "E.g. eng",
  "Related Documents": "E.g. FR80023452",
};

export const filterNames = {
  standardized_assignee_name: "Current Assignee",
  inventor_name: "Inventor",
  ipc: "IPC",
  cpc: "CPC",
  us_class: "US Class-Main-Divided",
  ipc_cpc: "IPC/CPC",
  priority_country: "Priority Country",
  publication_country: "Publication Country",
  publication_year: "Publication Year",
  publication_state: "Publication State",
  agent_name: "Attorney/Agent",
  application_year: "Application Year",
  application_country: "Application Country",
  us_series_code: "US Series Code",
  assignee_country: "Assignee Country",
  original_assignee_english: "Original Assignee",
  original_assignee_first_english: "Original Assignee First",
  assignee_normalized_english: "Assignee Normalized",
  botanic_latin_name: "Botanic Name",
  botanic_variety: "Botanic Variety",
  us_class_further_classification_divided: "US Class-Further-Divided",
  locarno_class_main_classification: "Locarno Class-Main",
  locarno_class_further_classification: "Locarno Class-Further",
  cpc_primary: "CPC-Primary",
  cpc_12_digit: "CPC-12 Digit",
  cpc_8_digit: "CPC-8 Digit",
  cpc_4_digit: "CPC-4 Digit",
  cpc_version: "CPC-Version",
  cpc_assigning_office: "CPC-Assigning Office",
  ecla_class_divided: "ECLA-Divided",
  jp_fi_f_terms: "JP-FI/F-Terms",
  ipc_divided: "IPC-Divided",
  ipc_12_digit: "IPC-12 Digit",
  ipc_8_digit: "IPC-8 Digit",
  ipc_4_digit: "IPC-4 Digit",
  ipcr_version: "IPC-Version",
  current_assignee_standardized_english: "Current Assignee Standardized",
  current_assignee_normalized_english: "Current Assignee Normalized",
  designated_contracting_states_ep: "Contracting-States - EP",
  designated_extension_states_ep: "Extension-States - EP",
  designated_validation_states_ep: "Validation-States - EP",
  designated_up_participating_states_ep: "UP-Participating-States - EP",
  designated_states_all_ep: "Designated States (All) - EP",
  designated_regional_country: "Regional Country - PCT",
  designated_regional_any_other_state: "Regional Any-Other-State - PCT",
  designated_regional_region_country: "Designated Regional-Region-Country",
  designated_pct_national: "PCT - National",
  designated_pct_new_designation_country: "New-Designation-Country - PCT",
  designated_states_all_pct: "Designated States (All) -PCT",
  assistant_examiner: "Examiner",
  primary_examiner: "Examiner",
  applicant_first_english_organization: "Applicant First Organization",
  applicant_first_english_individual: "Applicant First Individual",
  correspondent_english: "Correspondent",
  inventor_first_english: "Inventor First",
  earliest_priority_year: "Earliest Priority Year",
  priority_year: "Priority Year",
  applicant_type: "Applicant Type",
  patent_type: "Patent Type",
  legal_state: "Legal State",
  legal_status: "Legal Status",
  publication_type: "Publication Type",
  assignee_applicant_original: "Assignee/Applicant - Original",
  assignee_applicant_standardized: "Assignee/Applicant - Standardized",
  assignee_applicant_normalized: "Assignee/Applicant - Normalized",
};
