const RWIRE_IMAGES = {
  RwireFiledIcon: require("../../../assets/images/FieldsIcon.png"),
  RwireExpertIcon: require("../../../assets/images/ExpertIcon.png"),
  RwireNumberIcon: require("../../../assets/images/NumberIcon.png"),
  RwireSearchBlackIcon: require("../../../assets/images/search-black.png"),
  RwirePlusIcon: require("../../../assets/images/plus.png"),
  RwireInsideIcon: require("../../../assets/images/InsightsIcon.png"),
  RwireExportIcon: require("../../../assets/images/ExportIcon.png"),
  RwireDeleteIcon: require("../../../assets/images/DeleteIcon.png"),
  RwireCloseIcon: require("../../../assets/images/close.png"),
  RwireFilterIcon: require("../../../assets/images/FilterIcon.png"),
  RwireFilterIconWhite: require("../../../assets/images/filter-white.png"),
  RwireHighlightIcon: require("../../../assets/images/HighlightIcon.png"),
  RwireSortIcon: require("../../../assets/images/SortIcon.png"),
  RwireMinusIcon: require("../../../assets/images/minus-sign.png"),
  RwireArrow: require("../../../assets/images/DownArrow.png"),
  RwireSetting: require("../../../assets/images/setting.png"),
  RWireDownsort: require("../../../assets/images/DownSortIcon.png"),
  RWireUpsort: require("../../../assets/images/UpSortICon.png"),
  RWireAtoZIcon: require("../../../assets/images/AtoZIcon.png"),
  RWireZtoIconA: require("../../../assets/images/ZtoAIcon.png"),
  RwirePlus: require("../../../assets/images/PlusSign.png"),
  RwireDownload: require("../../../assets/images/download.png"),
  RwireSave: require("../../../assets/images/save-instagram.png"),
  RwireEmail: require("../../../assets/images/email.png"),
  RwireEmailWhite: require("../../../assets/images/email-white.png"),
  RwireUpload: require("../../../assets/images/upload.png"),
  RwireEditIcon: require("../../../assets/images/Editicon.png"),
  RwireDownloadCenter: require("../../../assets/images/download-center.png"),
  RwireDownloadCenterWhite: require("../../../assets/images/download-white.png"),
  RwireDelete: require("../../../assets/images/delete.png"),
  RwireDeleteWhite: require("../../../assets/images/delete-white.png"),
  RwireFile: require("../../../assets/images/file.png"),
  RwireCalender: require("../../../assets/images/calendar.png"),
  RwireDrag: require("../../../assets/images/drag.png"),
  RwireLight: require("../../../assets/images/light.svg"),
  RwireDark: require("../../../assets/images/dark.svg"),
  RwireDrop: require("../../../assets/images/blackdrop.png"),
  RwireUp: require("../../../assets/images/blackdrop-up.png"),
  RwireNoResultsFoundLight: require("../../../assets/images/new-image.gif"),
  RwireNoResultsFoundDark: require("../../../assets/images/new-image-dark.gif"),
  RwireAscendSort: require("../../../assets/images/ascending.png"),
  RwireDescendSort: require("../../../assets/images/descending.png"),
  RwireCognizancecheck: require("../../../assets/images/chk.png"),

  RwireServiceFirstpart: require("../../../assets/images/first_banner.png"),
  RwireFirstIcon: require("../../../assets/images/first_icon.png"),
  RwireSecondIcon: require("../../../assets/images/second_icon.png"),
  RwireThirdIcon: require("../../../assets/images/third_icon.png"),
  RwireFourthIcon: require("../../../assets/images/fourth_icon.png"),
  RwireFifthIcon: require("../../../assets/images/fifth_icon.png"),
  RwireLastpartIcon: require("../../../assets/images/last_part_icon.png"),
  RwireEmailIcon: require("../../../assets/images/email_icon.png"),
  RwireSecondPartFirstImage: require("../../../assets/images/image_first_list.jpg"),
  RwireSecondPartSecondImage: require("../../../assets/images/services_second_img.jpg"),
  RwireSecondPartThirdImage: require("../../../assets/images/services_third_img.jpg"),
  RwireSecondPartForthImage: require("../../../assets/images/services_fourth_img.jpg"),
  RwireSecondPartFifthImage: require("../../../assets/images/services_fifth_img.jpg"),
  RwireServicesArrow: require("../../../assets/images/Vector.png"),
  RwireAboutUsBanner: require("../../../assets/images/about_us_banner.png"),
  RwireFaqBanner: require("../../../assets/images/faq_banner.png"),
  RwireSecondSectionImage: require("../../../assets/images/Who_we_are.png"),
  RwireTickImage: require("../../../assets/images/about_us_tick.png"),
  RWireImagesSectionFirst: require("../../../assets/images/s1.png"),
  RWireImagesSectionSecond: require("../../../assets/images/s2.png"),
  RWireImagesSectionThird: require("../../../assets/images/s3.png"),
  RWireImagesSectionFourth: require("../../../assets/images/s4.png"),
  RWireImagesSectionFifth: require("../../../assets/images/s5.png"),
  RwirePlusImage: require("../../../assets/images/plus_image.png"),
  RwireMinusImage: require("../../../assets/images/minus_image.png"),
};
export default RWIRE_IMAGES;
