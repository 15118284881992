import React, { useState } from "react";
import Image from "../../ui-components/ui-component/image";
import { SummaryVector } from "../../assets/images/report";
import "./styles.scss";
import { Modal } from "../../ui-components";
import SummaryTabContent from "./summary-tab-content";
import KeyFeaturesTabContent from "./key-features-tab-content";
import { isObjectBlank } from "../../action/result-table";
import { Message, toaster } from "rsuite";

const PatentSummarizer = (props) => {
  const {
    onGetSummaryOfPatent,
    detailsData,
    isSummaryFetching,
    onAddKeyFeaturesToReportProject,
    onGetKeyFeaturesOfPatent,
    isKeyfeaturesExtracting,
    onGetReportProjectNotes,
  } = props;
  const checklistElements = ["Title", "Abstract", "Claims"];
  const shortcodeMapping = {
    Title: "TI_EN",
    Claims: "CL_EN",
    Abstract: "AB_EN",
  };

  const [checkedItems, setCheckedItems] = useState({});
  const [summarizedSections, setSummarizedSections] = useState([]);
  const [isSummarizerPanelOpen, setIsSummarizerPanelOpen] = useState(false);
  const [summaries, setSummaries] = useState([]);

  const [checkedItemsKeyfeatures, setCheckedItemsKeyfeatures] = useState({});
  const [keyfeaturesSections, setKeyFeatureSections] = useState([]);
  const [keyFeatures, setKeyFeatures] = useState([]);
  const [checkedFeatures, setCheckedFeatures] = useState({});
  const [key, setkey] = useState("summary");
  const [emptySummary, setEmptySummary] = useState(true);
  const [emptyKeyFeature, setEmptyKeyFeature] = useState(true);
  const handleTabClick = (tab) => {
    setkey(tab);
  };

  const handleChangeSummaryFields = (event) => {
    const { name, checked } = event.target;
    let updatedCheckList = { ...checkedItems };

    if (checked) {
      updatedCheckList[name] = checked;
    } else {
      delete updatedCheckList[name];
    }
    setCheckedItems(updatedCheckList);
  };
  const handleChangeKeyFeaturesFields = (event) => {
    const { name, checked } = event.target;
    let updatedCheckList = { ...checkedItemsKeyfeatures };

    if (checked) {
      updatedCheckList[name] = checked;
    } else {
      delete updatedCheckList[name];
    }
    setCheckedItemsKeyfeatures(updatedCheckList);
  };
  const handleClose = () => {
    setIsSummarizerPanelOpen(false);
    setCheckedItems({});
    setSummaries([]);
    setCheckedItemsKeyfeatures({});
    setKeyFeatures([]);
    setEmptySummary(true);
    setEmptyKeyFeature(true);
  };
  const handleApply = async () => {
    try {
      const texts = [];
      const sections = [];
      const obj = key === "summary" ? checkedItems : checkedItemsKeyfeatures;
      for (const [item, isChecked] of Object.entries(obj)) {
        if (isChecked) {
          const shortcode = shortcodeMapping[item];

          if (shortcode && detailsData.hasOwnProperty(shortcode)) {
            texts.push(detailsData[shortcode]);
            sections.push(item);
          }
        }
      }
      let response = [];
      if (
        Object.keys(checkedItemsKeyfeatures).length === 0 &&
        Object.keys(checkedItems).length === 0 &&
        response.length === 0
      ) {
        setEmptySummary(true);
        setEmptyKeyFeature(true);
        setKeyFeatureSections([]);
        setKeyFeatures([]);
        setSummarizedSections([]);
        setSummaries([]);
        throw "";
      }

      if (key === "summary") {
        setEmptySummary(false);
        response = await onGetSummaryOfPatent(texts);
        if (response.length === 0) {
          setSummarizedSections([]);
          setSummaries([]);
          setEmptySummary(true);
          throw "";
        }
        setSummarizedSections(sections);
        setSummaries(response);
      } else {
        setEmptyKeyFeature(false);
        response = await onGetKeyFeaturesOfPatent(texts);
        if (response.length === 0) {
          setKeyFeatureSections([]);
          setKeyFeatures([]);
          setEmptyKeyFeature(true);
          throw "";
        }
        setKeyFeatureSections(sections);
        setKeyFeatures(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleKeyfeatures = (event, feature) => {
    const { name, checked } = event.target;
    let updatedCheckList = { ...checkedFeatures };

    if (checked) {
      updatedCheckList[name] = feature;
    } else {
      delete updatedCheckList[name];
    }
    setCheckedFeatures(updatedCheckList);
  };

  const handleAddKeyFeatures = async () => {
    if (!sessionStorage.getItem("projectId")) return;
    try {
      const dataArray = Object.values(checkedFeatures);
      const apiBody = {
        data: [],
        projectId: parseInt(sessionStorage.getItem("projectId")),
      };

      // Iterate over the array elements and construct the object
      for (let i = 0; i < dataArray.length; i++) {
        apiBody.data.push({
          body: dataArray[i],
          order: i + 1,
        });
      }

      await onAddKeyFeaturesToReportProject(apiBody);
      setCheckedFeatures({});
      onGetReportProjectNotes();
      toaster.push(
        <Message type="success">
          Key features added in project successfully.
        </Message>
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e.message);
    }
  };
  return (
    <>
      <button
        className="patent-summary-button"
        onClick={() => setIsSummarizerPanelOpen(true)}
      >
        <Image src={SummaryVector} />  AI Summary
      </button>
      <Modal
        isOpen={isSummarizerPanelOpen}
        onClose={() => {}}
        className="patent-summarizer-modal-body"
        overlayClass="patent-summarizer-modal-bg"
      >
        <div className="summarizer-header w-100 d-flex gap-2">
          <Image src={SummaryVector} /> AI Summary & Key Features
          <span onClick={handleClose} className="ms-auto cursor-pointer">
            X
          </span>
        </div>

        <div className="summaries-section py-2">
          <div className="tabs">
            <div
              className={`tab ${key === "summary" ? "active" : ""}`}
              onClick={() => handleTabClick("summary")}
            >
              Summary
            </div>
            <div
              className={`tab ${key === "keyFeatures" ? "active" : ""}`}
              onClick={() => handleTabClick("keyFeatures")}
            >
              Key Features
            </div>
          </div>
          {key === "summary" ? (
            <div className="tab-content">
              <SummaryTabContent
                checklistElements={checklistElements}
                checkedItems={checkedItems}
                onChange={handleChangeSummaryFields}
                summaries={summaries}
                summarizedSections={summarizedSections}
                isSummaryFetching={isSummaryFetching}
                emptySummary={emptySummary}
                onApply={handleApply}
              />
            </div>
          ) : (
            <div className="tab-content">
              <KeyFeaturesTabContent
                checklistElements={checklistElements}
                checkedItems={checkedItemsKeyfeatures}
                onChange={handleChangeKeyFeaturesFields}
                keyFeatures={keyFeatures}
                keyfeaturesSections={keyfeaturesSections}
                isKeyfeaturesExtracting={isKeyfeaturesExtracting}
                checkedFeatures={checkedFeatures}
                onHandleKeyfeatures={handleKeyfeatures}
                emptyKeyFeature={emptyKeyFeature}
                onApply={handleApply}
              />
            </div>
          )}
        </div>

        <div className="action-section py-2 px-3 d-flex justify-content-end gap-2">
          {((!sessionStorage.getItem("projectId") &&
            (key === "summary" || key === "keyFeatures")) ||
            (sessionStorage.getItem("projectId") && key === "summary")) && (
            <button
              className="white-secondary-button-color"
              onClick={handleClose}
            >
              Close
            </button>
          )}
          {sessionStorage.getItem("projectId") && key === "keyFeatures" && (
            <button
              className="yellow-primary-button-color"
              onClick={() => setCheckedFeatures({})}
            >
              Clear
            </button>
          )}
          {sessionStorage.getItem("projectId") && key === "keyFeatures" && (
            <button
              className={`blue-primary-button-color ${
                isObjectBlank(checkedFeatures) ? "disabled-btn" : ""
              }`}
              onClick={handleAddKeyFeatures}
              disabled={isObjectBlank(checkedFeatures)}
            >
              Add
            </button>
          )}
        </div>
      </Modal>
    </>
  );
};

export default PatentSummarizer;
