/* eslint-disable no-useless-escape */
import { useNavigate } from "react-router-dom";
import moment from "moment";
import refreshIcon from "../../images/refresh.svg";
import bookmarkIcon from "../../images/bookmark.svg";
import removeIcon from "../../images/remove.svg";
import editIcon from "../../images/history-edit.svg";
import { BiEdit } from "react-icons/bi";
import { clearFielded } from "../../common/clear-filded";
import { getChunksData } from "../../common/getChunksData";
import { constructQueries, getHistoryDetailsById } from "../../action/history";
import {
  getSpecializedSearchType,
  includeFieldsMapping,
} from "../../common/specialized-search-type";
import { getBeautifiedQuery } from "../common/content-editable/query-beautify";

const HistoryRow = (props) => {
  let navigate = useNavigate();

  const {
    isBookmark,
    id,
    numberOfHits,
    query,
    updatedAt,
    onDeleteHistory,
    onFetchHistory,
    onBookmarkHistory,
    selectedRows,
    onSetSelectedRows,
    onSetExportSelected,
    selectedItemList,
    onSetApp,
    setEditQuery,
    userSearchNumber,
    onPerformSearch,
    clickOnEditQuery,
    selectedRowsForCombine,
    onSetItemPerPage,
    historyTableRow = {},
    setSelectedField,
    onSetSelectedCombineQuery,
    onSetSelectedCombineQueryId,
    onSetEditQueryPopup,
  } = props;

  const deleteRecord = async () => {
    const isSuccess = await onDeleteHistory(id);
    if (isSuccess) {
      await onFetchHistory();
      const updatedSelectedRowsForCombine = selectedRowsForCombine.filter(
        (item) => {
          return `${item}` !== `${userSearchNumber}`;
        }
      );
      onSetItemPerPage({
        selectedRowsForCombine: updatedSelectedRowsForCombine,
      });
    }
  };

  const addBookMarkRecord = () => {
    onBookmarkHistory(id, !Boolean(isBookmark));
  };
  const getUpdatedCombinedQuery = async () => {
    let detailsArray = await getHistoryDetailsById({
      ids: historyTableRow.combine_queries,
    });

    const query = constructQueries(
      historyTableRow.combine_queries,
      historyTableRow.operators,
      detailsArray
    );
    return query[1];
  };
  const handleRunQuery = async () => {
    const finalQuery = historyTableRow.is_combined
      ? await getUpdatedCombinedQuery()
      : query;
    onSetApp({ searchQuery: finalQuery });
    if (
      historyTableRow?.includeFields &&
      historyTableRow?.includeFields.length > 0
    ) {
      onSetApp({
        selectedIncludes: historyTableRow?.includeFields,
        selectedNumberFilter: getSpecializedSearchType(
          historyTableRow.includeFields
        ),
      });
    }
    onPerformSearch(finalQuery, {
      isReRunId: id,
      isResetHighlight: true,
      isNumberWithIncludeSearch:
        historyTableRow?.includeFields &&
        historyTableRow?.includeFields.length > 0
          ? true
          : false,
    }).then((data) => {
      if (data) {
        navigate("/en/rwire-patents");
      } else {
        // eslint-disable-next-line no-console
        console.log(props.error);
      }
    });
  };

  let selectedId = [];
  let selectedRowNumber = [];

  const handleCheckbox = (e) => {
    selectedId = [...selectedRows, `${id}`];
    selectedRowNumber = [...selectedRowsForCombine, `${userSearchNumber}`];
    if (!e.target.checked) {
      selectedId = selectedId.filter((item) => {
        return `${item}` !== `${id}`;
      });
    }
    if (!e.target.checked) {
      selectedRowNumber = selectedRowNumber.filter((item) => {
        return `${item}` !== `${userSearchNumber}`;
      });
    }
    onSetItemPerPage({ selectedRowsForCombine: selectedRowNumber });

    const selectedList = historyTableRow;
    if (e.target.checked) {
      selectedItemList.push(selectedList);
      onSetExportSelected({ selectedItemList: selectedItemList });
    } else if (!e.target.checked) {
      const filteredNumbers = selectedItemList.filter((element) => {
        return element.id !== selectedList.id;
      });
      onSetExportSelected({ selectedItemList: filteredNumbers });
    }

    onSetSelectedRows({ selectedRows: selectedId });
  };
  const isNumberSearchHistory = (string) => {
    const allowedKeywords = [
      "PN",
      "AN",
      "PRN",
      "PN_B",
      "AN_B",
      "PAPR",
      "PA",
      "PPR",
      "APR",
    ];
    string = string.match(/([^=]+)(?=\>\=|\<\=|\=)/)
      ? string.match(/([^=]+)(?=\>\=|\<\=|\=)/)[1]
      : string;
    const words = string.split(" OR ");

    // Check if any of the words are not in the list of allowed keywords
    for (const word of words) {
      if (!allowedKeywords.includes(word)) {
        return false;
      }
    }
    return true;
  };
  const handleEditQuery = () => {
    onSetApp({
      editQueryObj: historyTableRow,
    });
    if (historyTableRow.is_combined) {
      setEditQuery(
        combineQueries(
          historyTableRow.combine_queries,
          historyTableRow.operators
        )
      );
      onSetEditQueryPopup({
        queryToEdit: combineQueries(
          historyTableRow.combine_queries,
          historyTableRow.operators
        ),
      });
      onSetSelectedCombineQueryId(id);
    } else {
      onSetSelectedCombineQuery("");
      onSetSelectedCombineQueryId(null);
      const clearData = clearFielded(props.queryFields);
      props.onSetAllField({
        queryFields: clearData,
        tabWiseSearchQuery: { fielded: query },
      });

      setEditQuery(query);
      onSetApp({
        editQuery: query,
        clickOnEditQuery: !clickOnEditQuery,
        editQueryId: id,
        isNumberSearchHistory: isNumberSearchHistory(query),
        editEnableOnSave: true,
      });

      let { equations, operators, input } = getChunksData(query);
      onSetApp({
        chunksArray: equations,
        operatorsArray: operators,
        inputsValue: input,
      });
      if (
        historyTableRow?.includeFields &&
        historyTableRow?.includeFields.length > 0
      ) {
        setSelectedField("Number");
        onSetApp({
          selectedIncludes: historyTableRow?.includeFields,
          selectedNumberFilter: getSpecializedSearchType(
            historyTableRow.includeFields
          ),
        });
      } else {
        onSetApp({
          selectedIncludes: [],
          selectedNumberFilter: "",
        });
      }
      onSetEditQueryPopup({
        queryToEdit: query,
      });
    }
    onSetEditQueryPopup({
      isOpenEditQueryModal: true,
      editQueryObj: historyTableRow,
    });
  };
  const combineQueries = (combine_queries, operators) => {
    let query = "";

    // Use a loop to construct the query string with the desired operators
    for (let i = 0; i < combine_queries.length; i++) {
      query += combine_queries[i];
      if (i < operators.length) {
        query += ` ${operators[i]} `;
      }
    }
    return query;
  };

  return (
    <tr className={`table-row ${isBookmark ? "active" : ""} edit-row `}>
      <td className="table-column regular-checkbox form-check regular-checkbox">
        <input
          className={`form-check-input cursor-pointer ${
            selectedRows.includes(`${id}`) ? "checked" : ""
          }`}
          key={id}
          type="checkbox"
          checked={selectedRows.includes(`${id}`)}
          value={id}
          onChange={handleCheckbox}
        />
      </td>
      <td className="table-column  numbers">{userSearchNumber}</td>
      <td
        className="table-column  search-query query-column d-flex justify-content-between gap-2"
        title={
          historyTableRow?.is_combined
            ? combineQueries(
                historyTableRow.combine_queries,
                historyTableRow.operators
              )
            : historyTableRow?.includeFields
            ? `Specialized search - ${
                includeFieldsMapping[
                  getSpecializedSearchType(historyTableRow.includeFields)
                ]
              } ${query}`
            : query
        }
      >
        <div
          className="edit-query-text"
          dangerouslySetInnerHTML={{
            __html: getBeautifiedQuery(
              historyTableRow?.is_combined
                ? combineQueries(
                    historyTableRow.combine_queries,
                    historyTableRow.operators
                  )
                : historyTableRow?.includeFields &&
                  historyTableRow?.includeFields.length > 0
                ? `Specialized search - ${
                    includeFieldsMapping[
                      getSpecializedSearchType(historyTableRow.includeFields)
                    ]
                  } ${query}`
                : query
            ),
          }}
        ></div>

        <div className="edit-button d-flex flex-row align-items-center opacity-class button-hover">
          {
            <>
              <img
                alt="edit"
                onClick={handleEditQuery}
                className="img-fluid rwire-img cursor-pointer mr-2"
                src={editIcon}
                title="Edit"
              />
              <img
                alt=""
                onClick={handleRunQuery}
                className="img-fluid search_history_icon cursor-pointer mr-2"
                src={refreshIcon}
                title="Rerun"
              />
              <img
                alt=""
                onClick={addBookMarkRecord}
                className="img-fluid rwire-img cursor-pointer mr-2"
                src={bookmarkIcon}
                title="Bookmark"
              />
              <img
                alt=""
                onClick={deleteRecord}
                className="img-fluid rwire-imgs cursor-pointer mr-2"
                src={removeIcon}
                title="Delete"
              />
            </>
          }
        </div>
      </td>
      <td className="table-column text-blue date-time">
        {moment.unix(updatedAt).format("DD-MM-YYYY")}
        <br />
        {moment.unix(updatedAt).format("HH:mm:ss")}
      </td>
      <td className="table-column text-blue records">{numberOfHits}</td>
    </tr>
  );
};

export default HistoryRow;
