import React, { useState, useEffect, useMemo } from "react";
import { Button } from "react-bootstrap";
import { Modal } from "../../ui-components";
import { updateTableData } from "../report/rwire-report-notes/utils";
import { splitClaimsFromText } from "../../ui-components/functions/notes/utils";
import SplitClaims from "./split-claims";

export default function ImportClaimModal(props) {
  const {
    isOpen,
    onClose,
    onSetPatentClaims,
    onSetData,
    tableData,
    onSplitPatentClaims,
    searchedPatentNumber,
    onAddRowToreportProject,
    onUpdateReportProjectNotesRow,
    onGetReportProjectNotes,
  } = props;

  const [patentNumber, setPatentNumber] = useState("");
  const [isClaimsFetched, setIsClaimsFetched] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [hideDependentItems, setHideDependentItems] = useState(false);
  const [items, setItems] = useState([]);
  const [splittedClaims, setSplittedClaims] = useState([]);
  const [selectedClaims, setSelectedClaims] = useState([]);
  const [text, setText] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [checklistErrorMessage, setChecklistErrorMessage] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [patentNumberError, setPatentNumberError] = useState("");
  const [multiPn, setMultiPN] = useState([]);
  const [isPnListOpen, setPnListOpen] = useState(false);

  const fetchHandle = async (e) => {
    e.preventDefault(); // Prevent the default form submission
    const trimmedPatentNumber = patentNumber.trim();
    if (trimmedPatentNumber) {
      const response = await onSetPatentClaims(trimmedPatentNumber);
      if (Array.isArray(response)) {
        let allPn = response.map((i) => i._id);
        setPnListOpen(true);
        setMultiPN(allPn);
        setIsClaimsFetched(true);
        setPatentNumberError("");
      } else if (response.claims) {
        setMultiPN([]);
        const processedClaims = onSplitPatentClaims(response.claims);
        setItems(processedClaims);
      } else if (response.error) {
        setMultiPN([]);

        setIsClaimsFetched(false);
        setPatentNumberError("Patent number not found");
      }
    } else {
      setPatentNumberError("Patent number is invalid");
    }
    setShowTable(true);
  };

  const filteredItems = useMemo(() => {
    if (hideDependentItems) {
      return items.filter((item) => item.INDP === "true");
    } else {
      return items;
    }
  }, [items, hideDependentItems]);

  const closeModalHandle = () => {
    onClose();
    onSetData({ claimsData: [], searchedPatentNumber: "" });
  };

  const handleCheckboxChange = (id) => {
    const updatedItems = items.map((item) =>
      item.id === id ? { ...item, checked: !item.checked } : item
    );
    setItems(updatedItems);
    setSelectAll(updatedItems.every((item) => item.checked));
  };

  const handleSelectAll = () => {
    const updatedItems = items.map((item) => ({
      ...item,
      checked: !selectAll,
    }));

    setItems(updatedItems);
    setSelectAll(!selectAll);
  };

  const handleApply = async () => {
    let checkedItems = items.filter((item) => item.checked);
    if (hideDependentItems) {
      checkedItems = checkedItems.filter((item) => item.INDP === "true");
    }
    const _id = sessionStorage.getItem("projectId");

    if (checkedItems.length > 0) {
      const updatedTableData = updateTableData(
        [...tableData],
        [...checkedItems]
      );
      // Update the tableData with the modified data in the database
      await Promise.all(
        // Check if the row already exists in the database
        updatedTableData
          .filter((row) => row.id)
          .map(async (row, index) => {
            await onUpdateReportProjectNotesRow({
              id: row.id,
              body: {
                body: row.body,
              },
            });
          })
      );

      if (updatedTableData.filter((row) => !row.id).length > 0) {
        await onAddRowToreportProject({
          data: updatedTableData.filter((row) => !row.id),
          projectId: parseInt(_id),
        });
      }

      await onGetReportProjectNotes();
      // Close modal and reset state
      onClose();
      onSetData({ claimsData: [], searchedPatentNumber: "" });
      setItems([]);
      setSelectAll(false);
    }
  };

  const handleClear = () => {
    const clearedItems = items.map((item) =>
      item.checked ? { ...item, checked: false } : item
    );
    const allUnchecked = clearedItems.every((item) => !item.checked);
    setItems(clearedItems);
    setSelectAll(false);
  };

  const handleApplySplitClaim = async () => {
    const _id = sessionStorage.getItem("projectId");
    if (selectedClaims.length > 0) {
      let updatedFeatures = selectedClaims.map((claim, index) => {
        return {
          body: splittedClaims[claim],
          order: index + 1,
        };
      });

      try {
        await onAddRowToreportProject({
          data: updatedFeatures,
          projectId: parseInt(_id),
        });

        await onGetReportProjectNotes();
        closeModalHandle();
      } catch (error) {
        // Handle error appropriately (e.g., show an error message to the user)
      }
    }
  };

  const handleClearSelections = () => {
    setSelectedClaims([]);
  };

  const handleAdd = async () => {
    await handleApply();
    await handleApplySplitClaim();
  };

  const handleHideDependentClaims = () => {
    setHideDependentItems((prevState) => !prevState);
  };

  const handlePnClick = (pnNumber) => {
    setPatentNumber(pnNumber);
    setPnListOpen(false);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        isStatic={false}
        overlayClass="project-creation-modal"
        className="project-creation-modal-body">
        <div className="modal-content border-0 fs-16">
          <div className="modal-header bg-white border-0 px-4">
            <h5 className="modal-title fs-16 primary-heading-text">
              Please enter patent number or claim:
            </h5>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={closeModalHandle}></button>
          </div>
          <div className="modal-body py-0 px-4">
            <form className="px-4 py-3 form-bg border">
              <div className="row align-items-center mb-3">
                <div className="col-1 me-4">
                  <label
                    htmlFor="patentNumber"
                    className="col-form-label fw-bold me-2 primary-heading-text">
                    Patent
                  </label>
                </div>
                <div className="col position-relative">
                  <input
                    type="text"
                    className="form-control fs-16 rounded"
                    id="patentNumber"
                    name="patentNumber"
                    placeholder="Fetch Claim By Patent Number"
                    value={patentNumber} // Bind state to input
                    onChange={(e) => {
                      setPatentNumber(e.target.value);
                      setPatentNumberError("");
                    }}
                  />
                  {patentNumberError && (
                    <p className="claims-err-message pl-2 ">
                      {patentNumberError}
                    </p>
                  )}
                  <div
                    className={`pn-list-claim position-absolute z-3 ${
                      isPnListOpen ? "" : "d-none"
                    }`}>
                    {multiPn.map((i) => {
                      return <div onClick={() => handlePnClick(i)}>{i}</div>;
                    })}
                  </div>
                </div>
                <div className="col-3  px-0 text-end mt-auto">
                  <button
                    type="submit"
                    className="create-project-btn btn-with-blue-border-white-bg fs-16 fw-bold text-nowrap"
                    onClick={fetchHandle}>
                    Fetch Claim
                  </button>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-1 me-4">
                  <label
                    htmlFor="text"
                    className="col-form-label fw-bold me-2 primary-heading-text">
                    Claim
                  </label>
                </div>
                <div className="col">
                  <textarea
                    className="form-control fs-16 rounded"
                    placeholder="Enter Claim"
                    style={{ resize: "none" }}
                    value={text}
                    onChange={(e) => {
                      setText(e.target.value);
                      setErrorMessage("");
                    }}></textarea>
                  <p className="me-auto claims-err-message">{errorMessage}</p>
                </div>
                <div className="col-3 px-0 text-end mt-auto">
                  <Button
                    className="create-project-btn btn-with-blue-border-white-bg fs-16 fw-bold text-nowrap"
                    onClick={() => {
                      if (text) {
                        setSplittedClaims(splitClaimsFromText(text));
                      } else {
                        setErrorMessage("Please enter text to split");
                      }
                    }}>
                    Split Claim
                  </Button>
                </div>
              </div>
            </form>
          </div>

          <>
            <div className="modal-body py-0 mt-1 px-4">
              <div className="keywords-table">
                <div className="header d-flex py-1 align-items-center justify-content-between">
                  {searchedPatentNumber &&
                    items.filter((item) => item.INDP === "false").length >
                      0 && (
                      <div className="position-absolute left-0 d-flex gap-2 align-items-center">
                        <input
                          type="checkbox"
                          checked={hideDependentItems}
                          onChange={handleHideDependentClaims}
                        />
                        <p className="hdc-heading">Hide Dependent Claims</p>
                      </div>
                    )}
                  <div className={`flex-grow-1 fw-bold text-center`}>
                    <span>Claims</span>
                  </div>
                </div>
                <div className="table-body ">
                  {showTable && (
                    <table className="table">
                      <tbody>
                        {filteredItems.map((item) => (
                          <tr key={item.id}>
                            <td
                              style={{
                                borderLeft: "none", // Remove the left border for checkboxes in each row
                              }}>
                              <input
                                type="checkbox"
                                checked={item.checked}
                                onChange={() => handleCheckboxChange(item.id)}
                              />
                            </td>
                            <td
                              style={{
                                borderBottom: "1px solid #ccc", // Bottom border for each claim
                                padding: "10px",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: item.label,
                              }}></td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}

                  <SplitClaims
                    splittedClaims={splittedClaims}
                    setSplittedClaims={setSplittedClaims}
                    selectedClaims={selectedClaims}
                    setSelectedClaims={setSelectedClaims}
                    checklistErrorMessage={checklistErrorMessage}
                    setChecklistErrorMessage={setChecklistErrorMessage}
                  />
                </div>
              </div>
            </div>
          </>

          <div className="modal-footer border-0 d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center ml-4">
              {filteredItems.length > 0 && (
                <>
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAll}
                    className="me-2"
                  />
                  <span>Select All</span>
                </>
              )}
            </div>

            <div>
              <button
                type="button"
                className="btn cancel-btn fs-16 mr-2"
                onClick={() => {
                  handleClear();
                  handleClearSelections();
                }}>
                Clear
              </button>
              <Button
                type="button"
                className="create-project-btn btn-with-blue-border-white-bg fs-16"
                onClick={handleAdd}>
                Add
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
