import React, { useRef, useState } from "react";
import useClickOutside from "../../ui-components/custom-hooks/useClickoutside";
import { Image } from "../../ui-components";
import { DownArrow } from "../../assets/images/report";

const ExistingWordsDropdown = ({
  onChange,
  defaultValue = "",
  placeholder = "",
  disabled = false,
  data,
  menuClassName = "",
  optionClassName = "text-break",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    if (!disabled && data && data.length > 0) {
      setIsOpen((prevState) => !prevState);
    }
  };

  const handleOptionChange = (value) => {
    setSelectedOption(value.title);
    toggleDropdown();
    onChange(value);
  };

  useClickOutside(dropdownRef, () => {
    setIsOpen(false);
  });
  return (
    <div className={`dropdown internal-dropdown`}>
      <div ref={dropdownRef}>
        <div
          className={`${
            disabled || (data && data.length === 0) ? "disable-dropdown" : ""
          } dropdown-box`}
          onClick={toggleDropdown}
        >
          <button
            className={`btn bg-white ${
              selectedOption || defaultValue ? "ellipsis" : ""
            }`}
            type="button"
            id="multiSelectDropdown"
            disabled={disabled || (data && data.length === 0)}
          >
            {selectedOption
              ? selectedOption
              : defaultValue
              ? defaultValue
              : placeholder}
          </button>
          <Image src={DownArrow} alt="" />
        </div>
        {isOpen && (
          <div
            className={`dropdown-menu left-auto end-0 ${menuClassName} ${
              isOpen ? "show" : ""
            }`}
            ariaLabelledby="multiSelectDropdown"
          >
            {data &&
              data.length > 0 &&
              data.map((option) => (
                <div
                  key={option}
                  className={`form-check form-check-without-icon cursor-pointer vertical-middle py-0 ${optionClassName}`}
                  onClick={() => handleOptionChange(option)}
                >
                  {option.title}
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ExistingWordsDropdown;
