import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ExistingProject } from "../../components/report";
import {
  createReportProject,
  deleteReportProject,
  getBackwardCitationsData,
  getBackwardCitedPatentsList,
  getReportProjectDataById,
  getReportProjects,
  getReportWorkspaceElements,
  resetProjectData,
  setProjectReport,
  updateReportProject,
} from "../../action/report/report-project";
import { setApp } from "../../action/app";
import { getAllProjectUsers } from "../../action/report/report-users";
import { checkIfPatentAvailable } from "../../action/keyword-suggestor";
import { performSearch, setResultTable } from "../../action/rwire-search-api";
import { setAllField } from "../../action/search-query";

const Container = (props) => {
  return <ExistingProject {...props} />;
};

const mapStateToProps = ({ app, searchQuery: { tabWiseSearchQuery } }) => ({
  ...app,
  tabWiseSearchQuery,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onGetReportProjects: getReportProjects,
      onUpdateReportProject: updateReportProject,
      onResetProjectData: resetProjectData,
      onSetApp: setApp,
      onSetProjectReport: setProjectReport,
      onGetReportProjectDataById: getReportProjectDataById,
      onGetAllProjectUsers: getAllProjectUsers,
      onGetBackwardCitationsData: getBackwardCitationsData,
      onCreateReportProject: createReportProject,
      onGetReportWorkspaceElements: getReportWorkspaceElements,
      onCheckIfPatentAvailable: checkIfPatentAvailable,
      onGetBackwardCitedPatentsList: getBackwardCitedPatentsList,
      onDeleteReportProject: deleteReportProject,
      onPerformSearch: performSearch,
      onSetAllField: setAllField,
      onSetResultTable:setResultTable,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Container);
