import React from "react";
import DataRow from "./data-row";

const DataTable = ({
  data = [],
  mapping = {},
  columnWidths = {},
  field = "",
  tableTitle = "",
}) => {
  const headers = Object.keys(mapping);

  return (
    <>
      {data.length > 0 && tableTitle && (
        <p className="report-table-title px-4">{tableTitle}</p>
      )}
      
      {data && data.length > 0 && (
        <table className="w-100 table custom-data-table pt-1 pb-2">
          <thead>
            <tr className="header-row">
              {headers.map((header, index) => (
                <th
                  key={index}
                  className="text-center"
                  style={{
                    width: `${columnWidths[index]}%`,
                    maxWidth: `${columnWidths[index]}%`,
                  }}
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="">
            {data.map((row, rowIndex) => (
              <DataRow
                key={rowIndex}
                rowIndex={rowIndex}
                headers={headers}
                field={field}
                mapping={mapping}
                row={row}
                data={data}
              />
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};

export default DataTable;
