import React, { useEffect, useState } from "react";
import { Modal } from "rsuite";
import RWireButton from "../rwire-ui/rwire-button/rwire-button";
import RWIRE_IMAGES from "../common/common-functions/rwire-images";
import { Input } from "rsuite";
import CloseIcon from "@rsuite/icons/Close";
import { useNavigate } from "react-router-dom";
import { clearFielded } from "../../common/clear-filded";
import "./edit-query-popup.scss";
import {
  addHistory,
  constructQueries,
  getHistoryDetailsById,
  getQueryCount,
  updateHistoryRecord,
} from "../../action/history";
import { getBeautifiedQuery } from "../common/content-editable/query-beautify";
const EditQueryPopup = (props) => {
  const {
    isOpenEditQueryModal,
    onSetEditQueryPopup,
    queryToEdit,
    validationQuery,
    syntaxqueryError,
    syntaxqueryCorrect,
    onSetQuery,
    onSetApp,
    onUpdateQueryCount,
    onFetchHistory,
    editQueryObj,
    onPerformSearch,
    onSetAllField,
    queryFields,
    selectedCombineQueryId,
    previousPage = "rwire-patents",
    onSetCombineQueryPopup,
  } = props;
  const [queryValue, setQueryValue] = useState("");
  const [clearAll, setClearAll] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setQueryValue(queryToEdit);
  }, [queryToEdit]);
  useEffect(() => {
    setQueryValue(queryToEdit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenEditQueryModal]);
  const handleChange = (str) => {
    setQueryValue(str);
    onSetQuery({ syntaxqueryCorrect: "", syntaxqueryError: "" });
  };
  const handleClear = () => {
    setQueryValue("");
    setClearAll(true);
  };
  const queryValidation = () => {
    const queryToCheck = queryValue;
    return validationQuery(queryToCheck);
  };
  const getUpdatedCombinedQuery = async () => {
    try {
      let numbers = queryValue.match(/\d+/g);
      let operators = queryValue.match(/(OR|AND|NOT)/gim);
      if (!numbers || !operators || numbers.length !== operators.length + 1) {
        onSetQuery({ syntaxqueryError: "Please check the input" });
        throw new Error(
          "Invalid query format. Unable to extract numbers or operators."
        );
      }

      // Fetch the history details for the given numbers
      let detailsArray = await getHistoryDetailsById({ ids: numbers });

      const query = constructQueries(numbers, operators, detailsArray);
      if (query[2].includes(editQueryObj?.userSearchNumber)) {
        // eslint-disable-next-line no-alert
        alert("Can't add current query in combine query.");
        throw new Error("Can't add current query in combine query.");
      }

      const resultTotal = await getQueryCount(
        query[1].replaceAll("( ", "(").replaceAll(" )", ")")
      );
      console.log(resultTotal);

      return {
        query: query[1].replaceAll("( ", "(").replaceAll(" )", ")"),
        numberOfHits: resultTotal.count,
        operators: operators,
        ids: query[2],
      };
    } catch (error) {
      // Handle errors
      onSetQuery({ syntaxqueryError: "Please check the input" });
      console.error(
        "Error occurred while getting updated combined query:",
        error.message
      );
      return null;
    }
  };
  const handleSave = async () => {
    const isCombined = editQueryObj?.is_combined || false;
    if (isCombined) {
      const { query, numberOfHits, operators, ids } =
        await getUpdatedCombinedQuery();
      if (!query) {
        // Handle the case where getUpdatedCombinedQuery returns null (indicating an error)
        throw new Error("Failed to get updated combined query.");
      }
      onSetEditQueryPopup({
        isOpenEditQueryModal: false,
      });
      await updateHistoryRecord(editQueryObj?.id, {
        query: query,
        numberOfHits: numberOfHits,
        operators: operators,
        combineId: ids,
      });
      await onSetApp({
        queryEvaluatorText: query,
        queryEvaluatorExpertSearchError: "",
      });
      setTimeout(async () => {
        await onFetchHistory();
      }, 1000);
      onSetCombineQueryPopup({
        selectedQueryRows: [],
        selectedCombineQueryRows: [],
        selectedCombineQuery: "",
        selectedCombineQueryId: 0,
      });
    } else {
      const id = editQueryObj?.id;
      const isValid = await queryValidation();
      if (id && isValid !== false) {
        try {
          onSetEditQueryPopup({
            isOpenEditQueryModal: false,
          });
          onUpdateQueryCount(id, queryValue);
          await onSetApp({
            queryEvaluatorText: queryValue,
            editEnableOnSave: false,
          });
          setTimeout(async () => {
            await onFetchHistory();
          }, 1000);
          onSetEditQueryPopup({
            queryToEdit: "",
            editQueryObj: {},
          });
        } catch (error) {
          onSetApp({ syntaxqueryError: "Please check operators" });
        }
      } else {
        return;
      }
    }
  };
  const handleSearch = async () => {
    const isValid = await queryValidation();
    if (isValid !== false) {
      onSetEditQueryPopup({
        isOpenEditQueryModal: false,
      });
      onSetApp({ searchQuery: queryValue, searchedTab: "fielded" });
      const isReRunId = editQueryObj?.id;
      onPerformSearch(
        queryValue,
        {
          isResetHighlight: true,
          isReRunId: isReRunId,
        },
        navigate,
        `/en/${previousPage}`
      ).then((data) => {
        if (data) {
          const clearData = clearFielded(queryFields);
          onSetAllField({ queryFields: clearData });
        } else {
          // eslint-disable-next-line no-console
          console.log(props.error);
        }
      });
    } else {
      return;
    }
  };
  useEffect(() => {
    setTimeout(() => {
      onSetQuery({ syntaxqueryCorrect: "", syntaxqueryError: "" });
    }, 2000);
  }, [syntaxqueryCorrect, syntaxqueryError]);

  return (
    <Modal
      open={isOpenEditQueryModal}
      className="modalClassFilter edit-query-modal"
      onClose={() => {
        setClearAll(false);
        setQueryValue(queryToEdit);
        onSetEditQueryPopup({
          isOpenEditQueryModal: false,
        });
      }}
      static="static"
    >
      <div className="d-flex flex-column justify-content-center px-3 py-3">
        <div className="d-flex flex-row align-items center py-3 justify-content-between">
          <div className="popup-title">Edit Query</div>
          <div>
            <div
              className="popup-close"
              onClick={() => {
                onSetEditQueryPopup({
                  isOpenEditQueryModal: false,
                });
              }}
            >
              <CloseIcon />
            </div>
          </div>
        </div>
        <Input
          as="textarea"
          className="query-edit-field"
          value={queryValue}
          onChange={handleChange}
          spellcheck="false"
        />
        <div className="d-flex flex-row justify-content-between">
          <div
            className="mt-2 d-flex flex-row align-items-center justify-content-start"
            onClick={queryValidation}
          >
            <span className="check-syntax-span cursor-pointer">
              Syntax Check
            </span>
            <input
              type="checkbox"
              className="mx-2"
              checked
              onChange={queryValidation}
            />

            {syntaxqueryCorrect ? (
              <span
                className={`
                  correct-syntax-span    
                `}
              >
                {syntaxqueryCorrect}
              </span>
            ) : (
              <span
                className={`
                  incorrect-syntax-span
                }`}
                dangerouslySetInnerHTML={{
                  __html: syntaxqueryError,
                }}
              ></span>
            )}
          </div>
          <div className="d-flex flex-row justify-content-end mt-2 align-items-center">
            <div className="mr-2 clear-btn" onClick={handleClear}>
              Clear All
            </div>
            <div className="search-query">
              <button
                className={`input-button-text-form save-btn mr-2`}
                onClick={handleSave}
              >
                Save
              </button>
            </div>
            <div className="search-query">
              <RWireButton
                cNameDiv="search-query"
                buttonCName="input-button-text-form"
                name="Search"
                buttonImg={RWIRE_IMAGES.RwireSearchBlackIcon}
                onClick={handleSearch}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditQueryPopup;
