const initialState = {
    isOpenEditQueryModal: false,
    queryToEdit: "",
    editQueryObj: {},
};

const editQuery = (state = initialState, { type, payload }) => {
    switch (type) {
        case "EDIT_QUERY_SET":
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};

export default editQuery;
