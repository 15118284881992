import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  getProjectDashboard,
  getReportProjectRelevantResults,
  getReportProjectString,
} from "../../action/report/report-dashboard";
import { useEffect } from "react";
import { getReportProjectNotes } from "../../action/report/report-notes";
import GraphsSection from "../../components/report-new/graphsSection";

const Container = (props) => {
  const { onGetProjectDashboard, onGetReportProjectRelevantResults } = props;
  const projectId = sessionStorage.getItem("projectId");

  useEffect(() => {
    const fetchData = async () => {
      if (projectId) {
        await onGetProjectDashboard(projectId);
        await onGetReportProjectRelevantResults(projectId);
      }
    };
    fetchData();
  }, [projectId, onGetProjectDashboard, onGetReportProjectRelevantResults]);

  return <GraphsSection {...props} />;
};

const mapStateToProps = ({
  reportDashboard,
  reportNotes: { reportTableData: keyFeaturesList = [] },
}) => ({
  ...reportDashboard,
  keyFeaturesList,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onGetProjectDashboard: getProjectDashboard,
      onGetReportProjectString: getReportProjectString,
      onGetReportProjectNotes: getReportProjectNotes,
      onGetReportProjectRelevantResults: getReportProjectRelevantResults,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Container);
