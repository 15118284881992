import React from "react";
import { useNavigate } from "react-router-dom";

const RwireClickableField = (props) => {
  const {
    name,
    isLast,
    onPerformSearch,
    shortCode,
    joiningCharacter,
    isViewPage,
    onSetApp,
  } = props;
  const navigate = useNavigate();
  const handleClick = () => {
    if (!isViewPage) return;
    const query = `${shortCode}=(${name})`;
    navigate(`/en/rwire-patents`);
    onSetApp({ selectedIncludes: [] });
    onPerformSearch(query, { isResetHighlight: true });
  };
  return (
    <>
      <span
        onClick={handleClick}
        className={`${isViewPage ? "cursor-pointer link" : ""}
        ${shortCode === "CA" ? "long-name-margin" : ""}`}
      >
        {name}
        {!isLast && joiningCharacter}
      </span>
    </>
  );
};

export default RwireClickableField;
