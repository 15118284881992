import React, { useRef, useState, useEffect } from "react";
import { Image } from "../../../ui-components/ui-component";
import useClickOutside from "../../../ui-components/custom-hooks/useClickoutside";
import { DownArrow } from "../../../assets/images/report";
import "./dateSelector.scss";

const Dropdown = ({
  onChange,
  defaultValue = "",
  placeholder = "",
  disabled = false,
  data,
  menuClassName = "",
  btnClass = "",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const dropdownRef = useRef(null);
  const menuRef = useRef(null);

  const toggleDropdown = () => {
    if (!disabled && data && data.length > 0) {
      setIsOpen((prevState) => !prevState);
    }
  };

  const handleOptionChange = (value) => {
    setSelectedOption(value.label);
    toggleDropdown();
    onChange(value.label);
  };

  // Close dropdown when clicking outside
  useClickOutside(dropdownRef, () => {
    setIsOpen(false);
  });

  // Use effect to handle positioning of selected option
  useEffect(() => {
    if (isOpen && (selectedOption || defaultValue)) {
      const selectedValue = selectedOption || defaultValue;
      const selectedIndex = data.findIndex(
        (option) => option.title === selectedValue
      );

      if (selectedIndex >= 0 && menuRef.current) {
        // Get the corresponding option element
        const selectedOptionElement = menuRef.current.children[selectedIndex];

        if (selectedOptionElement) {
          // Calculate the position of the selected option and adjust the scroll position
          menuRef.current.scrollTop =
            selectedOptionElement.offsetTop - menuRef.current.offsetTop;
        }
      }
    }
  }, [isOpen, selectedOption, defaultValue, data]);

  return (
    <div className={`dropdown date-selector-dropdown`}>
      <div ref={dropdownRef}>
        <div
          className={`${
            disabled || (data && data.length === 0) ? "disable-dropdown" : ""
          } dropdown-box`}
          onClick={toggleDropdown}
        >
          <button
            className={`bg-white ellipsis ${btnClass}`}
            type="button"
            id="multiSelectDropdown"
            disabled={disabled || (data && data.length === 0)}
          >
            {selectedOption
              ? selectedOption
              : defaultValue
              ? defaultValue
              : placeholder}
            <Image src={DownArrow} alt="" />
          </button>
        </div>
        {isOpen && (
          <div
            ref={menuRef}
            className={`dropdown-menu end-0 ${menuClassName} ${
              isOpen ? "show" : ""
            }`}
            aria-labelledby="multiSelectDropdown"
          >
            {data &&
              data.length > 0 &&
              data.map((option, index) => (
                <div
                  key={option.code}
                  className="form-check px-0 form-check-without-icon"
                  onClick={() =>
                    handleOptionChange({
                      label: option.title,
                      code: option.code,
                    })
                  }
                >
                  <label
                    className={`form-check-label py-0 ${
                      selectedOption === option.title ||
                      defaultValue === option.title
                        ? "fw-bold"
                        : ""
                    }`}
                  >
                    {option.title}
                  </label>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Dropdown;
