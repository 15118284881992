import { fieldsListForSorting } from "../resources/data/sorting-fields-list";
import { search } from "../service/elasticSearch";
import { classes_suggestor } from "../components/constant";
import { displayError } from "../service/display-error";
import { fetch } from "../service/fetch";
import { applyMappings } from "../pages/rwire-patent-view/desc-block";

export const setFullView = (payload) => ({
  type: "FULL_VIEW_SET",
  payload: {
    ...payload,
  },
});

export const getPriorityArray = (shortcode) => {
  const languageCodes = ["EN", "FR", "ES", "DE", "CN", "JP", "KR"];
  const startIndex = languageCodes.indexOf(shortcode);

  const reorderedArray = [
    ...languageCodes.slice(startIndex),
    ...languageCodes.slice(0, startIndex),
  ];
  return reorderedArray;
};
export const getLocalizedValue = (data, languageSelected = "EN", shortcode) => {
  if (data) {
    let languagePriority = getPriorityArray(languageSelected);
    let value;
    let languageCode = "";
    for (let i = 0; i < languagePriority.length; i++) {
      languageCode = languagePriority[i];
      value = data[`${shortcode}_${languageCode}`];

      if (value) {
        if (typeof value !== "undefined" && value.length >= 1) {
          return value;
        }
      }
    }
    return "";
  } else return "";
};
export const getFormatizedValue = (data, shortcode) => {
  if (data) {
    const formatCodes = ["B", "O", "D", "DA", "EPO", "T", "L"];
    let value;
    for (let i = 0; i < formatCodes.length; i++) {
      let formatCode = formatCodes[i];
      value = data[`${shortcode}_${formatCode}`];

      if (value) {
        if (typeof value !== "undefined" && value.length >= 1) {
          return value;
        }
      }
    }
    return "";
  } else return "";
};

export const getMax = (arr) => {
  if (!arr) {
    return null;
  }
  var minV = arr[0];
  var maxV = arr[0];
  for (let a of arr) {
    if (a < minV) minV = a;
    if (a > maxV) maxV = a;
  }
  return maxV;
};

export const removeTagNames = (str = "") => {
  return str
    .replaceAll("&amp;", "&")
    .replaceAll("&gt;", ">")
    .replaceAll("&lt;", "<")
    .replace(/<\/?claim-text>/g, " ")
    .replace(/\s+/g, " ")
    .trim();
};

export const transformedString = (text) => {
  if (!text.includes("<claim-text>") && !text.includes("</claim-text>")) {
    // below regex will match markers in the start of string
    // <b>1</b>) This is a line starting with <b>1</b>.
    // <b>2</b>. This line starts with <b>2</b>.
    // <b>3</b># This line starts with <b>3</b> and has a hash symbol.
    const pattern = /^\s*<b>\d+<\/b>[.)^#]?\s+/gm;
    let cleanedText = text;
    let match = cleanedText.match(pattern);

    if (match) {
      cleanedText = cleanedText.replace(pattern, "");
      match = cleanedText.match(pattern);
    } else {
      // Matches zero or more whitespace characters followed by one or more digits, one character from [.)^#], and a single whitespace character
      const markerRegex = /\s*\d+[.)^#]\s/;
      cleanedText = cleanedText.replace(markerRegex, "");
    }
    return cleanedText.trim();
  }

  let str = text.replace(/\n/g, "");

  str = str.replace(
    /(<claim-text[^>]*>\s*)\d+[^a-zA-Z0-9\s]*\s*(.*?)(\s*<\/claim-text>)/gi,
    "$1$2$3"
  );

  str = str.replace(/(<claim-text>)?<b>\d+<\/b>\. /gm, "$1");

  return str;
};

export const getSortedArray = (array = [], fieldName = "", field = "") => {
  if (fieldsListForSorting.includes(field)) {
    array.sort((a, b) => a[fieldName] - b[fieldName]);
    return array;
  } else return array;
};

export const removeHighlightTags = (text) => {
  const regex = /<span class="highlight-word"[\s\S]*?>([\s\S]*?)<\/span>/gi;
  return text.replace(regex, "$1");
};
const setResultTableClasess = (payload) => ({
  type: "RESULT_SET",
  payload: {
    ...payload,
  },
});

const mergeDefinitions = (results, codes, classesType) => {
  let mergedArray = [];

  // Iterate over each code
  for (let code of codes) {
    let found = false;
    // Search for the code in the results
    for (let result of results) {
      if (result._source.code.includes(code)) {
        mergedArray.push(result);
        found = true;
        break;
      }
    }
    // If code not found, add an object with null definition, type, and code
    if (!found) {
      mergedArray.push({
        _source: {
          type: classesType.toUpperCase(),
          code: [code],
          definition: "Data not available",
        },
      });
    }
  }

  return mergedArray;
};

export const searchForClassAndKeyword =
  (classes, classesType) => async (dispatch, getState) => {
    if (classes.length > 0) {
      const queryObj = {
        classesAndkeywords: { classes: classes.join(",") },
        classesType,
        dataFrom: 0,
        dataSize: 100,
        isWildcardSearch: false,
      };

      const {
        resultTable: {
          classesTableData: { ipc, cpc, us },
        },
      } = getState();

      const body = JSON.stringify(queryObj);
      try {
        const data = await search(body, classes_suggestor);
        const dataResponse = data.data;
        const tableData = mergeDefinitions(
          dataResponse.hits.hits,
          classes,
          classesType
        );

        if (classesType === "ipc") {
          dispatch(
            setResultTableClasess({
              classesTableData: { us, cpc, ipc: tableData },
            })
          );
        } else if (classesType === "cpc") {
          dispatch(
            setResultTableClasess({
              classesTableData: { us, cpc: tableData, ipc },
            })
          );
        } else if (classesType === "us") {
          dispatch(
            setResultTableClasess({
              classesTableData: { us: tableData, cpc, ipc },
            })
          );
        }
      } catch (error) {
        displayError(error);
      }
    }
  };

export const removeHighlightByUid = (data, uid, field) => {
  const updatedData = { ...data };
  if (updatedData.hasOwnProperty(field)) {
    updatedData[field] = updatedData[field].filter(
      (highlight) => highlight.Uid !== uid
    );

    if (updatedData[field].length === 0) {
      delete updatedData[field];
    }
  }
  return updatedData;
};
export const updateDataById = (data, id, field, newComment) => {
  const updatedData = { ...data };

  if (updatedData.hasOwnProperty(field)) {
    updatedData[field] = updatedData[field].map((item) => {
      if (item.Uid === id) {
        item.commentText = newComment;
      }
      return item;
    });
  }

  return updatedData;
};

export const applyMultipleHighlights = (inputText = "", ranges = []) => {
  if (ranges && ranges.length === 0) return inputText;
  try {
    const container = document.createElement("div");
    container.innerHTML = applyMappings(inputText);

    const visibleTextNodes = getVisibleTextNodes(container);
    const adjustedRanges = adjustRangesForVisibleText(ranges, visibleTextNodes);

    // Sort the ranges by the priority (null commentText last)
    adjustedRanges.sort((a, b) =>
      a.commentText === null ? 1 : b.commentText === null ? -1 : 0
    );
    const highlightElements = [];

    for (const {
      start,
      end,
      highlightColor,
      commentText,
      Uid,
    } of adjustedRanges) {
      const startNode = findTextNodeWithPosition(visibleTextNodes, start);
      const endNode = findTextNodeWithPosition(visibleTextNodes, end);

      if (startNode && endNode) {
        const range = new Range();
        range.setStart(startNode.node, startNode.offset);
        range.setEnd(endNode.node, endNode.offset);

        const highlightElement = document.createElement("span");
        if (commentText) {
          highlightElement.classList.add("commented-keywords");
          highlightElement.setAttribute("title", `${commentText}`);
        } else {
          highlightElement.classList.add("highlight-keywords");
          highlightElement.classList.add("manual-highlight");
        }
        highlightElement.classList.add("annoted-text");
        highlightElement.style.backgroundColor = highlightColor;
        highlightElement.style.color = "#000";
        highlightElement.id = Uid;

        if (commentText) {
          const tooltip = document.createElement("span");
          tooltip.classList.add("tooltip");
          tooltip.textContent = commentText;
          highlightElement.appendChild(tooltip);
        }

        highlightElements.push({ range, highlightElement });
      }
    }

    // Adjust overlapping highlights
    for (let i = 0; i < highlightElements.length; i++) {
      const currentRange = highlightElements[i].range;
      for (let j = i + 1; j < highlightElements.length; j++) {
        const nextRange = highlightElements[j].range;
        if (isRangeContained(currentRange, nextRange)) {
          highlightElements.splice(j, 1); // Remove the nextRange from the highlightElements array
          j--; // Decrement the index to avoid skipping the next range after the removed one
        }
      }
    }

    // Apply the highlights
    highlightElements.forEach(({ range, highlightElement }) => {
      const fragment = range.extractContents();
      const clone = highlightElement.cloneNode(true);
      clone.appendChild(fragment);
      range.insertNode(clone);
    });

    // Remove tooltips from the container
    container.querySelectorAll(".tooltip").forEach((tooltip) => {
      tooltip.parentNode.removeChild(tooltip);
    });

    return container.innerHTML;
  } catch (e) {
    // console.log(e);
    return applyMappings(inputText);
  }
};

const findTextNodeWithPosition = (textNodes, position) => {
  for (const node of textNodes) {
    const nodeLength = node.textContent.length;
    if (position <= nodeLength) {
      return { node, offset: position };
    }
    position -= nodeLength;
  }
  return null;
};

const isRangeContained = (range1, range2) => {
  return (
    range1.compareBoundaryPoints(Range.START_TO_START, range2) <= 0 &&
    range1.compareBoundaryPoints(Range.END_TO_END, range2) >= 0
  );
};

const getVisibleTextNodes = (element) => {
  const walker = document.createTreeWalker(
    element,
    NodeFilter.SHOW_TEXT,
    null,
    false
  );
  const textNodes = [];

  let node;
  while ((node = walker.nextNode())) {
    if (node.textContent.trim() !== "") {
      textNodes.push(node);
    }
  }

  return textNodes;
};

const adjustRangesForVisibleText = (ranges, textNodes) => {
  const visibleText = textNodes.map((node) => node.textContent).join(" ");

  const adjustedRanges = [];

  for (const { start, end, highlightColor, commentText, Uid } of ranges) {
    if (end > 0 && start < visibleText.length) {
      const adjustedStart = Math.max(start, 0);
      const adjustedEnd = Math.min(end, visibleText.length);

      if (adjustedStart < adjustedEnd) {
        adjustedRanges.push({
          start: adjustedStart,
          end: adjustedEnd,
          highlightColor,
          commentText,
          Uid,
        });
      }
    }
  }
  return adjustedRanges;
};

export const getUniqueNFValues = (arr) => {
  const uniqueValues = [];

  arr.forEach((obj) => {
    const nfValue = obj.NF;

    // Check if the nfValue matches the pattern "XX YYYYMMDD"
    const match = nfValue.match(/^[A-Z]{2}\s\d{8}$/);

    if (match && !uniqueValues.includes(nfValue)) {
      uniqueValues.push(nfValue);
    }
  });

  return uniqueValues;
};
export const getMergedObj = (
  previousObj,
  fieldName,
  selectionIndex,
  comment,
  Uid,
  color
) => {
  const newObj = {
    [fieldName]: [
      {
        ...selectionIndex,
        highlightColor: color,
        commentText: comment,
        Uid: Uid,
      },
      ...(previousObj?.[fieldName] || []),
    ],
  };
  const mergedObj = {
    ...newObj,
  };

  return mergedObj;
};

export const mergeObjects = (obj1, obj2) => {
  for (const key in obj2) {
    if (!obj1[key]) {
      obj1[key] = obj2[key];
    } else {
      const existingKeys = obj1[key].map(JSON.stringify);
      obj2[key].forEach((item) => {
        if (!existingKeys.includes(JSON.stringify(item))) {
          obj1[key].push(item);
        }
      });
      // obj1[key] = obj1[key].concat(obj2[key]);
    }
  }
  return obj1;
};

function groupObjectsByBody(data) {
  const groupedData = {};

  data.forEach((item) => {
    const { body } = item;
    const parsedBody = JSON.parse(body);

    for (const key in parsedBody) {
      if (!groupedData[key]) {
        groupedData[key] = [];
      }
      groupedData[key] = groupedData[key].concat(parsedBody[key]);
    }
  });
  return groupedData;
}

export const getUserAnnotations = (body) => async (dispatch) => {
  let response;
  try {
    const id = sessionStorage.getItem("projectId");
    response = await fetch(
      {
        url: `/api/text-annotate/get`,
        body: { ...body, projectId: id },
      },
      "POST"
    );

    if (response.length > 0) {
      const highlightObj =
        response.find((item) => item.type === "highlight") || null;
      const commentObj =
        response.find((item) => item.type === "comment") || null;

      dispatch(
        setFullView({
          userAnnotations: groupObjectsByBody(response) || [],
          stringsHighlights: highlightObj ? JSON.parse(highlightObj.body) : [],
          stringsComments: commentObj ? JSON.parse(commentObj.body) : [],
        })
      );
    } else {
      dispatch(
        setFullView({
          userAnnotations: [],
          stringsHighlights: [],
          stringsComments: [],
        })
      );
    }
  } catch (error) {
    dispatch(
      setFullView({
        userAnnotations: [],
        stringsHighlights: [],
        stringsComments: [],
      })
    );
  }
};
export const updateUserAnnotations = (body) => async (dispatch) => {
  try {
    const id = sessionStorage.getItem("projectId");
    await fetch(
      {
        url: `/api/text-annotate`,
        body: { ...body, projectId: id },
      },
      "POST"
    );
  } catch (error) {}
};

export const setInfoIconPosition = (element) => {
  // const height = element.getBoundingClientRect().top + window.pageYOffset;
  // element.style.setProperty("--top", height);
  // const left = element.getBoundingClientRect().left + window.pageXOffset;
  // element.style.setProperty("--left", left);
};
