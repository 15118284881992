import { Modal } from "rsuite";
import "../../keyword-suggestions/keyword-suggestions.scss";
import { Block, InternalInput } from "../../../ui-components";
import RWireButton from "../../rwire-ui/rwire-button/rwire-button";
import { useState } from "react";

const checkValidPublicationNumber = (publicationNumber) => {
  const regex = /^([A-Za-z]{2,4})(\d{1,20})([A-Za-z]\d{0,1})$/;
  const match = publicationNumber.match(regex);
  if (!match) {
    return false;
  }
  return true;
};

const ReleventResultAddModal = (props) => {
  const {
    isModalOpen,
    onCloseModal,
    onRwireSearchAPI,
    setUserInput,
    userInput,
  } = props;

  const [relevantResult, setRelevantResult] = useState({
    patentNumber: "",
    errorMessage: "",
  });
  const handleApply = async () => {
    if (checkValidPublicationNumber(relevantResult.patentNumber)) {
      setRelevantResult({
        ...relevantResult,
        errorMessage: "",
      });
      await onRwireSearchAPI(relevantResult.patentNumber).then(
        ({ resultId, alreadyExists }) => {
          if (resultId && !alreadyExists) {
            setUserInput({
              ...userInput,
              isModalOpen: true,
              relevantResultId: resultId,
            });
            onCloseModal();
          } else if (alreadyExists) {
            setRelevantResult({
              ...relevantResult,
              errorMessage:
                "This publication no is already exist, please enter a different no",
            });
          } else {
            setRelevantResult({
              ...relevantResult,
              errorMessage:
                "No result found: please enter valid publication number",
            });
          }
        }
      );
    } else {
      setRelevantResult({
        ...relevantResult,
        errorMessage: "Please enter valid publication number",
      });
    }
  };

  return (
    <Modal
      className="modalClassFilter relevant-results-section add-patent-no-modal report-integration"
      static="static"
      keyboard={false}
      open={isModalOpen}
      onClose={onCloseModal}
    >
      <Block className="modal-title">
        <p className="modal-title-heading">Relevant Results</p>
        <p className="modal-title-heading" onClick={onCloseModal}>
          X
        </p>
      </Block>
      <Block className="classes-suggestor-modal__wrapper">
        <InternalInput
          type="text"
          inputName="patentNumber"
          placeholder="Enter Patent Number"
          InputValue={relevantResult.patentNumber}
          onSetValue={(_, value) =>
            setRelevantResult({
              ...relevantResult,
              patentNumber: value,
              errorMessage: value ? relevantResult.errorMessage : "",
            })
          }
          InputClassName="patent-number-input"
          containerClass={`input-container w-100`}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleApply();
            }
          }}
        />
        {relevantResult.errorMessage && (
          <div className="pt-2" style={{ color: "red" }}>
            {relevantResult.errorMessage}
          </div>
        )}
        <div className="model-footer-buttons">
          <RWireButton
            cNameDiv="add-btn_wrapper ps-2"
            buttonCName={`add-btn`}
            name="Add"
            onClick={handleApply}
            disabled={!relevantResult.patentNumber}
          />
        </div>
      </Block>
    </Modal>
  );
};

export default ReleventResultAddModal;
