export const checkList = [
  {
    id: "publication_no",
    value: "publication_no",
    subList: [],
  },
  {
    id: "patent_information",
    value: "patent_information",
    subList: [
      "publication_date",
      "application_date",
      "Earliest_Priority_Date",
      "Current Assignee",
      "Inventor",
      // "Jurisdiction", DO NOT REMOVE
      "Publication Country",
      "Priority Country",
      "normalized_assignee",
      "standardized_assignee",
      "assignee_original",
      "application_number",
      "priority_number",
      "Kind Code",
      // "Expiry Date",
      "patent_type",
      "Assignee/Applicant_Original",
      "Assignee/Applicant_Standardized",
      "Assignee/Applicant_Normalized",
    ],
  },
  {
    id: "title",
    value: "title",
    subList: [],
  },
  {
    id: "abstract",
    value: "abstract",
    subList: [],
  },
  {
    id: "Claims",
    value: "Claims",
    subList: [],
  },
  {
    id: "Images",
    value: "Images",
    subList: [],
  },
  {
    id: "ipc",
    value: "ipc",
    subList: [],
  },
  {
    id: "cpc",
    value: "cpc",
    subList: [],
  },
  {
    id: "US Class",
    value: "US Class",
    subList: [],
  },
  {
    id: "ECL",
    value: "ECL",
    subList: [],
  },
  {
    id: "JP-FI/F-Terms",
    value: "JP-FI/F-Terms",
    subList: [],
  },
  {
    id: "Legal Status",
    value: "Legal Status",
    subList: [],
  },
  {
    id: "Relevancy Score",
    value: "Relevancy Score",
    subList: [],
  },
  {
    id: "Main Family",
    value: "Main Family",
    subList: [],
  },
  {
    id: "Simple Family",
    value: "Simple Family",
    subList: [],
  },
  {
    id: "Domestic Family",
    value: "Domestic Family",
    subList: [],
  },
  {
    id: "Complete Family",
    value: "Complete Family",
    subList: [],
  },
  {
    id: "Extended Family",
    value: "Extended Family",
    subList: [],
  },
];
