import React from "react";
import RwireClickableField from "../../../container/rwire-clickable-field";

function RwireAssignee({
  assignee,
  assigneeToggle,
  setAssigneeToggle,
  onPerformSearch,
  isViewPage,
}) {
  const assigneeList = assigneeToggle ? assignee : [assignee[0]];
  return (
    <div className="d-flex justify-content-between">
      <div>
        {assigneeList.map((assignee, index) => (
          <RwireClickableField
            name={assignee}
            isLast={assigneeList.length === index + 1}
            onPerformSearch={onPerformSearch}
            shortCode="CA"
            joiningCharacter=" , "
            isViewPage={isViewPage}
          />
        ))}
      </div>
      {!assigneeToggle && assignee.length > 1 ? (
        <div className="ms-1 inventor" onClick={() => setAssigneeToggle(true)}>
          +{assignee.length - 1}
        </div>
      ) : null}
    </div>
  );
}

export default RwireAssignee;
