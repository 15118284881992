/* eslint-disable no-console */
import { fetch } from "../../service/fetch";
import { setResultTable } from "../rwire-search-api";
import { getProjectDashboard } from "./report-dashboard";

export const setReportInventor = (payload) => ({
  type: "SET_INVENTOR",
  payload: {
    ...payload,
  },
});

export const getReportProjectInventors = () => async (dispatch) => {
  try {
    var response = {};
    const id = sessionStorage.getItem("projectId");
    response = await fetch(
      {
        url: `/api/project-inventor/project/${id}`,
      },
      "GET"
    );
    dispatch(
      setReportInventor({
        reportTableData: response.result,
        undoStack: [],
        redoStack: [],
      })
    );
  } catch (error) {
    console.log("error>>>", error);
  }
};

export const updateReportProjectInventorsRow =
  ({ id, body }) =>
  async (dispatch) => {
    console.log("2nd");
    try {
      await fetch(
        {
          url: `/api/project-inventor/${id}`,
          body,
        },
        "PUT"
      );
      dispatch(getProjectDashboard());
    } catch (error) {
      console.log("error>>>", error);
    }
  };

export const updateReportProjectInventorsSortOrder =
  (body) => async (dispatch) => {
    try {
      await fetch(
        {
          url: `/api/project-inventor/sort-order`,
          body,
        },
        "POST"
      );
    } catch (error) {
      console.log("error>>>", error);
    }
  };

export const addRowToInventorReportProject = (body) => async (dispatch) => {
  try {
    await fetch(
      {
        url: `/api/project-inventor`,
        body,
      },
      "POST"
    );
    dispatch(getProjectDashboard());
  } catch (error) {
    console.log("error>>>", error);
  } finally {
    dispatch(setResultTable({ isLoadingResult: false }));
  }
};

export const deleteRowFromInventorReportProject = (id) => async (dispatch) => {
  try {
    await fetch(
      {
        url: `/api/project-inventor/${id}`,
      },
      "DELETE"
    );
    dispatch(getProjectDashboard());
  } catch (error) {
    console.log("error>>>", error);
  } finally {
    dispatch(setResultTable({ isLoadingResult: false }));
  }
};

export const addInventor =
  ({ projectId, body, patentNumber }) =>
  async (dispatch) => {
    try {
      await fetch(
        {
          url: `/api/project-inventor`,
          body: {
            data: body,
            projectId,
            patentNumber,
          },
        },
        "POST"
      );
      dispatch(getProjectDashboard());
    } catch (error) {}
  };

export const removeInventor =
  ({ projectId, body, patentNumber }) =>
  async (dispatch) => {
    try {
      await fetch(
        {
          url: `/api/project-inventor/delete-inventor`,
          body: {
            data: body,
            projectId,
            patentNumber,
          },
        },
        "POST"
      );
      dispatch(getProjectDashboard());
    } catch (error) {}
  };

export const getProjectPatentDetails =
  ({ projectId, patentNumber }) =>
  async (dispatch) => {
    const { result } = await fetch(
      {
        url: `/api/project-patent-details/project/${projectId}/${patentNumber}`,
        body: {
          projectId,
          patentNumber,
        },
      },
      "GET"
    );

    dispatch(setReportInventor({ patentDetails: result }));
  };
