import React, { useEffect, useState } from "react";
import loader from "../../assets/images/loader.gif";

const RwirePhrasesTable = ({ meaningfulPhrases, extractedNumbers }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (meaningfulPhrases && Object.keys(meaningfulPhrases).length > 0) {
      setLoading(false);
    }
  }, [meaningfulPhrases]);

  if (loading) {
    return (
      <div className="loader-container d-flex justify-content-center align-items-center">
        <img alt="Loader" width={30} height={30} src={loader} />
      </div>
    );
  }

  const filteredNumbers = Array.isArray(extractedNumbers)
    ? extractedNumbers.filter(
        (number) =>
          meaningfulPhrases[number] &&
          meaningfulPhrases[number].length > 0 &&
          meaningfulPhrases[number].some((phrase) => phrase.length > 1)
      )
    : [];

  // Remove one-character phrases
  const refinedPhrases = filteredNumbers.reduce((acc, number) => {
    const phrases = meaningfulPhrases[number].filter(
      (phrase) => phrase.length > 1
    );
    if (phrases.length > 0) {
      acc[number] = phrases;
    }
    return acc;
  }, {});

  const hasData = filteredNumbers.length > 0;

  return (
    <div className="recognized-text-section">
      <table>
        <thead>
          <tr>
            <th>Number</th>
            <th>Phrases</th>
          </tr>
        </thead>
        <tbody>
          {hasData ? (
            filteredNumbers.map((number) => (
              <tr key={number}>
                <td className="text-center">{number}</td>
                <td>{refinedPhrases[number].join(", ")}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td>NA</td>
              <td>NA</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default RwirePhrasesTable;
