import React, { useEffect, useState } from "react";
// import { useSharedContext } from "../../el-search";
import Select from "react-select";
// import { perPageOptions } from "../result-table/content";
import RCPagination from "rc-pagination";
import "./style.scss";
import { useTranslation } from "react-i18next";
import RWireButton from "../rwire-button/rwire-button";
import reRunIcon from "../../../images/refresh.svg";
import exportIcon from "../../../images/export.svg";
import combineIcon from "../../../images/combine-icon.svg";
const perPageOptions = [
  { value: "20", label: "20" },
  { value: "50", label: "50" },
  { value: "100", label: "100" },
  { value: "200", label: "200" },
  { value: "500", label: "500" },
];

const Pagination = (props) => {
  const { t } = useTranslation();
  const {
    total,
    data,
    totalRecordsCount,
    itemsPerPage,
    page,
    onSetItemPerPage,
    limit,
    onGetPagination,
    totalPage,
    onSetPage,
    isResultFetch = true,
    selectedRows,
    onFetchHistory,
    onUpdateQueryCount,
    onExportHistory,
    setSelectedField,
    onSetSelectedCombineQuery,
    onSetSelectedCombineQueryId,
    selectedItemList,
    ...rest
  } = props;

  useEffect(() => {
    onGetPagination(total, itemsPerPage, page);
  });
  const [pageValue, setPageValue] = useState("");
  const [clname, setClassName] = useState("pages_input_box");
  const [validationMessage, setValidationMessage] = useState("");
  const [updateValidationMessage, setUpdateValidationMessage] = useState("");
  const handleUpdateCount = async () => {
    if (selectedRows.length < 1) {
      setUpdateValidationMessage("Please select string for update count");
      setTimeout(() => {
        setUpdateValidationMessage("");
      }, 3000);
      return;
    }
    await selectedRows.map(async (item, index) => {
      const filterItem = data.filter((i) => `${i.id}` === `${item}`);

      await onUpdateQueryCount(
        item,
        filterItem[0].query,
        filterItem[0].is_combined,
        filterItem[0],
        filterItem[0].includeFields
      ).then(async () => {
        if (selectedRows.length - 1 === index) {
          await onFetchHistory();
        }
      });
    });
  };

  const handleExport = () => {
    onExportHistory();
  };
  const handleCombineClick = () => {
    if (selectedRows.length <= 1) {
      setValidationMessage("Please select two or more strings for combine");
      setTimeout(() => {
        setValidationMessage("");
      }, 3000);
    } else if (
      selectedItemList.some(
        (obj) => obj.includeFields && obj.includeFields.length > 0
      )
    ) {
      alert(
        "Error: Invalid Combination\nSpecial strings that include families or citations cannot be combined."
      );
    } else {
      onSetSelectedCombineQuery("");
      onSetSelectedCombineQueryId(null);
      setValidationMessage("");
      setSelectedField("Combine");
    }
  };

  const handleChangeOptions = (item) => {
    onSetItemPerPage({ itemsPerPage: parseInt(item.value) });
    if (isResultFetch) {
      // dispatch({ type: "fetchQueryData", dispatch });
    }
    onSetPage(1);
  };

  const enterPageAlert = () => {
    // eslint-disable-next-line no-console
    console.log("Please enter page number");
  };
  const pageNotFoundAlert = () => {
    // eslint-disable-next-line no-console
    console.log("Page number not found");
  };
  const handleChange = (event) => {
    setPageValue(event.target.value);
    if (event.target.value.length >= 0) {
      setClassName("pages_input_box");
    }
  };

  const handleClick = () => {
    if (pageValue !== "") {
      if (parseInt(pageValue) <= 0) {
        setClassName("red_border");
        setTimeout(pageNotFoundAlert, 100);
      } else if (parseInt(pageValue) <= parseInt(totalPage)) {
        onSetPage(pageValue);
        setPageValue("");
      } else {
        setClassName("red_border");
        setTimeout(pageNotFoundAlert, 100);
      }
    } else if (pageValue === "") {
      setClassName("red_border");
      setTimeout(enterPageAlert, 100);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && event.target.value !== "") {
      if (parseInt(pageValue) <= 0) {
        setClassName("red_border");
        setTimeout(pageNotFoundAlert, 100);
      } else if (parseInt(pageValue) <= parseInt(totalPage)) {
        onSetPage(pageValue);
        setPageValue("");
      } else {
        setClassName("red_border");
        setTimeout(pageNotFoundAlert, 100);
      }
    } else if (event.key === "Enter") {
      setClassName("red_border");
      setTimeout(enterPageAlert, 100);
    }
  };

  const offset = parseInt(page) === 1 ? 0 : (parseInt(page) - 1) * itemsPerPage;
  const record = offset + itemsPerPage > total ? total : offset + itemsPerPage;

  return (data && data > 0) || (totalRecordsCount && totalRecordsCount > 0) ? (
    <div
      className={`pagination p-4 d-flex align-items-center justify-content-between`}
    >
      <div
        className={`per_page_block d-flex align-items-center justify-content-between`}
      >
        <RWireButton
          cNameDiv="search-query"
          buttonCName="input-button-text-form big-width ml-1"
          name={t("history-update-count")}
          buttonImg={reRunIcon}
          onClick={handleUpdateCount}
        />
        <div className="ml-1">Results per page</div>
        <Select
          defaultValue={itemsPerPage}
          onChange={handleChangeOptions}
          options={perPageOptions}
          className="ml-1 page-number-dropdown"
          placeholder={itemsPerPage}
          menuPlacement="top"
          isSearchable={false}
        />
        {/* <div className="ps-3 ms-2 me-2">
          {t("pagination-showing")} {offset + 1} {t("pagination-to")} {record}{" "}
          {t("pagination-of")} {total}
        </div> */}
        <div
          className={`jump_to_page d-flex align-items-center justify-content-between`}
        >
          {totalPage > 1 ? (
            <RCPagination
              {...rest}
              current={parseInt(page)}
              onChange={onSetPage}
              pageSize={itemsPerPage}
              total={total}
              showTitle={true}
              locale={{
                items_per_page: "/ page",
                jump_to: "Go to",
                jump_to_confirm: "confirm",
                page: "Page",
                prev_page: "Previous Page",
                next_page: "Next Page",
                prev_5: "Previous 5 Pages",
                next_5: "Next 5 Pages",
                prev_3: "Previous 3 Pages",
                next_3: "Next 3 Pages",
                page_size: "Page Size",
              }}
            />
          ) : (
            <div className="pagination__navigate">
              <div className="pagination__page">
                <span>
                  <div
                    className={`
                page-number me-2
                d-flex align-items-center justify-content-center
                selected-page pg_num_border
              `}
                  >
                    {totalPage}
                  </div>
                </span>
              </div>
            </div>
          )}
          <div className="jump-pages pe-3">{t("pagination-jump-pages")}</div>
          <div className="pe-2 d-flex flex-row">
            <input
              type="text"
              value={pageValue}
              placeholder="Pages"
              onChange={handleChange}
              // className= {`pages_input_box ${clname}`}
              className={clname}
              onKeyPress={handleKeyPress}
            />
            <button
              type="button"
              className={`go-btn d-flex align-items-center justify-content-between ml-2`}
              onClick={handleClick}
            >
              {t("pagination-go")}
            </button>
          </div>
        </div>
      </div>
      <div className="d-flex flex-row align-items-center">
        <div>
          {updateValidationMessage && (
            <span className="updatevalidation-msg">
              {updateValidationMessage}
            </span>
          )}
          {validationMessage && (
            <span className="validation-msg fs-14">{validationMessage}</span>
          )}
        </div>
        <RWireButton
          cNameDiv="search-query"
          buttonCName="input-button-text-form ml-1 button-white"
          name={t("history-update-export")}
          buttonImg={exportIcon}
          onClick={handleExport}
        />
        <RWireButton
          cNameDiv="search-query"
          buttonCName="input-button-text-form ml-1"
          name={t("history-update-combine")}
          buttonImg={combineIcon}
          onClick={handleCombineClick}
        />
      </div>
    </div>
  ) : null;
};
export default Pagination;
