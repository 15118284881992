/* eslint-disable array-callback-return */
import peggy from "../resources/parser/parser";
import { generateQuery } from "../common/generate-rec-query";
import { setQuery } from "./search-query";
import {
  checkQueryDataWithFields,
  checkSpecialConditions,
  cleanQuery,
} from "../common/query-functions";
import { addHistory, reRunHistory } from "../action/history";
import { getSelectedFilters } from "./aggregationFunction";
import { setSelectedField } from "./export";
import { filtersJson } from "../components/common/patents-result-common/rwire-filter-modal";
import { setApp } from "./app";
import {
  smart_search,
  filters_options,
  highlight_regex,
} from "../components/constant";
import { displayError } from "../service/display-error";
import { search, singleSearch } from "../service/elasticSearch";
import {
  extractWords,
  formatNumberFields,
} from "../common/pre-process-input/utils";
import { replaceSpace } from "../common/query-replace-space";
import { findMatchingWords, setHighlight } from "./highlight";
import { mapErrorMessage } from "../common/error-messages";
import { addString } from "./report/report-string";
import { multipleFieldsShortCode } from "../resources/data/combine-field-list";
import { cleanTags, cleanUpString } from "../common/helper-functions";
import {
  POST_REGEX_HIGHLIGHT,
  PRE_REGEX_HIGHLIGHT,
} from "../common/highlight-constants";

export const setResultTable = (payload) => ({
  type: "RESULT_SET",
  payload: {
    ...payload,
  },
});

export const resetHighlightWords = (payload) => ({
  type: "RESULT_SET",
  payload: {
    ...payload,
  },
});

export const resetDetails = (payload = []) => ({
  type: "RESET_RESULT_SET",
  payload,
});
export const resetState = (payload) => ({
  type: "RESET_STATE_EXCLUDING_PAYLOAD",
  payload,
});

export const rwireSearchAPI =
  (queryToSearch = "", options = {}) =>
  async (dispatch, getState) => {
    const {
      isNumberWithIncludeSearch = false,
      isResetHighlight = false,
      isBothCitations = false,
    } = options;
    let { isReRunId } = options;
    const {
      app: { searchQuery, selectedIncludes = [] },
      resultTable: { isLoadingResult },
    } = getState();

    if (!isLoadingResult) {
      if (queryToSearch !== "") {
        dispatch(setSelectedField({ selectedFields: ["PN_B"] }));
        dispatch(
          resetDetails([
            "filterList",
            "patentReamainingList",
            "patentInformationList",
            "dataSize",
            "includeFieldsOnResult",
          ])
        );

        if (!isNumberWithIncludeSearch) {
          dispatch(setApp({ selectedIncludes: [] }));
        }
        dispatch(resetHighlightWords({ highlightword: [] }));
        localStorage.removeItem("highlightWords");
        localStorage.removeItem("highlightedWords");
      }

      dispatch(setResultTable({ isLoadingResult: true }));
      const {
        resultTable: {
          dataSize,
          dataFrom,
          sortBy,
          sortType,
          includeFieldsOnResult,
        },

        app: { collapsableFields = "PN_B" },
      } = getState();
      const finalSearchQuery = queryToSearch ? queryToSearch : searchQuery;
      const isValidQuery = await dispatch(validationQuery(finalSearchQuery));
      if (!isValidQuery) {
        dispatch(setResultTable({ isLoadingResult: false }));
        throw new Error("Invalid Query");
      }
      dispatch(
        setApp({
          searchQuery: cleanQuery(finalSearchQuery),
        })
      );
      await dispatch(setQuery({ syntaxqueryCorrect: "" }));
      let filters = getSelectedFilters(getState().resultTable);

      const queryObj = {
        queryToSearch: finalSearchQuery,
        isNumberWithIncludeSearch:
          isBothCitations ||
          (selectedIncludes && selectedIncludes.length > 0) ||
          finalSearchQuery.includes("BOTH=("),
        selectedIncludes:
          isBothCitations || finalSearchQuery.includes("BOTH=(")
            ? ["BCP", "FCP"]
            : selectedIncludes || [],
        dataSize,
        dataFrom,
        sortBy,
        sortType,
        includeFieldsOnResult,
        collapsableField: collapsableFields,
        filters,
        highlightWords: [],
        require_field_match: false,
      };
      const storedQueryDetails = {
        queryToSearch: queryObj.queryToSearch,
        isNumberWithIncludeSearch: queryObj.isNumberWithIncludeSearch,
        selectedIncludes: queryObj.selectedIncludes,
        filters: queryObj.filters,
      };
      localStorage.setItem(
        "storedQueryDetails",
        JSON.stringify(storedQueryDetails)
      );
      const body = JSON.stringify(queryObj);
      try {
        const data = await search(body, smart_search);
        let dataResponse = data.data;

        //s3 serve file related code

        const s3data = [];
        dataResponse?.hits?.hits?.forEach((item) => {
          s3data.push(singleSearch({ id: item._id }));
        });

        const dataResponseRaw = await Promise.all(s3data);

        const structuredData = dataResponse?.hits?.hits.map((item, k) => ({
          ...item,
          _source: dataResponseRaw[k].data,
        }));

        dataResponse.hits = {
          ...dataResponse.hits,
          hits: [...structuredData],
        };

        dataResponse.hits.total.value = data.headers.get("X-Total-Count")
          ? data.headers.get("X-Total-Count")
          : dataResponse.hits.total.value;

        const cleanQueryOrignal = replaceSpace(finalSearchQuery);

        const regexQueryObj = {
          queryToSearch: finalSearchQuery,
        };

        let quickView_highlights_data = await search(
          regexQueryObj,
          highlight_regex
        );

        let quickViewData = [];

        let queryRegex = quickView_highlights_data?.data?.regex;

        let highlightedQueryWordsArray =
          quickView_highlights_data?.data?.queryWords
            .filter(
              (i) =>
                i.operator !== "NOT" && !["PN", "AN", "PRN"].includes(i.field)
            )
            .map((i) => i.word);

        localStorage.setItem("queryRegex", queryRegex);

        dispatch(
          setResultTable({
            queryRegex: queryRegex,
            quickViewHighlights: quickViewData,
          })
        );
        // END s3 --------------

        dispatch(
          setResultDetails({
            dataResponse,
            isReRunId,
            highlightword: highlightedQueryWordsArray,
            isResetHighlight,
            cleanQueryOrignal,
            collapsableField: collapsableFields,
          })
        );
      } catch (error) {
        displayError(error);
        dispatch(setResultTable({ isLoadingResult: false }));
        dispatch(setResultTable({ error: "Something went wrong" }));
        dispatch(
          setQuery({
            syntaxqueryError: "Please check the input",
          })
        );
        throw error;
      }
    }
    dispatch(setResultTable({ isLoadingResult: false }));
  };

const quickViewDataGenerate = (
  patentDetails,
  extractWordsArray,
  queryRegex,
  quickViewData,
  resultIds
) => {
  let generateData = "";
  extractWordsArray.map((item) => {
    if (multipleFieldsShortCode[item]) {
      multipleFieldsShortCode[item].map((i) => {
        let sourceData = Array.isArray(patentDetails["_source"][i])
          ? cleanTags(patentDetails["_source"][i].join(" "))
          : cleanTags(patentDetails["_source"][i]);

        if (sourceData) {
          generateData = {
            ...generateData,
            [i]: [
              ...sourceData.matchAll(
                new RegExp(
                  PRE_REGEX_HIGHLIGHT + queryRegex + POST_REGEX_HIGHLIGHT,
                  "gim"
                )
              ),
            ]
              .map((k) => cleanUpString(k[0]))
              .filter((i) => i)
              .slice(0, 5),
          };
        }
      });
    } else {
      let sourceData = Array.isArray(patentDetails["_source"][item])
        ? cleanTags(patentDetails["_source"][item].join(" "))
        : cleanTags(patentDetails["_source"][item]);
      if (sourceData) {
        generateData = {
          ...generateData,
          [item]: [
            ...sourceData.matchAll(
              new RegExp(
                PRE_REGEX_HIGHLIGHT + queryRegex + POST_REGEX_HIGHLIGHT,
                "gim"
              )
            ),
          ]
            .map((k) => cleanUpString(k[0]))
            .filter((i) => i)
            .slice(0, 5),
        };
      }
    }
  });
  quickViewData.map((item, key) => {
    if (item._id === resultIds) {
      quickViewData[key].highlight = generateData;
    }
  });

  return quickViewData;
};

export const getQuickViewHighlights =
  (
    queryToSearch = "",
    options = {},
    shouldRunRemainingSearch = false,
    resultIds = []
  ) =>
  async (dispatch, getState) => {
    if (!shouldRunRemainingSearch) return;
    const {
      app: { searchQuery },
      resultTable: { displayData, quickViewHighlights, queryRegex },
    } = getState();
    const resultIdsAll = displayData.map((i) => i._id);

    const finalSearchQuery = queryToSearch ? queryToSearch : searchQuery;

    const extractWordsArray = extractWords(finalSearchQuery);

    try {
      let quickViewData = quickViewHighlights;

      if (quickViewHighlights.length <= 0) {
        resultIdsAll.map((item) => {
          quickViewData.push({
            highlight: "",
            _id: item,
          });
        });
      }
      let quickViewDataGenerated = [];
      resultIds.map(async (i) => {
        let singleRecord = quickViewData.filter((item) => item._id === i);
        if (singleRecord[0]?.highlight === "") {
          const dataResponse = displayData.filter((it) => it._id === i);
          if (dataResponse) {
            let patentDetails = { _source: dataResponse[0]._source };
            quickViewDataGenerated = quickViewDataGenerate(
              patentDetails,
              extractWordsArray,
              queryRegex,
              quickViewData,
              i
            );
          }
        }
      });
      dispatch(
        setResultTable({
          quickViewHighlights: [...quickViewDataGenerated],
        })
      );
    } catch (error) {
      console.log("error>>", error);
    }
  };

export const rwireAggregationCountsAPI =
  (queryToSearch = "", options = {}, shouldRunRemainingSearch = false) =>
  async (dispatch, getState) => {
    const { isBothCitations = false } = options;
    let {
      isReRunId,
      runAggregate = true,
      isCombinedSearch = false,
      operators = [],
      combinedIds = [],
    } = options;
    const {
      app: { searchQuery, collapsableFields = "PN_B", selectedIncludes },
      resultTable: { totalRecordsCount },
    } = getState();
    if (!runAggregate) return;
    const finalSearchQuery = queryToSearch ? queryToSearch : searchQuery;
    if (shouldRunRemainingSearch) {
      dispatch(
        setResultTable({
          isAggregationCountsLoading: true,
        })
      );

      let filters = getSelectedFilters(getState().resultTable);

      const aggBody = JSON.stringify({
        queryToSearch: finalSearchQuery,
        includeFieldsOnResult: ["PN_B"],
        filters,
        dataSize: 0,
        isNumberWithIncludeSearch:
          isBothCitations ||
          (selectedIncludes && selectedIncludes.length > 0) ||
          finalSearchQuery.includes("BOTH=("),
        selectedIncludes:
          isBothCitations || finalSearchQuery.includes("BOTH=(")
            ? ["BCP", "FCP"]
            : selectedIncludes || [],
      });
      try {
        let aggrigated_data = await search(aggBody, "aggregate");
        aggrigated_data = aggrigated_data.data;
        let total = totalRecordsCount;
        if (collapsableFields === "PN_B") {
          aggrigated_data.aggregations = aggrigated_data.aggregations
            ? {
                ...aggrigated_data.aggregations,
                PN_B: { value: totalRecordsCount },
              }
            : {};
        } else {
          total = aggrigated_data.aggregations[collapsableFields]
            ? aggrigated_data.aggregations[collapsableFields].value
            : 0;
        }
        dispatch(
          setResultTable({
            aggregations: aggrigated_data?.aggregations,
            totalRecordsCount: total,
          })
        );
        isReRunId = isReRunId ? isReRunId : "";
        if (!isReRunId && typeof isReRunId !== "boolean") {
          await addHistory({
            query: finalSearchQuery,
            numberOfHits: aggrigated_data.aggregations.PN_B.value,
            ...(isCombinedSearch && {
              combineId: combinedIds,
              operators: operators,
            }),
            includeFields: isBothCitations
              ? ["BCP", "FCP"]
              : selectedIncludes && selectedIncludes.length > 0
              ? selectedIncludes
              : null,
          });
          const projectId = sessionStorage.getItem("projectId");
          const projectMode = sessionStorage.getItem("projectMode");
          if (projectId && projectMode !== "false") {
            await dispatch(
              addString({
                body: finalSearchQuery,
                publicationCount: aggrigated_data.aggregations.PN_B.value,
                applicationCount: aggrigated_data.aggregations.AN_B.value,
                order: 1,
                projectId,
              })
            );
          }
        } else if (typeof isReRunId !== "boolean") {
          await reRunHistory(
            finalSearchQuery,
            aggrigated_data.aggregations.PN_B.value,
            isReRunId
          );
        }
      } catch (error) {
      } finally {
        dispatch(
          setResultTable({
            isAggregationCountsLoading: false,
          })
        );
      }
    } else {
      isReRunId = isReRunId ? isReRunId : "";
      if (!isReRunId && typeof isReRunId !== "boolean") {
        await addHistory({
          query: finalSearchQuery,
          numberOfHits: 0,
          ...(isCombinedSearch && {
            combineId: combinedIds,
            operators: operators,
          }),
          includeFields:
            selectedIncludes && selectedIncludes.length > 0
              ? selectedIncludes
              : null,
        });
        const projectId = sessionStorage.getItem("projectId");
        const projectMode = sessionStorage.getItem("projectMode");
        if (projectId && projectMode !== "false") {
          await dispatch(
            addString({
              body: finalSearchQuery,
              publicationCount: 0,
              applicationCount: 0,
              order: 1,
              projectId,
            })
          );
        }
      } else if (typeof isReRunId !== "boolean") {
        await reRunHistory(finalSearchQuery, 0, isReRunId);
      }
    }
  };
export const performSearch =
  (query = "", options = {}, navigate, routeStr = "") =>
  async (dispatch, getState) => {
    const {
      app: { isQuickViewOpen = false },
    } = getState();
    try {
      if (query === null) {
        await dispatch(rwireSearchAPI());
      } else {
        await dispatch(rwireSearchAPI(query, options));
      }
      const {
        resultTable: { displayData },
      } = getState();
      if (navigate && routeStr) {
        navigate(routeStr);
      }
      const shouldRunRemainingSearch = displayData.length > 0;
      const resultIds = displayData.map((i) => i._id);
      if (isQuickViewOpen) {
        dispatch(
          getQuickViewHighlights(
            query,
            options,
            shouldRunRemainingSearch,
            resultIds
          )
        );
        dispatch(
          rwireAggregationCountsAPI(query, options, shouldRunRemainingSearch)
        );
      } else {
        dispatch(
          rwireAggregationCountsAPI(query, options, shouldRunRemainingSearch)
        );
        dispatch(
          getQuickViewHighlights(
            query,
            options,
            shouldRunRemainingSearch,
            resultIds
          )
        );
      }
      return true;
    } catch (error) {
      displayError(error);
      throw error;
    }
  };

export const validationQuery =
  (rawQuery, isOnlyCheckingValidation = false) =>
  async (dispatch, getState) => {
    try {
      const {
        searchQuery: { tabWiseSearchQuery },
        app: { activeTab, isEditModalOpen },
      } = getState();
      rawQuery = replaceSpace(rawQuery);
      const cleanQueryOrignal = cleanQuery(rawQuery);

      checkSpecialConditions(rawQuery);
      checkQueryDataWithFields(rawQuery);
      rawQuery = formatNumberFields(rawQuery);

      const parser = peggy.parse(cleanQuery(rawQuery));
      generateQuery(parser, true);

      dispatch(setQuery({ syntaxqueryCorrect: "Syntax correct " }));

      if (!isEditModalOpen && !isOnlyCheckingValidation) {
        dispatch(
          setApp({
            searchedVisibleQuery: cleanQueryOrignal,
          })
        );

        dispatch(
          setQuery({
            syntaxqueryError: "",
            tabWiseSearchQuery: {
              ...tabWiseSearchQuery,
              [activeTab]: cleanQueryOrignal,
            },
          })
        );
      }
      return true;
    } catch (e) {
      let errorMessage = e.message ? e.message : "Syntax Wrong";
      errorMessage = mapErrorMessage(errorMessage);
      dispatch(setQuery({ syntaxqueryCorrect: "" }));
      dispatch(setQuery({ syntaxqueryError: errorMessage }));

      return false;
    }
  };

const setResultDetails =
  ({
    dataResponse = [],
    highlightword,
    isResetHighlight = false,
    collapsableField,
  }) =>
  async (dispatch, getState) => {
    const {
      highlight: {
        highlightedWords: storedHighlightWord = [],
        highlightText: storedHighlightText = "",
      },
    } = getState();
    let updatedHighlightedWords = storedHighlightWord;
    let updatedHighlightText = storedHighlightText;
    let total = dataResponse.hits.total.value;

    const result = dataResponse.hits.hits;

    if (isResetHighlight) {
      updatedHighlightedWords = await findMatchingWords(result, highlightword);
      updatedHighlightedWords = updatedHighlightedWords.sort(
        (a, b) => b.word.length - a.word.length
      );
      updatedHighlightText = "";
    }

    dispatch(
      setResultTable({
        // totalRecordsCount: total,
        displayData: result,
        isLoadingResult: false,
        esHighlightwords: highlightword,
        highlightword,
        totalHighlightedWords: highlightword,
      })
    );
    if (collapsableField === "PN_B") {
      dispatch(
        setResultTable({
          totalRecordsCount: parseInt(total),
        })
      );
    }
    localStorage.setItem(
      "highlightedWords",
      JSON.stringify(updatedHighlightedWords)
    );

    dispatch(
      setHighlight({
        highlightedWords: updatedHighlightedWords,
        highlightText: updatedHighlightText,
      })
    );
  };

export const mapKeysToProps = (filterList) => {
  return filterList.map((key) => filtersJson[key]);
};

export const getFiltersOptions =
  ({ isIndivisual = false, isDisplayListFilters = false }) =>
  async (dispatch, getState) => {
    const {
      resultTable: {
        filtersSearchText,
        openedFilterName = "",
        filterOptionsData,
        filterList,
        sizeLimit = {},
      },
      app: { searchQuery, collapsableFields = "PN_B" },
    } = getState();
    const isValidQuery = await dispatch(validationQuery(searchQuery));
    if (!isValidQuery) {
      dispatch(
        setResultTable({
          isLoadingFilterData: false,
        })
      );
      return;
    }

    await dispatch(setQuery({ syntaxqueryCorrect: "" }));
    if (openedFilterName !== "" || isDisplayListFilters) {
      if (
        !filterOptionsData.hasOwnProperty(filtersJson[openedFilterName]) ||
        isIndivisual ||
        isDisplayListFilters
      ) {
        dispatch(
          setResultTable({
            isLoadingFilterData: filterOptionsData.hasOwnProperty(
              filtersJson[openedFilterName]
            )
              ? false
              : true,
          })
        );
        const fields = isDisplayListFilters
          ? mapKeysToProps(filterList)
          : [filtersJson[openedFilterName]];
        const queryFilters = getSelectedFilters(getState().resultTable);
        let queryObj = JSON.parse(localStorage.getItem("storedQueryDetails"));
        queryObj = {
          queryToSearch: searchQuery,
          isNumberWithIncludeSearch: queryObj.isNumberWithIncludeSearch,
          selectedIncludes: queryObj.selectedIncludes,
          fields,
          filtersSearchText,
          collapsableField: collapsableFields,
          filters: queryFilters,
          sizeLimit: sizeLimit,
        };
        const body = JSON.stringify(queryObj);

        try {
          const dataAggregationResponse = await search(body, filters_options);
          const filterOptions = dataAggregationResponse.data.aggregations;
          if (isIndivisual) {
            dispatch(
              setResultTable({
                filterOptionsData: {
                  ...filterOptionsData,
                  [[filtersJson[openedFilterName]]]:
                    filterOptions[filtersJson[openedFilterName]],
                },
                isLoadingFilterData: false,
              })
            );
          } else {
            dispatch(
              setResultTable({
                filterOptionsData: { ...filterOptionsData, ...filterOptions },
                isLoadingFilterData: false,
              })
            );
          }
        } catch (error) {
          displayError(error);
          dispatch(
            setResultTable({
              isLoadingFilterData: false,
            })
          );
          return false;
        }
      }
    }
  };

export const inputValidationQuery =
  (rawQuery) => async (dispatch, getState) => {
    try {
      const {
        searchQuery: { tabWiseSearchQuery },
        app: { activeTab, isEditModalOpen },
      } = getState();
      const cleanQueryOrignal = cleanQuery(rawQuery);

      checkSpecialConditions(rawQuery);
      checkQueryDataWithFields(rawQuery);
      rawQuery = formatNumberFields(rawQuery);
      const parser = peggy.parse(cleanQuery(rawQuery));
      generateQuery(parser, true);

      if (!isEditModalOpen) {
        dispatch(
          setApp({
            searchQuery: cleanQueryOrignal,
            searchedVisibleQuery: cleanQueryOrignal,
          })
        );

        dispatch(
          setQuery({
            syntaxqueryError: "",
            tabWiseSearchQuery: {
              ...tabWiseSearchQuery,
              [activeTab]: cleanQueryOrignal,
            },
          })
        );
      }
      return true;
    } catch (e) {
      return false;
    }
  };

export const quickViewOnPN = (id) => async (dispatch, getState) => {
  const {
    app: { searchQuery },
  } = getState();
  return await dispatch(getQuickViewHighlights(searchQuery, {}, true, [id]));
};
