import { displayError } from "../../service/display-error";
import { classes_suggestor, filters_options } from "../../components/constant";
import { search } from "../../service/elasticSearch";
export const setClassesModal = (payload) => ({
  type: "CLASSES_SUGGESTOR_SET",
  payload: {
    ...payload,
  },
});

export const setPagination = (payload) => ({
  type: "CLASSES_SUGGESTOR_SET",
  payload: {
    ...payload,
  },
});

export const setSelectedRows = (selectedRows) => (dispatch) => {
  dispatch(setClassesModal(selectedRows));
};

export const getPagination =
  (total, limit, page = 1) =>
  (dispatch) => {
    const totalPage = Math.ceil(total / limit);
    dispatch(setPagination({ totalPage, limit }));
  };

export const setPage = (page) => (dispatch) => {
  dispatch(setClassesModal({ page }));
};

export const searchForClassAndKeyword = () => async (dispatch, getState) => {
  const {
    classesSuggestor: { formData = {}, classesType, dataFrom },
  } = getState();
  let queryObj = {};
  let keys = [];
  try {
    if (formData.keywords) {
      let ctbSearchObj = {
        queryToSearch: `TAC=(${formData.keywords})`,
        isNumberWithIncludeSearch: false,
        selectedIncludes: [],
        fields: [`${classesType.toUpperCase()}D`],
        filtersSearchText: {
          [`${classesType.toUpperCase()}D`]: formData.classes
            ? formData.classes
            : "",
        },
        collapsableField: "PN_B",
        filters: [],
        sizeLimit: { [`${classesType.toUpperCase()}D`]: 1000 },
      };
      const ctbSearchBody = JSON.stringify(ctbSearchObj);
      const ctbSearchData = await search(ctbSearchBody, filters_options);
      keys = ctbSearchData?.data?.aggregations?.[
        `${classesType.toUpperCase()}D`
      ]?.buckets?.map((item) => item.key);
    }

    queryObj = {
      classesAndkeywords: {
        ...formData,
        classes: formData.keywords
          ? keys.join(" or ").toUpperCase()
          : formData.classes,
      },
      classesType,
      dataFrom,
      dataSize: 1000,
    };
    const body = JSON.stringify(queryObj);
    const data = await search(body, classes_suggestor);
    const dataResponse = data.data;
    const tableData = dataResponse.hits.hits;
    const total = dataResponse.hits.total.value;

    dispatch(
      setClassesModal({
        tableData,
        totalRecordsCount: total,
        isNoResultFound: tableData.length > 0 ? false : true,
      })
    );
  } catch (error) {
    displayError(error);
  }
};
