import React, { useEffect, useState } from "react";
import RCPagination from "rc-pagination";
import { useTranslation } from "react-i18next";

const ClassesPagination = (props) => {
  const { t } = useTranslation();

  const {
    total,
    data,
    totalRecordsCount,
    itemsPerPage,
    page,
    onSetItemPerPage,
    limit,
    onGetPagination,
    totalPage,
    onSetPage,
    isResultFetch = true,
    ...rest
  } = props;

  useEffect(() => {
    onGetPagination(total, itemsPerPage, page);
  });

  const [pageValue, setPageValue] = useState("");
  const [clname, setClassName] = useState("pages_input_box");

  const enterPageAlert = () => {
    // eslint-disable-next-line no-console
    console.log("Please enter page number");
  };
  const pageNotFoundAlert = () => {
    // eslint-disable-next-line no-console
    console.log("Page number not found");
  };
  const handleChange = (event) => {
    setPageValue(event.target.value);
    if (event.target.value.length >= 0) {
      setClassName("pages_input_box");
    }
  };

  const handleClick = () => {
    if (pageValue !== "") {
      if (parseInt(pageValue) <= 0) {
        setClassName("red_border");
        setTimeout(pageNotFoundAlert, 100);
      } else if (parseInt(pageValue) <= parseInt(totalPage)) {
        onSetPage(pageValue);
        setPageValue("");
      } else {
        setClassName("red_border");
        setTimeout(pageNotFoundAlert, 100);
      }
    } else if (pageValue === "") {
      setClassName("red_border");
      setTimeout(enterPageAlert, 100);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && event.target.value !== "") {
      if (parseInt(pageValue) <= 0) {
        setClassName("red_border");
        setTimeout(pageNotFoundAlert, 100);
      } else if (parseInt(pageValue) <= parseInt(totalPage)) {
        onSetPage(pageValue);
        setPageValue("");
      } else {
        setClassName("red_border");
        setTimeout(pageNotFoundAlert, 100);
      }
    } else if (event.key === "Enter") {
      setClassName("red_border");
      setTimeout(enterPageAlert, 100);
    }
  };

  return (data && data > 0) || (totalRecordsCount && totalRecordsCount > 0) ? (
    <div
      className={`pagination p-3  m-0 d-flex classes_pagination `}
    >
      {totalPage > 1 ? (
        <RCPagination
          {...rest}
          current={parseInt(page)}
          onChange={onSetPage}
          pageSize={itemsPerPage}
          total={total}
          showTitle={true}
          locale={{
            items_per_page: "/ page",
            jump_to: "Go to",
            jump_to_confirm: "confirm",
            page: "Page",
            prev_page: "Previous Page",
            next_page: "Next Page",
            prev_5: "Previous 5 Pages",
            next_5: "Next 5 Pages",
            prev_3: "Previous 3 Pages",
            next_3: "Next 3 Pages",
            page_size: "Page Size",
          }}
        />
      ) : (
        <div className="pagination__navigate">
          <div className="pagination__page">
            <span>
              <div
                className={`
                page-number me-2
                d-flex align-items-center justify-content-center
                selected-page pg_num_border
              `}
              >
                {totalPage}
              </div>
            </span>
          </div>
        </div>
      )}
      <div
        className={`ml-1 jump_to_page_classes d-flex align-items-center`}
      >
        <div className="jump-pages pe-1">{t("pagination-jump-pages")}</div>
        <div className="pe-2">
          <input
            type="text"
            value={pageValue}
            placeholder="Pages"
            onChange={handleChange}
            className="pages_input_box"
            onKeyPress={handleKeyPress}
          />
        </div>
        <div>
          <button
            type="button"
            className={`go-btn d-flex align-items-center pr-2`}
            onClick={handleClick}
          >
            {t("pagination-go")}
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

export default ClassesPagination;
