/* eslint-disable no-console */
import { Modal } from "rsuite";
import React, { useState, useEffect } from "react";
import CloseIcon from "@rsuite/icons/Close";
import { Message, toaster } from "rsuite";

const RwireLoadFromTemplate = (props) => {
  const {
    loadSaveModal,
    setSaveLoadModal,
    onGetExportTemplates,
    onDeleteExportTemplate,
    onSetSelectedField,
    onLoadTemplate,
  } = props;

  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    if (loadSaveModal) {
      loadExportTemplates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadSaveModal]);

  const loadExportTemplates = async () => {
    try {
      const templatesData = await onGetExportTemplates(1);
      const filteredTemplates = templatesData.filter(
        (template) => !template.default
      );
      setTemplates(filteredTemplates);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const handleTemplateSelect = (template) => {
    setSelectedTemplate(template);
  };

  const handleLoadTemplate = async () => {
    if (selectedTemplate) {
      try {
        const fieldsArray = selectedTemplate.fields.split(",");
        // Remove duplicates from the fields array
        const uniqueFieldsArray = [...new Set(fieldsArray)];
        onSetSelectedField({ selectedFields: uniqueFieldsArray });
        setSaveLoadModal(false);
        onLoadTemplate(uniqueFieldsArray);
      } catch (error) {
        console.error("Error loading the template:", error);
      }
    }
  };

  const handleDeleteTemplate = async () => {
    if (selectedTemplate) {
      if (selectedTemplate.default === true) {
        setTemplates((prevTemplates) =>
          prevTemplates.filter(
            (template) => template.id !== selectedTemplate.id
          )
        );
        setSelectedTemplate(null);
      } else {
        try {
          await onDeleteExportTemplate(selectedTemplate.id);
          loadExportTemplates();
          setSelectedTemplate(null);
          toaster.push(
            <Message type="success">Template deleted successfully</Message>
          );
        } catch (error) {
          console.error("Error deleting the template:", error);
        }
      }
    }
  };

  return (
    <>
      {loadSaveModal && (
        <Modal
          className="loadFromFilterModal load-from-filter-margin"
          backdrop="true"
          keyboard={false}
          open={true}
          onClose={() => {
            setSaveLoadModal(!loadSaveModal);
          }}>
          <div>
            <div className="d-flex justify-content-between download-center-header light-blue-background">
              <div className="download-center-text">Select export template</div>
              <div
                className="cursor-pointer"
                title="Close"
                onClick={() => setSaveLoadModal(!loadSaveModal)}>
                <CloseIcon />
              </div>{" "}
            </div>
            <div className="folder-tree-load-template">
              <div className="template-label p-3">Export Templates</div>
              <div className="template-folder">
                {templates.length === 0 ? (
                  <div className="no-templates-message">
                    Please Create Template
                  </div>
                ) : (
                  templates.map((template) => (
                    <div key={template.id} className="template-item pl-4">
                      <label className="flex items-center">
                        <input
                          type="radio"
                          name="template"
                          checked={
                            selectedTemplate &&
                            selectedTemplate.id === template.id
                          }
                          onChange={() => handleTemplateSelect(template)}
                        />
                        <span className="pl-2">{template.name}</span>
                      </label>
                    </div>
                  ))
                )}
              </div>
            </div>
            <div className="footer-section-laod">
              <div className="d-flex justify-content-end footer-load">
                <div className="d-flex justify-content-between">
                  <button
                    className="email-send-download-cancel"
                    onClick={() => {
                      setSaveLoadModal(!loadSaveModal);
                    }}>
                    Cancel
                  </button>
                  <button
                    className="email-send-download-save"
                    onClick={handleLoadTemplate}
                    disabled={!selectedTemplate}>
                    OK
                  </button>
                  {templates.length > 0 && (
                    <button
                      className="email-send-download-save"
                      onClick={handleDeleteTemplate}
                      disabled={!selectedTemplate}>
                      DELETE
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default RwireLoadFromTemplate;
