import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Modal } from "../../ui-components";
import ExistingWordsDropdown from "./existing-words-dropdown";
import { updateReportKeywordsData } from "./utils";

export default function SynonymsSuggestorModal(props) {
  const {
    isOpen,
    onClose,
    reportTableData,
    onUpdateReportProjectKeywordsRow,
    onSetKeyword,
    onGetReportProjectKeywords,
    onAddRowToreportProject,
    onSearchSynonyms,
    tableData,
  } = props;
  const [formData, setFormData] = useState({
    keyword: "",
    selectedExistingKeyword: "",
    isValid: null,
    data: null,
    isLoading: false,
    isChecking: false,
    count: 5,
    keywords: [],
  });
  const [errors, setErrors] = useState({});
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const validateForForm = () => {
    const newErrors = {};
    if (formData.keyword === "") {
      newErrors.text = "Keyword is required.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSearchForSynonyms = async (e) => {
    e.preventDefault();
    if (!validateForForm()) return;
    setFormData((prev) => ({
      ...prev,
      isLoading: true,
    }));
    setSelectedKeywords([]);
    try {
      if (formData.keyword) {
        await onSearchSynonyms(formData.keyword, formData.count.code);
        setFormData((prev) => ({
          ...prev,
          keywords: formData.keyword.split(","),
        }));
      }
    } catch (error) {
      onSetKeyword({ tableData: [] });
    } finally {
      setFormData((prev) => ({
        ...prev,
        isLoading: false,
      }));
    }
  };

  const handleCheckbox = (e) => {
    const value = e.target.value;
    setSelectedKeywords((prev) =>
      prev.includes(value) ? prev.filter((k) => k !== value) : [...prev, value]
    );
  };

  const handleFieldChange = (value, field) => {
    console.log(value, field);
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };
  const handleApply = async (e) => {
    e.preventDefault();
    let newReportTableData = [...reportTableData];

    const selectedKeywordId = formData.selectedExistingKeyword.code;
    if (selectedKeywordId) {
      const index = newReportTableData.findIndex(
        (item) => item.id === selectedKeywordId
      );
      if (index !== -1) {
        newReportTableData[index].body = `${newReportTableData[index].body} ${
          newReportTableData[index].body ? " | " : ""
        } ${selectedKeywords.join(" | ")}`;
        await onUpdateReportProjectKeywordsRow({
          id: selectedKeywordId,
          body: {
            body: newReportTableData[index].body,
          },
        });

        await onGetReportProjectKeywords();
      }
    } else {
      const updatedTableData = updateReportKeywordsData(
        [...reportTableData],
        [...selectedKeywords]
      );

      // Update the existing items in the backend
      await Promise.all(
        updatedTableData
          .filter((row) => row.id)
          .map(async (row) => {
            await onUpdateReportProjectKeywordsRow({
              id: row.id,
              body: {
                title: row.title,
              },
            });
          })
      );

      // Add new rows for newly created items
      const newKeywords = updatedTableData.filter((row) => !row.id);
      if (newKeywords.length > 0) {
        const projectId = parseInt(sessionStorage.getItem("projectId"));
        await onAddRowToreportProject({
          data: newKeywords,
          projectId: projectId,
        });
      }
      setSelectedKeywords([]);

      onGetReportProjectKeywords();
    }
    onSetKeyword({ tableData: [] });
    onClose();
  };

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    setIsSelectAllChecked(isChecked);

    if (isChecked) {
      const allKeywords = tableData.flatMap((item) =>
        Object.values(item).flat()
      );
      setSelectedKeywords(allKeywords);
    } else {
      setSelectedKeywords([]);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      isStatic={false}
      overlayClass="project-creation-modal"
      className="synonyms-suggestor-modal-body"
    >
      <div className="modal-content border-0 fs-16">
        <div className="modal-header bg-white border-0 px-4">
          <h5 className="modal-title fs-16 primary-heading-text">
            Please enter keyword to get synonyms:
          </h5>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={() => {
              onSetKeyword({ tableData: [] });
              onClose();
            }}
          ></button>
        </div>
        <div className="modal-body py-0 px-4">
          <form className="px-3 pt-3 pb-1 form-bg border">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div className="col-6 p-0">
                <input
                  type="text"
                  className="form-control fs-16 rounded"
                  id="keyword"
                  name="keyword"
                  placeholder="Enter Keyword"
                  value={formData.keyword}
                  onChange={(e) => {
                    handleChange(e);
                    setFormData((prev) => ({
                      ...prev,
                      isValid: null,
                    }));
                    setErrors({});
                  }}
                />
              </div>
              <span className="fw-bold text">Add In:</span>
              <ExistingWordsDropdown
                onChange={(value) =>
                  handleFieldChange(value, "selectedExistingKeyword")
                }
                data={reportTableData
                  .filter((i) => i.title)
                  .map((i) => {
                    return {
                      title: i.title
                        .replace(/<[^>]*>/g, "")
                        .replaceAll("&nbsp;", " "),
                      code: i.id,
                    };
                  })}
                placeholder="Select"
                disabled={formData.keyword === ""}
              />
              <ExistingWordsDropdown
                onChange={(value) => handleFieldChange(value, "count")}
                data={[5, 10, 15, 20, 25].map((i) => {
                  return {
                    title: i,
                    code: i,
                  };
                })}
                defaultValue={5}
                placeholder="Select"
                menuClassName="keyword-count-menu"
                optionClassName=""
                disabled={formData.keyword === ""}
              />

              <button
                className="create-project-btn btn-with-blue-border-white-bg fs-16 fw-bold"
                onClick={handleSearchForSynonyms}
              >
                Search
              </button>
            </div>

            {errors && (
              <span className="position-absolute bottom-0 text-danger">
                {errors.text}
              </span>
            )}
          </form>
        </div>
        <div className="modal-body py-0 mt-1 px-4">
          <div className="keywords-table">
            <div className="header w-100 d-flex py-1 align-items-center justify-content-center">
              <span className="text">Synonyms</span>
            </div>
            <div className="synonyms-table-body w-100">
              {formData.isLoading ? (
                <div className=" row h-100 col-12" style={{ width: "60vw" }}>
                  {" "}
                  <div className="fw-bold d-flex justify-content-center align-items-center">
                    Loading ...
                  </div>
                </div>
              ) : tableData.length === 0 ? (
                <div className=" row h-100 col-12" style={{ width: "60vw" }}>
                  {" "}
                  <div className="text-secondary fw-bold d-flex justify-content-center align-items-center">
                    Please type in keywords for search
                  </div>
                </div>
              ) : (
                <div className="px-0 pt-1">
                  {formData.keywords &&
                    formData.keywords.map((keyword, index) => (
                      <div className="w-100">
                        <div className="w-100 bg-keyword fs-16 py-1 px-2">
                          <span className="fw-bold">Synonym: {keyword}</span>
                        </div>
                        <div className="d-flex flex-wrap w-100 ">
                          {Array.from(new Set(tableData[index][keyword])).map(
                            (synonym, idx) => {
                              const formattedSynonym = synonym
                                .replace(/^[\d.]+/g, "")
                                .replace(/^[^a-zA-Z]+/, "")
                                .split(" ")
                                .map(
                                  (word) =>
                                    word.charAt(0).toUpperCase() +
                                    word.slice(1).toLowerCase()
                                )
                                .join(" ");
                              return (
                                <div
                                  className={`col-4 d-flex align-items-center gap-2 py-2 
                              ${idx % 3 === 2 ? "border-bottom 2" : ""} 
                               ${
                                 idx % 3 === 1
                                   ? "border-bottom border-right 1"
                                   : ""
                               }${
                                    idx % 3 === 0
                                      ? "border-bottom border-right 0"
                                      : ""
                                  }`}
                                >
                                  <input
                                    className={` cursor-pointer`}
                                    type="checkbox"
                                    value={`${formattedSynonym}`}
                                    checked={selectedKeywords.includes(formattedSynonym)}
                                    onChange={handleCheckbox}
                                  />
                                  <span>{formattedSynonym}</span>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="modal-footer border-0">
          <div className="me-auto d-flex gap-2 px-2 py-2">
            <input
              className={` cursor-pointer`}
              type="checkbox"
              checked={isSelectAllChecked}
              onChange={handleSelectAll}
              disabled={tableData.length === 0}
            />
            Select All
          </div>

          <button
            type="button"
            className="btn cancel-btn fs-16"
            onClick={onClose}
          >
            Cancel
          </button>
          <Button
            type="button"
            className="create-project-btn btn-with-blue-border-white-bg fs-16"
            onClick={handleApply}
          >
            Add
          </Button>
        </div>
      </div>
    </Modal>
  );
}
