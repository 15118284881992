import React, { useState } from "react";
import "./dashboard.scss";
import Header from "./header";
import MainContent from "./mainComponent";
import CitationSection from "./citationSection";
import RwireReportRelevantResults from "../../container/report/rwire-report-relevant-results";
import NewKeyFeatures from "../../container/report/new-key-features";
import RwireLoader from "../../container/rwire-loader";
import RwireReportString from "../../container/report/rwire-report-string";
import { InsightsIcon, ReportBtnIcon } from "../../assets/images/report";
import { useNavigate } from "react-router-dom";
import FirstSectionMain from "../../container/report/first-section-main";
import RwireReportGraphs from "../../container/report/rwire-report-graphs";


const Dashboard = (props) => {
  const {
    keyFeature,
    relevantResult,
    string,
    keywords,
    classes,
    inventor,
    assignee,
  } = props;

  const [section, setSection] = useState("first");
  const navigate = useNavigate();

  const getCount = (field) => field?.count || 0;
  const fields = [
    keyFeature,
    relevantResult,
    string,
    keywords,
    classes,
    inventor,
    assignee,
  ];
  const isReportDisabled = fields.every((field) => getCount(field) === 0);

  // Render different components based on the section value
  const renderSection = () => {
    switch (section) {
      case "first":
        return (
          <>
            <FirstSectionMain {...props} />
          </>
        );
      case "second":
        return (
          <>
            <RwireReportString {...props} />
            <CitationSection {...props} />
          </>
        );
      case "third":
        return (
          <>
            <MainContent {...props} />
          </>
        );

      case "fourth":
        return (
          <>
            <RwireReportGraphs isOpenSidebar={true} />
          </>
        );

      default:
        return <MainContent {...props} />; // Fallback to the default section
    }
  };

  return (
    <div className="report-new-dashboard">
      <RwireLoader />
      <Header section={section} setSection={setSection} {...props} />
      {renderSection()}
      <div className="position-fixed w-100 bottom-0 bg-white py-2 px-4 d-flex gap-3 report-new-footer">
        <button
          class={`insights-btn ms-auto ${section === "fourth" ? "active" : ""}`}
          onClick={() => setSection("fourth")}
        >
          <img
            src={InsightsIcon}
            alt=""
            className={section === "fourth" ? "active-img" : ""}
          />
          Insights
        </button>

        <button
          class={`report-btn ${isReportDisabled ? "opacity-50" : ""}`}
          disabled={isReportDisabled}
          onClick={() => {
            navigate("/en/report/final-view");
          }}
        >
          <img src={ReportBtnIcon} alt="" />
          Report
        </button>
      </div>
    </div>
  );
};

export default Dashboard;
