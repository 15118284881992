import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  addRowToKeywordsReportProject,
  getReportProjectKeywords,
  setReportKeyword,
  setSelectedRows,
} from "../../action/report/report-keyword";
import { updateReportProjectKeywordsRow } from "../../action/report/report-keyword";
import {
  checkIfPatentAvailable,
  findKeywordsFromText,
  getSynonymsOfWords,
  setKeyword,
} from "../../action/keyword-suggestor";
import SynonymsSuggestorModal from "../../components/report-new/synonyms-suggestor-modal";

const Container = (props) => {
  return <SynonymsSuggestorModal {...props} />;
};

const mapStateToProps = ({ keywordSuggestor, keyword, reportKeyword }) => ({
  ...reportKeyword,
  ...keywordSuggestor,
  ...keyword,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onSetKeywordModal: setReportKeyword,
      onSearchSynonyms: getSynonymsOfWords,
      onSetSelectedRows: setSelectedRows,
      onSetKeywordSelector: setReportKeyword,
      onSetKeyword: setKeyword,
      onupdateReportProjectKeywordsRow: updateReportProjectKeywordsRow,
      onFindKeywordsFromText: findKeywordsFromText,
      onCheckIfPatentAvailable: checkIfPatentAvailable,
      onUpdateReportProjectKeywordsRow: updateReportProjectKeywordsRow,
      onGetReportProjectKeywords: getReportProjectKeywords,
      onAddRowToreportProject: addRowToKeywordsReportProject,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Container);
