const TableRow = ({ item, selectedRows = [], onSetKeywordSelector }) => {
  let selectedId = [];
  const handleCheckbox = (e) => {
    selectedId = [...selectedRows, item];
    if (!e.target.checked) {
      selectedId = selectedId.filter((i) => {
        return `${i}` !== `${item}`;
      });
    }
    onSetKeywordSelector(selectedId);
  };

  const formattedItem = Array.from(
    new Set(item.replace(/^\s*\d+\.?\s*/, "").split(/\s+/))
  ).join(" ");
  
  return (
    <div className="table-row">
      <div className="table-column regular-checkbox form-check regular-checkbox">
        <input
          className={` cursor-pointer`}
          type="checkbox"
          defaultChecked={selectedRows.includes(item)}
          value={`${item}`}
          checked={selectedRows.includes(item)}
          onChange={handleCheckbox}
        />
      </div>
      <div className="table-column definition text-start">{formattedItem}</div>
    </div>
  );
};

export default TableRow;
