import React, { useEffect, useState } from "react";
import { FaSearch, FaUser } from "react-icons/fa";
import ProjectDropdown from "../../container/report/project-dropdown";
import { useNavigate } from "react-router-dom";
import { AiOutlineUserAdd } from "react-icons/ai";
import "./header-history.scss";
const smartSearchField = "SS";
const Header = (props) => {
  const {
    onSetApp,
    smartSearchWord,
    onPerformSearch,
    onSetAllField,
    tabWiseSearchQuery,
  } = props;
  const [displayValue, setDisplayValue] = useState(smartSearchWord);
  const navigate = useNavigate();
  useEffect(() => {
    if (smartSearchWord) {
      setDisplayValue(smartSearchWord);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smartSearchWord]);
  const handleSearch = async () => {
    localStorage.removeItem("tableScrollPosition");
    if (displayValue) {
      const querySearch = `${smartSearchField}=(${displayValue})`;

      onSetApp({
        smartSearchWord: displayValue,
        searchQuery: querySearch,
        isSearchFromIncremental: false,
      });
      onSetAllField({
        tabWiseSearchQuery: { ...tabWiseSearchQuery, fielded: querySearch },
      });
      await onPerformSearch(
        querySearch,
        { isResetHighlight: true },
        navigate,
        "/en/rwire-patents"
      ).then((data) => {
        if (!data) {
          // eslint-disable-next-line no-console
          console.log("Error:", props.error);
        }
      });
    }
  };

  const handleClick = async () => {
    await handleSearch();
  };

  const handleEnter = async (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      if (!displayValue) {
        return false;
      }

      await handleSearch();
    }

    return;
  };
  return (
    <>
      <>
        <nav className="navbar navbar-light">
          <div className="container-fluid">
            <div className="home-page-breadcrumb fs-14">
              <ProjectDropdown />
            </div>
            <form className="d-flex flex-grow-1 mx-4">
              <div className="input-group">
                <input
                  className="form-control smart-search-history mt-0 px-2 fs-16 py-1 text-dark"
                  type="search"
                  placeholder="Smart Search"
                  aria-label="Search"
                  value={displayValue}
                  onChange={(e) => setDisplayValue(e.target.value)}
                  onKeyDown={handleEnter}
                />
                <button
                  className="btn btn-outline-secondary shadow-none z-0 px-2 py-1"
                  onClick={handleClick}
                >
                  <FaSearch />
                </button>
              </div>
            </form>
          </div>
        </nav>
      </>
    </>
  );
};

export default Header;
