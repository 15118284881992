import React, { useState, useEffect } from "react";
import TableHeader from "./table-header";
import HistoryRow from "./history-row";
import { uuidv4 } from "../../action/app";
import "./styles.scss";
import Pagination from "../rwire-ui/rwire-pagination-new";
import ActionButtons from "./action-buttons";
import { Table } from "react-bootstrap";
import LoaderGif from "../../assets/images/loader.gif";
const History = (props) => {
  const {
    data,
    totalRecordsCount,
    itemsPerPage,
    page,
    onGetPagination,
    onSetItemPerPage,
    onSetPage,
    onDeleteHistory,
    onFetchHistory,
    onBookmarkHistory,
    selectedRows,
    onSetSelectedRows,
    onSetExportSelected,
    onSetApp,
    selectedItemList,
    onPerformSearch,
    selectedRowsForCombine,
    selectedField,
    historySortDirection,
    loadingHistory = false,
  } = props;
  const [editQuery, setEditQuery] = useState("");
  const [sortedData, setSortedData] = useState(data);

  useEffect(() => {
    setSortedData(data);
  }, [data]);

  const handleChange = (p) => {
    onSetPage(p);
    onFetchHistory();
  };

  return (
    <div className="px-3 py-2">
      <div
        className={`table-wrapper normal-scroll-bar px-3  py-3 strings-section border`}
      >
        {loadingHistory ? (
          <div className="d-flex justify-content-center align-items-center h-100">
            <img src={LoaderGif} alt="" width={50} />
          </div>
        ) : totalRecordsCount > 0 ? (
          <div className="strings-table ">
            <div
              className={`${
                selectedField === "Combine"
                  ? "table-responsive-new"
                  : "table-responsive-new"
              }`}
            >
              <Table bordered={false} striped>
                <TableHeader
                  selectedRows={selectedRows}
                  onSetSelectedRows={onSetSelectedRows}
                  data={sortedData}
                  onSetExportSelected={onSetExportSelected}
                  selectedItemList={selectedItemList}
                  onSetItemPerPage={onSetItemPerPage}
                  selectedRowsForCombine={selectedRowsForCombine}
                  historySortDirection={historySortDirection}
                  onFetchHistory={onFetchHistory}
                />
                <tbody>
                  {sortedData.map((item) => (
                    <HistoryRow
                      key={item.id}
                      isBookmark={item.isBookmark}
                      id={item.id}
                      numberOfHits={item.numberOfHits}
                      userSearchNumber={item.userSearchNumber}
                      query={item.query}
                      createdAt={item.createdAt}
                      updatedAt={item.updatedAt}
                      onDeleteHistory={onDeleteHistory}
                      onFetchHistory={onFetchHistory}
                      selectedRows={selectedRows}
                      onBookmarkHistory={onBookmarkHistory}
                      onSetSelectedRows={onSetSelectedRows}
                      onSetExportSelected={onSetExportSelected}
                      selectedItemList={selectedItemList}
                      onSetApp={onSetApp}
                      onPerformSearch={onPerformSearch}
                      editQuery={editQuery}
                      setEditQuery={setEditQuery}
                      historyTableRow={item}
                      // setEditQueryId={setEditQueryId}
                      {...props}
                    />
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center h-100">
            <h6 className="text-secondary">No records available !</h6>
          </div>
        )}
      </div>
      <div className="pagination-action-buttons">
        <div className="pagination-block">
          <div className="container">
            <Pagination
              {...props}
              onSetItemPerPage={onSetItemPerPage}
              onGetPagination={onGetPagination}
              total={totalRecordsCount ? totalRecordsCount : 0}
              itemsPerPage={itemsPerPage}
              page={page}
              onSetPage={handleChange}
              isResultFetch={false}
            />
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default History;
