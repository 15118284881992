import React, { useEffect, useState } from "react";
import "./styles.scss";
import {
  ReportDownload,
  ReportSendOnMail,
} from "../../../assets/images/report";
import DataTable from "./data-table";
import {
  filterByType,
  filterNonBlank,
} from "../../../ui-components/functions/report-view/utils";
import {
  assigneesMapping,
  cpcMapping,
  inventorsMapping,
  ipcMapping,
  keyFeaturesMapping,
  keywordsMapping,
  stringsMapping,
  usMapping,
  relevantResultsMapping,
  citationMapping,
} from "../../../ui-components/functions/report-view/mappings";
import { useNavigate } from "react-router-dom";
import DownloadModal from "./download-modal";
import SendMailModal from "./send-mail-modal";
import { getTodaysDate } from "../../../ui-components/functions/report-view/utils";
import moment from "moment";
import { RwireReportGraphs } from "../../../container/report";
import RwireLoader from "../../../container/rwire-loader";

export const formatDate = (dateString) => {
  const year = parseInt(dateString.substring(0, 4), 10);
  const month = parseInt(dateString.substring(4, 6), 10) - 1; // Month is 0-indexed
  const day = parseInt(dateString.substring(6, 8), 10);

  const date = new Date(year, month, day);
  const options = { day: "2-digit", month: "short", year: "numeric" };

  return date.toLocaleDateString("en-GB", options).replace(/ /g, "-");
};

const FinalReportView = ({
  onGetReportProjectDataById,
  onSentReportOnMail,
  citedReferences,
  onSetResultTable
}) => {
  const [projectData, setProjectData] = useState({});
  const [isEdited, setIsEdited] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showMailModal, setShowMailModal] = useState(false);
  const navigate = useNavigate();

  function getKeyFeaturePatents(keyFeatures, patents) {
    const keyFeatureMap = {};
    keyFeatures = keyFeatures.filter(
      (keyFeature) => keyFeature.body.trim() !== ""
    );

    // Populate keyFeatureMap with key features as keys and empty arrays as values
    keyFeatures.forEach((keyFeature, index) => {
      keyFeatureMap[`Key Feature ${index + 1}: ${keyFeature.body}`] = {
        relevance: `Key Feature-${index + 1}`,
        Defination: ` ${keyFeature.body}`,
        patents: [],
      };
    });

    // Iterate through patents and populate keyFeatureMap with associated patent numbers
    patents.forEach((patent) => {
      patent.projectRelevantResultKeyFeatureData.forEach((result) => {
        const keyFeature = keyFeatures.find(
          (feature) => feature.id === result.keyFeature
        );
        if (keyFeature) {
          const keyFeatureText = `Key Feature ${
            keyFeatures.findIndex((kf) => kf.id === keyFeature.id) + 1
          }: ${keyFeature.body}`;
          keyFeatureMap[keyFeatureText].patents.push(patent.patentNumber);
        }
      });
    });

    Object.keys(keyFeatureMap).forEach((key) => {
      keyFeatureMap[key].patents = keyFeatureMap[key].patents.join(" | ");
    });

    const result = Object.values(keyFeatureMap);

    return result;
  }

  function processPatentData(patentData, keyFeaturesData) {
    keyFeaturesData = keyFeaturesData.filter(
      (keyFeature) => keyFeature.body.trim() !== ""
    );

    const keyFeaturesMap = new Map();
    keyFeaturesData.forEach((kf, index) => {
      keyFeaturesMap.set(kf.id, index + 1);
    });

    const processedData = patentData.map((patent) => {
      const availableKeyFeatures = [];
      const notAvailableKeyFeatures = [];

      patent.projectRelevantResultKeyFeatureData.forEach((keyFeatureData) => {
        if (keyFeaturesMap.has(keyFeatureData.keyFeature)) {
          availableKeyFeatures.push(
            keyFeaturesMap.get(keyFeatureData.keyFeature)
          );
        }
      });

      if (availableKeyFeatures.length === 0) {
        availableKeyFeatures.push("");
      }
      if (keyFeaturesData.length === 0) {
        notAvailableKeyFeatures.push("");
      }

      for (let i = 1; i <= keyFeaturesData.length; i++) {
        if (!availableKeyFeatures.includes(i)) {
          notAvailableKeyFeatures.push(i);
        }
      }
      return {
        publicationNumber: patent.patentNumber,
        availableKeyFeatures: availableKeyFeatures,
        notAvailableKeyFeatures: notAvailableKeyFeatures,
      };
    });

    return processedData;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const id = sessionStorage.getItem("projectId");
        if (!id) {
          navigate("/en/existing-project");
        }
        onSetResultTable({isLoadingResult: true});
        let data = await onGetReportProjectDataById(id);
        const keyFeaturePatents = getKeyFeaturePatents(
          data.keyFeatures,
          data.relevantResults
        );

        const patentKeyFeatures = processPatentData(
          data.relevantResults,
          data.keyFeatures
        );
        data = {
          ...data,
          usClasses: filterByType(data.classes, "US"),
          cpcClasses: filterByType(data.classes, "CPC"),
          ipcClasses: filterByType(data.classes, "IPC"),
          keyFeaturePatents: keyFeaturePatents,
          patentKeyFeatures: patentKeyFeatures,
        };
        setProjectData(data);
      } catch (error) {}
      finally {
        onSetResultTable({isLoadingResult: false});
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEdited) return;

    const handleBeforeUnload = (event) => {
      const message = "Are you sure you want to leave?";
      event.returnValue = message;
      return message;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isEdited]);

  const enhancedKeyFeatures = Array.isArray(projectData?.keyFeatures)
    ? projectData.keyFeatures.map((feature, index) => ({
        index: index + 1,
        ...feature,
      }))
    : [];

  const processedRelevantResults = (projectData.relevantResults || []).map(
    (result, index) => {
      const matchingPatentData = projectData.patentKeyFeatures[index];

      const publicationInfo = `
      <strong>Publication Date:</strong> ${
        result.publicationDate
          ? moment(result.publicationDate).format("DD/MM/YYYY")
          : "N/A"
      },
      <strong>Priority Date:</strong>  ${
        result.priorityDate
          ? moment(result.priorityDate).format("DD/MM/YYYY")
          : "N/A"
      }
    `;
      const comments = "";

      const checklist = `
      <span style="color:green;">
     ${
       matchingPatentData?.availableKeyFeatures.length > 0
         ? matchingPatentData.availableKeyFeatures.join(" ")
         : "None"
     }
     </span>,
     <span style="color:red;">
     ${
       matchingPatentData?.notAvailableKeyFeatures.length > 0
         ? matchingPatentData.notAvailableKeyFeatures.join(" ")
         : "None"
     }
       </span> `;

      return {
        patentNumber: `<span class="report-table-title d-block text-center">${
          result.patentNumber || "No patent number"
        }</span>`,
        publicationInfo: publicationInfo,
        title: result.title || "No title",
        checklist: checklist,
        comments: comments,
      };
    }
  );

  const processedCitationResults = citedReferences.map((item, index) => ({
    srNo: index + 1,
    PN: `<span class="report-table-title d-block text-center">${item.PN}</span>`,
    PD: formatDate(item.PD),
    AD: formatDate(item.AD),
    EPRD: formatDate(item.EPRD),
    CA: item.CA?.join(" | ") || "",
    IN: `<span class="blue-text">${item.IN?.join(" | ") || ""}</span>`,
  }));

  return (
    <>
    <RwireLoader />
    <div className="project-final-view report-new-dashboard">
      <div className="heading-strip"></div>
      <div className="list-section" id="project-report-section">
        <div className="Report-view-main">
          <p className="Report_heading pt-3 pl-4">
            {" "}
            Project Name : {projectData.name}
          </p>
          <p className="Report_date pb-2 pr-4">
            {" "}
            Report Date: {getTodaysDate()}{" "}
          </p>
        </div>
        <div className="data-tables px-4 pt-3">
          <DataTable
            data={projectData && filterNonBlank(enhancedKeyFeatures)}
            mapping={keyFeaturesMapping}
            columnWidths={[10, 90]}
            field="keyFeatures"
          />
          <DataTable
            data={projectData && filterNonBlank(projectData.keywords)}
            mapping={keywordsMapping}
            columnWidths={[20, 80]}
            field="keywords"
          />
          <DataTable
            data={projectData && filterNonBlank(projectData.cpcClasses)}
            mapping={cpcMapping}
            columnWidths={[20, 80]}
            field="cpcClasses"
          />
          <DataTable
            data={projectData && filterNonBlank(projectData.ipcClasses)}
            mapping={ipcMapping}
            columnWidths={[20, 80]}
            field="ipcClasses"
          />
          <DataTable
            data={projectData && filterNonBlank(projectData.usClasses)}
            mapping={usMapping}
            columnWidths={[20, 80]}
            field="usClasses"
          />
          <DataTable
            data={projectData && filterNonBlank(projectData.assignees)}
            mapping={assigneesMapping}
            columnWidths={[100]}
            field="assignees"
          />
          <DataTable
            data={projectData && filterNonBlank(projectData.inventors)}
            mapping={inventorsMapping}
            columnWidths={[100]}
            field="inventors"
          />
          <DataTable
            data={projectData && filterNonBlank(projectData.strings)}
            mapping={stringsMapping}
            columnWidths={[80, 20]}
            field="strings"
          />
          {/* <DataTable
            data={projectData && projectData.keyFeaturePatents}
            mapping={keyFeaturePatentsMapping}
            columnWidths={[20, 40, 40]}
            field="keyFeaturePatents"
          />

          <DataTable
            data={projectData && projectData.patentKeyFeatures}
            mapping={patentKeyFeatureMapping}
            columnWidths={[30, 30, 40]}
            field="patentKeyFeatures"
            hideEditingAndDeletingUI={true}
          /> */}
          <DataTable
            data={processedRelevantResults}
            mapping={relevantResultsMapping}
            columnWidths={[20, 20, 25, 5, 30]}
            field="processedRelevantResults"
            tableTitle="Results"
          />
          <DataTable
            data={processedCitationResults}
            mapping={citationMapping}
            columnWidths={[5, 15, 10, 10, 15, 20, 20]}
            field="processedCitationResults"
            tableTitle="Citations"
          />
          <RwireReportGraphs isOpenSidebar={false} />
        </div>
      </div>
      <div className="create-new-section">
        <div className="inner pr-3">
          <div
            className="report_view_download pr-4"
            onClick={() => setShowModal(true)}
          >
            <img className="pr-2" src={ReportDownload} alt="" />
            Download
          </div>

          <div onClick={() => setShowMailModal(true)}>
            <img className="pr-2" src={ReportSendOnMail} alt="" />
            Send on mail
          </div>
        </div>
      </div>
      {showMailModal && (
        <SendMailModal
          projectData={projectData}
          isOpen={showMailModal}
          onClose={() => setShowMailModal(false)}
          onSentReportOnMail={onSentReportOnMail}
        />
      )}
      {showModal && (
        <DownloadModal
          projectData={projectData}
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          citedReferences={citedReferences}
        />
      )}
    </div>
    </>
  );
};

export default FinalReportView;
