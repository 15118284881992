import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useEffect } from "react";
import {
  getReportProjectNotes,
  setReportNotes,
  setPatentClaims,
  updateReportProjectNotesRow,
  addRowToReportProject,
  deleteRowFromReportProject,
} from "../../action/report/report-notes";
import NewKeyFeatureSection from "../../components/report-new/keyFeaturesSection";
import { splitPatentClaims } from "../../ui-components/functions/notes/utils";

const Container = (props) => {
  const projectId = sessionStorage.getItem("projectId");
  const { onGetReportProjectNotes } = props;
  useEffect(() => {
    if (projectId) {
      onGetReportProjectNotes();
    }
  }, [onGetReportProjectNotes, projectId]);

  return <NewKeyFeatureSection {...props} />;
};

const mapStateToProps = ({ reportNotes, fullView: { userAnnotations },reportRelevantResults }) => ({
  userAnnotations,
  ...reportNotes,
  ...reportRelevantResults,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onGetReportProjectNotes: getReportProjectNotes,
      onSetReportNotes: setReportNotes,
      onSetPatentClaims: setPatentClaims,
      onSplitPatentClaims: splitPatentClaims,
      onUpdateReportProjectNotesRow: updateReportProjectNotesRow,
      onAddRowToreportProject: addRowToReportProject,
      onDeleteRowFromReportProject: deleteRowFromReportProject,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Container);
