export const data = [
  {
    field: "Abstract",
    shortcode: "AB",
  },
  {
    field: "Applicant Address",
    shortcode: "APA",
  },
  {
    field: "Applicant-Normalized",
    shortcode: "APN",
  },
  {
    field: "Applicant-Original",
    shortcode: "AP",
  },
  {
    field: "Applicant-Standardized",
    shortcode: "APS",
  },
  {
    field: "Application Country",
    shortcode: "AC",
  },
  {
    field: "Application Date",
    shortcode: "AD",
  },
  {
    field: "Application Number",
    shortcode: "AN",
  },
  {
    field: "Assignee Address",
    shortcode: "AA",
  },
  {
    field: "Assignee Country",
    shortcode: "ACC",
  },
  {
    field: "Assignee-Normalized",
    shortcode: "ANZ",
  },
  {
    field: "Assignee-Original",
    shortcode: "AO",
  },
  {
    field: "Assignee-Standardized",
    shortcode: "AS",
  },
  {
    field: "Assignee/Applicant",
    shortcode: "AAP",
  },

  {
    field: "Attorney/Agent",
    shortcode: "AG",
  },
  {
    field: "Attorney/Agent Address",
    shortcode: "AGA",
  },
  {
    field: "Botanic Latin Name",
    shortcode: "BON",
  },
  {
    field: "Botanic Variety",
    shortcode: "BOV",
  },
  {
    field: "Cited Refs Details - NPL",
    shortcode: "BCN",
  },
  {
    field: "Cited Refs Details - Patents",
    shortcode: "BCP",
  },
  {
    field: "Citing Refs Details - Patent",
    shortcode: "FCP",
  },
  {
    field: "Claims/Abstract",
    shortcode: "CLA",
  },
  {
    field: "Claims/Description",
    shortcode: "CD",
  },
  {
    field: "Claims",
    shortcode: "CL",
  },
  {
    field: "Complete Family",
    shortcode: "CF",
  },
  {
    field: "Correspondent",
    shortcode: "CR",
  },
  {
    field: "Correspondent Address",
    shortcode: "CRA",
  },
  {
    field: "CPC",
    shortcode: "CPC",
  },
  {
    field: "Current Assignee -Original",
    shortcode: "CA",
  },
  {
    field: "Current Assignee Address",
    shortcode: "CAA",
  },
  {
    field: "Current Assignee-Normalized",
    shortcode: "CAN",
  },
  {
    field: "Current Assignee-Standardized",
    shortcode: "CAS",
  },
  {
    field: "Dependent Claim",
    shortcode: "DCL",
  },
  {
    field: "Description",
    shortcode: "DESC",
  },
  {
    field: "Designated All Country-PCT",
    shortcode: "DSPCT",
  },
  {
    field: "Designated All States-EP",
    shortcode: "DSEP",
  },
  {
    field: "Designated Contracting-States - EP",
    shortcode: "DSEP_CST",
  },
  {
    field: "Designated Extension-States - EP",
    shortcode: "DSEP_EST",
  },
  {
    field: "Designated National -PCT",
    shortcode: "DSPCT_NT",
  },
  {
    field: "Designated New-Designation-Country -PCT",
    shortcode: "DSPCT_NDSCN",
  },
  {
    field: "Designated Participating-States - EP",
    shortcode: "DSEP_PST",
  },
  {
    field: "Designated Regional Any-Other-State- PCT",
    shortcode: "DSPCT_AOST",
  },
  {
    field: "Designated Regional-Country-PCT",
    shortcode: "DSPCT_RGCN",
  },
  {
    field: "Designated Regional-Region-Country -PCT",
    shortcode: "DSPCT_RGNCN",
  },
  {
    field: "Designated Validation-States - EP",
    shortcode: "DSEP_VST",
  },
  {
    field: "Domestic Family",
    shortcode: "DF",
  },
  {
    field: "Earliest Priority Date",
    shortcode: "EPRD",
  },
  {
    field: "ECLA Class",
    shortcode: "ECL",
  },
  {
    field: "Examiner",
    shortcode: "EX",
  },
  {
    field: "Extended Family",
    shortcode: "EF",
  },
  {
    field: "Independent Claim",
    shortcode: "ICL",
  },
  {
    field: "Inventor",
    shortcode: "IN",
  },
  {
    field: "Inventor Address",
    shortcode: "INA",
  },
  {
    field: "IPC",
    shortcode: "IPC",
  },
  {
    field: "IPC/CPC",
    shortcode: "PCL",
  },
  {
    field: "JP-FI/F-Terms",
    shortcode: "JCL",
  },
  {
    field: "Legal Events",
    shortcode: "LE",
  },
  {
    field: "Locarno Class",
    shortcode: "LC",
  },
  {
    field: "Main Family",
    shortcode: "MF",
  },
  {
    field: "PCT App Date",
    shortcode: "PCTAD",
  },
  {
    field: "PCT Application Number",
    shortcode: "PCT",
  },
  {
    field: "PCT Pub Date",
    shortcode: "PCTPD",
  },
  {
    field: "PCT Pub Number",
    shortcode: "PCTN",
  },

  {
    field: "Priority Country",
    shortcode: "PRC",
  },
  {
    field: "Priority Date",
    shortcode: "PRD",
  },
  {
    field: "Priority Numbers",
    shortcode: "PRN",
  },
  {
    field: "Provisional App Number",
    shortcode: "PVN",
  },
  {
    field: "Publication Kind Code",
    shortcode: "PKC",
  },
  {
    field: "Publication Country",
    shortcode: "PNC",
  },
  {
    field: "Publication Date",
    shortcode: "PD",
  },
  {
    field: "Publication Number",
    shortcode: "PN",
  },
  {
    field: "Simple Family",
    shortcode: "SF",
  },
  {
    field: "Title",
    shortcode: "TI",
  },
  {
    field: "Title/Abstract",
    shortcode: "TA",
  },
  {
    field: "Title/Abstract/Claims",
    shortcode: "TAC",
  },
  {
    field: "Title/Abstract/Claims/Description (Full-Text)",
    shortcode: "ALL",
  },
  {
    field: "Title/Claims",
    shortcode: "TC",
  },
  {
    field: "US Class",
    shortcode: "USC",
  },
  {
    field: "US Series Code",
    shortcode: "SC",
  },
  {
    field: "Assignee Count",
    shortcode: "ACN",
  },
  {
    field: "CPC-Primary",
    shortcode: "CPCP",
  },
  {
    field: "Related Documents",
    shortcode: "RD",
  },
  {
    field: "First Claim",
    shortcode: "FCL",
  },
  {
    field: "Publication Language",
    shortcode: "PL",
  },
  {
    field: "Assignee/Applicant - Original",
    shortcode: "AAPO",
  },
  {
    field: "Assignee/Applicant - Standardized",
    shortcode: "AAPS",
  },
  {
    field: "Assignee/Applicant - Normalized",
    shortcode: "AAPN",
  },
  {
    field: "Legal Status",
    shortcode: "LST",
  },
  {
    field: "Legal State",
    shortcode: "ALD",
  },
  {
    field: "Pub. Type",
    shortcode: "PT",
  },
  {
    field: "Application Year",
    shortcode: "AY",
  },
  {
    field: "Earliest Priority Year",
    shortcode: "EPRY",
  },
  {
    field: "Priority Year",
    shortcode: "PRY",
  },
  {
    field: "Publication Year",
    shortcode: "PY",
  },
  {
    field: "PCT Publication Country",
    shortcode: "PCTPC",
  },
];
