import { useEffect } from "react";
import { useStore } from "react-redux";
import { ACCESS_COMMENTOR } from "../../common/user-roles";
import ChartBlock from "../report/rwire-report-dashboard/chart-block";
import KeyFeaturesCount from "../report/rwire-report-dashboard/key-features-count";
import { NoDashboardData } from "../../assets/images/report";
import { Block } from "../../ui-components";

const GraphsSection = (props) => {
  const {
    keyFeaturesList = [],
    onGetReportProjectNotes,
    keyFeature,
    isOpenSidebar,
    relevantResult,
  } = props;

  const projectId = sessionStorage.getItem("projectId");
  useEffect(() => {
    onGetReportProjectNotes();
  }, [onGetReportProjectNotes, projectId]);

  const store = useStore();
  const {
    app: { userProjectAccess },
  } = store.getState();

  return (
    <div className="mt-2 py-3 px-4">
      <div className="px-4 pt-4 pb-0 graphs-bg">
        {userProjectAccess !== ACCESS_COMMENTOR && (
          <div className="dashboard-content">
            <div
              className={`d-flex content_wrapper justify-content-${
                keyFeaturesList.filter((i) => i.body).length === 0
                  ? "center"
                  : "between"
              }`}
            >
              {relevantResult?.count > 0 ? (
                <ChartBlock
                  {...props}
                  isOpenSidebar={
                    isOpenSidebar &&
                    keyFeaturesList.filter((i) => i.body).length > 0
                  }
                />
              ) : (
                <div className="">
                  <img
                    src={NoDashboardData}
                    alt="NoDashboardData"
                    className="nodata-image"
                  />
                  <h6 className="key-data-not-availabe pt-2 text-center mt-3 mb-2">
                    {" "}
                    Data Not Available!{" "}
                  </h6>
                </div>
              )}
              {isOpenSidebar &&
              keyFeaturesList.filter((i) => i.body).length > 0 &&
              relevantResult?.count > 0 ? (
                <KeyFeaturesCount
                  keyFeaturesList={keyFeaturesList}
                  onGetReportProjectNotes={onGetReportProjectNotes}
                  keyFeature={keyFeature}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GraphsSection;
