import { isObjectBlank } from "../../../action/result-table";
import { formatDate } from "../../../components/report/final-report-view/final-report-view";
import moment from "moment";
export const removeTags = (inputString) => {
  var pattern = /<.*?>/g;
  var resultString = inputString.replaceAll(pattern, "");
  return resultString;
};
export const filterByType = (data, targetType) => {
  if (data && data.length > 0) {
    return data.filter((item) => item.type === targetType);
  } else return [];
};

export const filterNonBlank = (data = []) => {
  return data.filter((item) => {
    if ("title" in item) {
      // Case 1: 'title' field exists
      return item.title.trim() !== "" || item.body.trim() !== "";
    } else if ("classes" in item) {
      // Case 2: 'classes' field exists
      return item.classes.trim() !== "" || item.body.trim() !== "";
    } else {
      // Case 3: 'title' field doesn't exist
      return item.body.trim() !== "";
    }
  });
};
export const getTodaysDate = () => {
  let today = new Date();
  return `${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}`;
};

const transformProcessedRelevantResults = (
  relevantResults,
  patentKeyFeatures
) => {
  return (relevantResults || []).map((result, index) => {
    const matchingPatentData = patentKeyFeatures[index];

    const publicationInfo = `
      Publication Date: ${
        result.publicationDate
          ? moment(result.publicationDate).format("DD/MM/YYYY")
          : "N/A"
      },
      Priority Date: ${
        result.priorityDate
          ? moment(result.priorityDate).format("DD/MM/YYYY")
          : "N/A"
      }
    `;

    const checklist = `
      ${
        matchingPatentData?.availableKeyFeatures?.length > 0
          ? matchingPatentData.availableKeyFeatures.join(" ")
          : "None"
      },
      ${
        matchingPatentData?.notAvailableKeyFeatures?.length > 0
          ? matchingPatentData.notAvailableKeyFeatures.join(" ")
          : "None"
      }
    `;

    return {
      "Patent Number": result.patentNumber || "No patent number",
      "Publication Info": removeTags(publicationInfo),
      Title: removeTags(result.title || "No title"),
      Comments: removeTags(result.comments || ""),
      Checklist: removeTags(checklist),
    };
  });
};

export const getExcelDownloadableData = (data = {}, citedReferences = []) => {
  if (isObjectBlank(data)) return [];

  const multiSheetData = [];
  multiSheetData.push({
    projectName: data.name,
  });
  // Transform 'keyFeatures' data
  const keyFeaturesSheet = {
    sheetName: "Key Features",
    details: filterNonBlank(data.keyFeatures).map((feature, index) => ({
      "Sr. No": `Key Feature-${index + 1}`, // Assuming you want to include 'id' as 'title'
      Features: removeTags(feature.body),
    })),
    tableDescription:
      "The following important key features are identified based on the Prosecution history (if available) and claims of the subject patent:",
  };
  multiSheetData.push(keyFeaturesSheet);

  // Transform 'keywords' data
  const keywordsSheet = {
    sheetName: "Keywords",
    details: filterNonBlank(data.keywords).map((keyword) => ({
      Keywords: removeTags(keyword.title),
      Synonyms: removeTags(keyword.body),
    })),
    tableDescription:
      "The following key words are identified for the keyword based searching. Keywords are selected from the subject patent. Synonyms are included to keep the search broad and exhaustive.",
  };
  multiSheetData.push(keywordsSheet);

  // Transform 'cpcClasses' data
  const cpcClassesSheet = {
    sheetName: "Relevant CPC Classes",
    details: filterNonBlank(data.cpcClasses).map((cpcClass) => ({
      "CPC Classes": removeTags(cpcClass.classes),
      Definition: removeTags(cpcClass.body),
    })),
    tableDescription:
      "Following is the list of relevant CPC classes identified during the search:",
  };
  multiSheetData.push(cpcClassesSheet);

  // Transform 'ipcClasses' data
  const ipcClassesSheet = {
    sheetName: "Relevant IPC Classes",
    details: filterNonBlank(data.ipcClasses).map((ipcClass) => ({
      "IPC Classes": removeTags(ipcClass.classes),
      Definition: removeTags(ipcClass.body),
    })),
    tableDescription:
      "Following is the list of relevant IPC classes identified during the search:",
  };
  multiSheetData.push(ipcClassesSheet);

  // Transform 'usClasses' data
  const usClassesSheet = {
    sheetName: "Relevant US Classes",
    details: filterNonBlank(data.usClasses).map((usClass) => ({
      "US Classes": removeTags(usClass.classes),
      Definition: removeTags(usClass.body),
    })),
    tableDescription:
      "Following is the list of relevant US classes identified during the search:",
  };
  multiSheetData.push(usClassesSheet);

  // Transform 'assignees' data
  const assigneesSheet = {
    sheetName: "Key Assignees",
    details: filterNonBlank(data.assignees).map((assignee, index) => ({
      "Sr. No": index + 1,
      "Key Assignees": removeTags(assignee.body),
    })),
    tableDescription:
      "Following is the list of dominant assignees identified during the search:",
  };
  multiSheetData.push(assigneesSheet);

  // Transform 'inventors' data
  const inventorsSheet = {
    sheetName: "Key Inventors",
    details: filterNonBlank(data.inventors).map((inventor, index) => ({
      "Sr. No": index + 1,
      Inventors: removeTags(inventor.body),
    })),
    tableDescription:
      "Following is the list of dominant inventors identified during the search:					",
  };
  multiSheetData.push(inventorsSheet);

  // Transform 'strings' data
  const stringsSheet = {
    sheetName: "Relevant Search Strings",
    details: filterNonBlank(data.strings).map((string) => ({
      Strings: removeTags(string.body),
      Count: `${string.applicationCount}/${string.publicationCount}`,
    })),
    tableDescription:
      "Following is the list of relevant strings identified during the search:",
  };
  multiSheetData.push(stringsSheet);

  const processedRelevantResultsSheet = {
    sheetName: "Results",
    details: transformProcessedRelevantResults(
      data.relevantResults,
      data.patentKeyFeatures
    ),
    tableDescription: "Description of the processed results...",
  };
  multiSheetData.push(processedRelevantResultsSheet);

  const citationSheet = {
    sheetName: "Cited References",
    details: citedReferences.map((citation, index) => ({
      "Sr.No": index + 1,
      "Publication Number": citation.PN || "No patent number",
      "Pub date": formatDate(citation.PD),
      "App date": formatDate(citation.AD),
      "Priority": formatDate(citation.EPRD),
      "Current Assignee": citation.CA?.join(" | ") || "",
      "Inventors": citation.IN?.join(" | ") || "",
    })),
    tableDescription:
      "The following is a list of cited references for the subject patent:",
  };
  multiSheetData.push(citationSheet);

  return multiSheetData;
};
