export const exportFieldsList = [
  "AB_EN",
  "AN_B",
  "AC",
  "AD",
  "AY",
  "SC",
  "AA_EN",
  "ACC",
  "AO_EN",
  "AOF_EN",
  "ANZ_EN",
  "AS_EN",
  "ACN",
  "APCN",
  "BON",
  "BOV",
  "USM",
  "USD",
  "USF",
  "USFD",
  "LCM",
  "LCF",
  "CPC",
  "CPCP",
  "CPCD",
  "CPC12",
  "CPC4",
  "CPC8",
  "CPCV",
  "CPCO",
  "ECL",
  "ECLD",
  "JCL",
  "IPC",
  "IPCD",
  "IPC12",
  "IPC8",
  "IPC4",
  "IPCRV",
  "CA_EN",
  "CAS_EN",
  "CAN_EN",
  "CAA_EN",
  "DSEP_CST",
  "DSEP_EST",
  "DSEP_VST",
  "DSEP_PST",
  "DSEP",
  "DSPCT_RGCN",
  "DSPCT_AOST",
  "DSPCT_RGNCN",
  "DSPCT_NT",
  "DSPCT_NDSCN",
  "DSPCT",
  "AEX",
  "PEX",
  "EX",
  "TI_EN",
  "CLCN",
  "AG_EN",
  "AGA_EN",
  "AP_EN",
  "API_EN",
  "APS_EN",
  "APN_EN",
  "APFO_EN",
  "APFI_EN",
  "APT",
  "APA_EN",
  "CRA_EN",
  "CR_EN",
  "IN_EN",
  "INCN",
  "INF_EN",
  "INA_EN",
  "CF",
  "DF",
  "EF",
  "MF",
  "SF",
  "CFID",
  "DFID",
  "EFID",
  "MFID",
  "SFID",
  "PCT_B",
  "PCTAD",
  "PCTN_B",
  "PCTPD",
  "PRN_B",
  "PRC",
  "PRD",
  "EPRD",
  "EPRY",
  "PRY",
  "PN_B",
  "PNC",
  "PD",
  "PY",
  "PKC",
  "BCP",
  "BCN",
  "FCP",
  "RDN",
  "PVN",
  "PTA",
  "CL_EN",
  "DESC_EN",
  "DCL_EN",
  "FCL",
  "ICL_EN",
  "GOI",
  "PL",
  "USMS",
  "PCTPC",
  "LST",
  "ALD",
  "PT",
  "OH",
  "FP",
  "LIT",
  "PCL",
  "AAPO",
  "AAPS",
  "AAPN",
];
