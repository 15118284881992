import React, { useRef } from "react";
import { applyMultipleHighlights } from "../../action/patent-view";
import { mappings } from "./tags-mappings";
import { HighlightFromQueryOrManually } from "../../container/highlight";
export const applyMappings = (text = "") => {
  let replacedText = text ? text : "";
  
  replacedText = convertTablesToHTML(replacedText);
  
  for (const [tag, replacement] of Object.entries(mappings)) {
    const regex = new RegExp(`<${tag}\\b([^>]*)>([\\s\\S]*?)<\\/${tag}>`, "gi");
    const matches = replacedText.match(regex);
    if (matches !== null) {
      replacedText = replacedText.replace(
        regex,
        `<${replacement} $1>$2</${replacement}>`
      );
    }
  }
  replacedText = stripWhitespaceAdjacentToTags(replacedText);
  replacedText = convertSubscriptTags(replacedText);
  return `<div class="mapped-text">${replacedText}</div>`;
};

const convertTablesToHTML = (text) => {
  return text
    .replace(/<tables>/gi, "<table>")
    .replace(/<\/tables>/gi, "</table>")
    .replace(/<tr>/gi, "<tr>")
    .replace(/<\/tr>/gi, "</tr>")
    .replace(/<td>/gi, "<td>")
    .replace(/<\/td>/gi, "</td>")
    .replace(/<tgroup[^>]*>/gi, "")
    .replace(/<\/tgroup>/gi, "")
    .replace(/<tbody>/gi, "<tbody>")
    .replace(/<\/tbody>/gi, "</tbody>");
};

const stripWhitespaceAdjacentToTags = (text) => {
  const regex = /\n\s*<([^>]*)>/g;
  return text.replace(regex, "<$1>");
};
export const convertSubscriptTags = (text) => {
  // regex to match all the patterns of subscript tags
  const regex =
    /([A-Za-z0-9]+)(?:\s*\.\s*sub\s*\.\s*([A-Za-z']+))|([A-Za-z0-9]+)\s*\.\s*sub\s*\.\s*(SUM|DIF)|([A-Za-z]+)\.sub\.(S)/g;
  return text.replace(regex, (match, p1, p2, p3, p4, p5, p6) => {
    if (p1 && p2) {
      return `${p1}<sub>${p2}</sub>`;
    } else if (p3 && p4) {
      return `${p3}<sub>${p4}</sub>`;
    } else if (p5 && p6) {
      return `${p5}<sub>${p6}</sub>`;
    }
  });
};

function DescBlock(props) {
  const { description, userAnnotations = {}, languageSelected } = props;
  const containerRef = useRef(null);
  const finalText = applyMultipleHighlights(
    applyMappings(description),
    userAnnotations && userAnnotations[`DESC_${languageSelected}`]
  );

  return (
    <div className="claims-block mt-2">
      <div className="d-flex sticky-claims ">
        <h5 className="me-4">Description</h5>
      </div>
      {description ? (
        <div className={`mt-2 text-justify fixed-height-claims`}>
          {applyMappings(description) && (
            <div
              ref={containerRef}
              className={`DESC_${languageSelected} highlighted-component-modal-desc`}
            >
              <HighlightFromQueryOrManually>
                {finalText}
              </HighlightFromQueryOrManually>
            </div>
          )}
        </div>
      ) : (
        <p className="pt-2">Data not available</p>
      )}
    </div>
  );
}

export default DescBlock;
