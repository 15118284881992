import React, { useState } from "react";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { Image, SlidingSidePanel } from "../../ui-components";
import {
  MinusBlackIcon,
  PlusBlackIcon,
  RightBlackIcon,
  SixDots,
} from "../../assets/images/report";
import ContentEditableInput from "./ContentEditableInput";
import ReportCollaboration from "../../container/report/report-collaboration";

const DragHandle = SortableHandle(() => (
  <span
    className="sortable-dots cursor-grab drag-icon drag-column"
    tabIndex={0}
  >
    <Image width={20} height={20} src={SixDots} className="mx-auto" />
  </span>
));

const ActionHandle = ({
  id = null,
  setCommentingRowID,
  rowIndex,
  onDelete,
  onAddRow,
}) => (
  <>
    <span
      className="sortable-dots cursor-pointer drag-icon drag-column text-center"
      tabIndex={0}
      onClick={() => setCommentingRowID(id)}
      title="Collaboration"
    >
      <Image width={16} height={16} src={RightBlackIcon} className="mx-0" />
    </span>
    <span
      className="sortable-dots cursor-pointer drag-icon drag-column text-center"
      tabIndex={0}
      onClick={() => onAddRow(rowIndex)}
      title="Add row"
    >
      <Image width={20} height={20} src={PlusBlackIcon} className="mx-0" />
    </span>
    <span
      className="sortable-dots cursor-pointer drag-icon drag-column text-center"
      tabIndex={0}
      onClick={() => onDelete(id)}
      title="Delete row"
    >
      <Image width={20} height={20} src={MinusBlackIcon} className="mx-0" />
    </span>
  </>
);
// SortableItem for individual items
const SortableItem = SortableElement(
  ({
    value,
    onChange,
    onSaveInDb,
    idx,
    commentingRowID,
    setCommentingRowID,
    workspaceId = 1,
    rowIndex,
    onDelete,
    onAddRow,
  }) => (
    <div className="assignee-item fs-16 d-flex align-items-center">
      <DragHandle />
      <ContentEditableInput
        cellClassName="px-1 flex-grow-1 value-col"
        onCellChange={onChange}
        text={value.body}
        rowIndex={value.id}
        isChangeText={true}
        onSaveInDb={onSaveInDb}
        idx={idx}
        style={{
          overflowWrap: "break-word",
          width: "70%",
        }}
      />
      <ActionHandle
        id={value?.id}
        setCommentingRowID={setCommentingRowID}
        rowIndex={rowIndex}
        onDelete={onDelete}
        onAddRow={onAddRow}
      />
      <SlidingSidePanel
        isOpen={value?.id === commentingRowID ? true : false}
        onClose={() => setCommentingRowID(null)}
        id="report-collab-modal"
      >
        <ReportCollaboration
          commentingRowID={commentingRowID}
          onClose={() => setCommentingRowID(null)}
          workspaceId={workspaceId}
        />
      </SlidingSidePanel>
    </div>
  )
);

// SortableList for the list of items
const SortableList = SortableContainer(
  ({ items, workspaceId, onChange, onSaveInDb, onDelete, onAddRow }) => {
    const [commentingRowID, setCommentingRowID] = useState(null);
    return (
      <div className="assignees-list border rounded">
        {items.map((item, index) => (
          <SortableItem
            key={`item-${item?.id}`}
            index={index}
            value={item}
            onChange={onChange}
            onSaveInDb={onSaveInDb}
            idx={parseInt(item?.sortOrder)}
            commentingRowID={commentingRowID}
            setCommentingRowID={setCommentingRowID}
            workspaceId={workspaceId}
            rowIndex={index + 1}
            onDelete={onDelete}
            onAddRow={onAddRow}
          />
        ))}
      </div>
    );
  }
);

// Common SortableTable component
const SortableTable = (props) => {
  const {
    items,
    onSortEnd,
    workspaceId,
    onChange,
    onSaveInDb,
    onDelete,
    onAddRow,
  } = props;
  return (
    <SortableList
      items={items}
      onSortEnd={onSortEnd}
      lockAxis="y"
      lockToContainerEdges={true}
      useDragHandle
      pressDelay={100}
      workspaceId={workspaceId}
      onChange={onChange}
      onSaveInDb={onSaveInDb}
      onDelete={onDelete}
      onAddRow={onAddRow}
    />
  );
};

export default SortableTable;
