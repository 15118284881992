export const getBeautifiedQuery = (str) => {
  const patterns = [
    { word: "AND ", style: " AND " },
    { word: "OR ", style: " OR " },
    { word: "ADJ", style: " ADJ", withNumber: true },
    { word: "SAME ", style: " SAME " },
    { word: "NEAR", style: " NEAR", withNumber: true },
    { word: "NOT ", style: " NOT " },
    { word: "S ", style: " S " },
  ];

  let res = str;

  patterns.forEach(({ word, style, withNumber }) => {
    const regex = withNumber
      ? new RegExp(`${word}(\\d*)`, "gi") // Match the word with optional numbers
      : new RegExp(`${word}`, "gi"); // Match the word without numbers

    res = res.replace(regex, (match, number) => {
      const text = withNumber ? `${word}${number}` : word;
      return `<span class="cm-atom" style="color:black;font-weight: bold">${text}</span>`;
    });
  });

  return `<div> ${res} </div>`;
};

export const capitalizeKeywords = (str = "") => {
  const patterns = [" AND ", " OR ", " ADJ", " SAME ", " NEAR", " NOT ", " S "];

  // Build a regex to match the keywords with optional numbers for ADJ and NEAR
  const regex = new RegExp(
    patterns
      .map((word) => (word === "ADJ" || word === "NEAR" ? `${word}\\d*` : word))
      .join("|"),
    "gi"
  );

  return str.replace(regex, (match) => match.toUpperCase());
};
