import React, { useEffect, useState } from "react";
import ContentEditable from "react-contenteditable";
const DataRow = (props) => {
  const { rowIndex, headers, editingCell, field, mapping, row } = props;
  const getCustomValue = (row, header) => {
    const firstParam = header.split("/")[0];
    const secondParam = header.split("/")[1];
    return `${row[firstParam]}/${row[secondParam]}`;
  };
  const [localRow, setLocalRow] = useState({});
  useEffect(() => {
    setLocalRow(row);
  }, [row]);

  const centeredHeaders = ["Key Features", "CPC", "IPC", "Count", "US","Keywords"];

  return (
    <>
      {" "}
      <tr
        key={rowIndex}
        className={rowIndex % 2 === 0 ? "even-row" : "odd-row"}
      >
        {headers.map((header, colIndex) => {
          // Get the value for the cell, or set to an empty string if undefined
          const cellValue = mapping[header].includes("/")
            ? getCustomValue(localRow, mapping[header])
            : localRow[mapping[header]]
                ?.toString()
                ?.replaceAll("&nbsp;", " ") || "";

          // Handle multiline rendering for "Publication Information" and "Checklist"
          if (header === "Publication Information" || header === "Checklist") {
            // Split the cell value into lines (adjust the split based on your needs)
            const lines = cellValue ? cellValue.split(",") : [""]; // Split by comma for each line

            return (
              <td
                key={colIndex}
                className={`${
                  editingCell?.row === rowIndex && editingCell?.field === field
                    ? "editing-td"
                    : "read-only-td"
                }`}
              >
                <ContentEditable
                  className="editable-cell report-string-cell-height"
                  disabled={
                    editingCell?.row !== rowIndex ||
                    editingCell?.field !== field
                  }
                  html={lines
                    .map(
                      (line, index) => `<div key=${index}>${line.trim()}</div>`
                    )
                    .join("")}
                />
              </td>
            );
          } else {
            return (
              <td
                key={colIndex}
                className={`${
                  editingCell?.row === rowIndex && editingCell?.field === field
                    ? "editing-td"
                    : "read-only-td" 
                } ${centeredHeaders.includes(header) ? "text-center" : ""}`}
              >
                <ContentEditable
                  className={`editable-cell report-string-cell-height ${header === "Count" ? "blue-text" : ""}`}
                  disabled={
                    editingCell?.row !== rowIndex ||
                    editingCell?.field !== field
                  }
                  html={cellValue}
                />
              </td>
            );
          }
        })}
      </tr>
    </>
  );
};

export default DataRow;
