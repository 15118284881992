import pptxgen from "pptxgenjs";
import LogoImg from "../../../assets/images/logo.png";
import { removeTags } from "../../../ui-components/functions/report-view/utils";

export const PPTExportHelper = (projectData, isDownload = false) => {
  let pptx = new pptxgen();
  const filteredData = projectData
    .filter((sheet) => sheet.sheetName)
    .filter((sheet) => sheet.details.length > 0);
  // Function to add cover page
  const addCoverPage = () => {
    const coverSlide = pptx.addSlide();
    coverSlide.addImage({
      path: LogoImg,
      x: 0.1,
      y: 0.5,
      w: 4,
      // h: 1,
    });

    // Add report name
    coverSlide.addText("Invalidation Search Report", {
      x: 0.1,
      y: 2,
      w: "90%",
      fontSize: 20,
      bold: true,
      fontFace: "Arial",
    });

    // Add today's date
    const currentDate = new Date().toLocaleDateString();
    coverSlide.addText(`Priority Date: ${currentDate}`, {
      x: 0.1,
      y: 2.4,
      w: "90%",
      bold: true,
      fontSize: 20,
      fontFace: "Arial",
    });

    // Add table with one header and one cell
    const coverTable = [
      [
        {
          text: "Report Overview",
          options: {
            bold: true,
            fill: "#E0E0E0",
            color: "#000000",
            valign: "middle",
          },
        },
      ],
      [
        "2 relevant patents and 12 additional patents are auncovered during the search. The Search covered the main keyfeatures as described on Scope of the Search page. The search was conducted on basis of keywords, IPC, US, CPC classes and Assignees as depicted on search history sheet. The check list depicts the feature found in prior art. Our team has tried to map elements of the claim of interest with the features found in uncovered prior art as depicted in the claim mapping sheet.",
      ],
    ];

    coverSlide.addTable(coverTable, {
      x: 0.2,
      y: 2.8,
      w: "90%",
      colW: [8],
      border: { pt: 1, color: "#E0E0E0" },
    });
  };

  // Function to add contents page
  const addContentsPage = () => {
    const contentsSlide = pptx.addSlide();

    // Add title for contents page
    contentsSlide.addText("Contents Page", {
      x: 0.1,
      y: 0.5,
      w: "90%",
      fontSize: 24,
      bold: true,
      fontFace: "Arial",
    });

    // Add single column table with values equal to sheetName
    const contentsTable = filteredData.map((sheet) => [
      { text: sheet.sheetName, options: { align: "center" } },
    ]);

    contentsSlide.addTable(
      [
        [
          {
            text: "Table of Contents",
            options: {
              bold: true,
              fill: "#E0E0E0",
              color: "#000000",
              valign: "middle",
              align: "center",
            },
          },
        ],
        ...contentsTable,
      ],
      {
        x: 0.1,
        y: 1.3,
        w: "90%",
        colW: [5],
        border: { pt: 1, color: "#E0E0E0" },
      }
    );
  };

  // Add cover page
  addCoverPage();

  // Add contents page
  addContentsPage();

  if (filteredData.length > 0) {
    filteredData.forEach((sheet) => {
      let slide = pptx.addSlide();

      const addSheetInfo = () => {
        // Add sheetName above the table
        slide.addText(sheet.sheetName, {
          x: 0.1,
          y: 0.2,
          w: "90%",
          fontSize: 18,
          bold: true,
          fontFace: "Arial",
        });

        // Add table description below sheetName
        slide.addText(sheet.tableDescription, {
          x: 0.1,
          y: 0.2,
          w: "90%",
          h: 1,
          fontSize: 12,
          fontFace: "Arial",
        });
      };

      const addTable = () => {
        // Add a table to the slide
        const table = [];
        if (sheet.details && sheet.details.length > 0) {
          // Add header row
          const headerRow = Object.keys(sheet.details[0]).map((header) => ({
            text: header,
            options: {
              bold: true,
              fill: "#E0E0E0",
              color: "#000000",
              valign: "middle",
            },
          }));
          table.push(headerRow);

          // Add data rows
          sheet.details.forEach((detail, index) => {
            const rowColor = index % 2 === 0 ? "#FFFFFF" : "#F4F4F4";

            const dataRow = Object.values(detail).map((value) => ({
              text: removeTags(value.toString()),
              options: {
                fill: rowColor,
                color: "#000000",
              },
            }));
            table.push(dataRow);
          });

          const numColumns = table[0].length;
          let colWidths;
          if (
            numColumns === 2 &&
            Object.keys(sheet.details[0]).includes("Strings") &&
            Object.keys(sheet.details[0]).includes("Count")
          ) {
            colWidths = [6, 3];
          } else if (numColumns === 2) {
            colWidths = [2, 7.5];
          } else if (numColumns === 3) {
            colWidths = [3, 3, 3];
          } else {
            colWidths = new Array(numColumns).fill(9 / numColumns);
          }

          // Add table to the slide
          slide.addTable(table, {
            x: 0.1,
            y: 1,
            w: "100%",
            autoPage: true,
            colW: colWidths,
            h: "70%",
            border: { pt: 1, color: "#E0E0E0" },
            autoPageRepeatHeader: true,
            autoPageHeaderRows: 1,
            autoPageSlideStartY: 1,
            rowH: 0.4,
          });
          // If the table overflows to the next slide, add a new slide and re-add sheet info
          slide.newAutoPagedSlides.forEach((slide) => {
            slide.addText(sheet.sheetName, {
              x: 0.1,
              y: 0.2,
              w: "90%",
              fontSize: 18,
              bold: true,
              placeholder: "footer",
            });

            // Add table description below sheetName
            slide.addText(sheet.tableDescription, {
              x: 0.1,
              y: 0.4,
              w: "90%",
              h: 1,
              fontSize: 12,
              placeholder: "footer",
            });
          });
        }
      };

      // Add sheet info and table to the current slide
      addSheetInfo();
      addTable();
    });

    if (!isDownload) {
      return pptx.write();
    } else {
      // Save the presentation
      pptx.writeFile({ fileName: `${projectData[0].projectName}.pptx` });
    }
  } else {
    // eslint-disable-next-line no-alert
    alert("Add Some Project Data");
  }
};
