import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import "./styles.scss";
import SearchIcon from "../../../assets/images/search-black.png";
import { useNavigate } from "react-router-dom";
import { RwireProjectModal } from "../rwire-project-creation-wrapper";
import { ACCESS_COMMENTOR, ROLE_OWNER } from "../../../common/user-roles";
import CreateProject from "../../../container/report/create-project";
import RwireLoader from "../../../container/rwire-loader";
import useConfirm from "../../../context/ConfirmDialogProvider";
import { TbEdit } from "react-icons/tb";
import { RiDeleteBin6Line } from "react-icons/ri";

function formatDate(unixTimestamp) {
  const date = new Date(unixTimestamp * 1000); // Convert to milliseconds
  const options = { day: "2-digit", month: "short", year: "numeric" };
  return date.toLocaleDateString("en-GB", options).replace(/ /g, "-"); // Replace spaces with dashes
}

function capitalizeWords(str) {
  return str
    .split(" ") // Split the string into words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize first letter and lowercase the rest
    .join(" "); // Join the words back into a string
}

const smartSearchField = "SS";

const ExistingProject = (props) => {
  const {
    onGetReportProjects,
    onResetProjectData,
    onSetApp,
    onSetProjectReport,
    onGetReportProjectDataById,
    onGetAllProjectUsers,
    onGetBackwardCitationsData,

    smartSearchWord,
    onPerformSearch,
    onSetAllField,
    tabWiseSearchQuery,
    onDeleteReportProject,
    onSetResultTable,
  } = props;
  const [data, setData] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [displayValue, setDisplayValue] = useState(smartSearchWord);
  const navigate = useNavigate();
  const confirm = useConfirm();

  useEffect(() => {
    if (smartSearchWord) {
      setDisplayValue(smartSearchWord);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smartSearchWord]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        onSetResultTable({ isLoadingResult: true });
        let response = await onGetReportProjects();
        if (response && response.result && response.result.length > 0) {
          setData([response.result][0]);
          onSetApp({ userProjects: response.result });
        } else {
          onSetApp({ userProjects: [] });
        }
      } catch (error) {
        onSetApp({ userProjects: [] });
        // eslint-disable-next-line no-console
        console.log(error);
      } finally {
        onSetResultTable({ isLoadingResult: false });
        setDataFetched(true);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const viewProject = async (id, name, access) => {
    setShowModal(true);
    sessionStorage.setItem("projectId", id);
    sessionStorage.setItem("projectMode", true);
    sessionStorage.setItem("userProjectAccess", access);
    onSetProjectReport({ projectName: name });
    onResetProjectData();

    const data = await onGetReportProjectDataById(id);
    await onGetAllProjectUsers(id);
    onSetProjectReport({
      workspaceElements: data.workspaceElements.split(",").map(Number),
    });
    await onGetBackwardCitationsData();
    setTimeout(() => {
      onSetApp({
        isProjectMode: true,
      });
      setShowModal(false);
      if (access === ACCESS_COMMENTOR) navigate("/en/report/notes");
      else navigate("/en/report");
      // const firstElement = data.workspaceElements.split(",")[0];
    }, 2000);
  };

  const handleSearch = async () => {
    localStorage.removeItem("tableScrollPosition");
    if (displayValue) {
      const querySearch = `${smartSearchField}=(${displayValue})`;

      onSetApp({
        smartSearchWord: displayValue,
        searchQuery: querySearch,
        isSearchFromIncremental: false,
      });
      onSetAllField({
        tabWiseSearchQuery: { ...tabWiseSearchQuery, fielded: querySearch },
      });
      onSetApp({ selectedIncludes: [] });
      await onPerformSearch(
        querySearch,
        { isResetHighlight: true },
        navigate,
        "/en/rwire-patents"
      ).then((data) => {
        if (!data) {
          // eslint-disable-next-line no-console
          console.log("Error:", props.error);
        }
      });
    }
  };

  const handleClick = async () => {
    await handleSearch();
  };

  const handleEnter = async (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      if (!displayValue) {
        return false;
      }

      await handleSearch();
    }

    return;
  };

  const handleDeleteProject = async (id, permission = false) => {
    if (!permission) return;
    const choice = await confirm({
      title: "Delete the project",
      description: "Are you sure you want to delete the project ?",
      confirmBtnLabel: "Confirm",
    });

    if (choice) {
      await onDeleteReportProject(id);
      const openedProjectId = sessionStorage.getItem("projectId");
      if (id.toString() === openedProjectId) {
        onSetApp({
          isProjectMode: false,
        });
        sessionStorage.removeItem("projectId");
        sessionStorage.setItem("projectMode", false);
      }
      const updatedData = data.filter((item) => item.id !== id);
      setData([...updatedData]);
    }
  };
  return (
    <>
      <RwireLoader />
      <div className="research-wire-existing-project">
        <div className="project-list">
          <div className="search-container">
            <div className="d-flex search-bar justify-content-center">
              <input
                type="text"
                className="form-control fs-16"
                placeholder="Smart Search"
                value={displayValue}
                onChange={(e) => setDisplayValue(e.target.value)}
                onKeyDown={handleEnter}
              />
              <button
                className="btn btn-warning exp-search-button"
                onClick={handleClick}
              >
                <img src={SearchIcon} width={20} alt="" />
              </button>
            </div>
          </div>
          <div className="content">
            <div className="d-flex justify-content-start gap-4 align-items-center mb-3">
              <h5 className="mb-0 fw-bold fs-16">
                {data.length > 0
                  ? "Please select a project"
                  : "Please create the project"}
              </h5>

              <div className="project-search-container">
                <Form.Control
                  type="text"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="search-input fs-16"
                />
                <img src={SearchIcon} alt="Search" className="search-icon" />
              </div>
            </div>
            <div className="p-0 table-bg">
              <div className="table-container">
                {data && data.length > 0 ? (
                  <Table bordered={false} striped>
                    <thead className="fs-16">
                      <tr>
                        <th className="border-start-0 text-center id-width">
                          Id
                        </th>
                        <th className="name-width">Name</th>
                        <th className="name-width">Project Type</th>
                        <th className="members-width">Leads</th>
                        <th className="date-width">Created Date</th>
                        <th className="actions-width">Action</th>
                      </tr>
                    </thead>
                    <tbody className="fs-16">
                      {data
                        .filter((project) =>
                          project.name
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        )
                        .map((project, index) => (
                          <tr
                            key={project.id}
                            className={index % 2 === 0 ? "even-row" : "odd-row"}
                          >
                            <td className="border-start-0 text-center project-name id-width">
                              {index + 1}
                            </td>
                            <td className="name-width">
                              <span className="project-name">
                                {project.name}
                              </span>
                              <br />
                            </td>
                            <td className="text-center">
                              <span
                                className={`project-type ${project?.type?.toLowerCase()}`}
                              >
                                {project?.projectType}
                              </span>
                            </td>
                            <td className="members-width">
                              <div className="members-container">
                                <div className="text-center">
                                  <span className="members-value">
                                    {capitalizeWords(project.createdBy)}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td className="text-center date-width">
                              {formatDate(project.createdAt)}
                            </td>
                            <td className="text-center action-icons actions-width">
                              <span
                                className="p-2 cursor-pointer me-2"
                                title="Edit Project"
                                onClick={() =>
                                  viewProject(
                                    project.id,
                                    project.name,
                                    project.access
                                  )
                                }
                              >
                                <TbEdit />
                              </span>

                              <span
                                className={`p-2 ${
                                  project?.role === ROLE_OWNER
                                    ? "cursor-pointer"
                                    : "opacity-50 cursor-not-allowed"
                                }`}
                                title={`${
                                  project?.role === ROLE_OWNER
                                    ? "Delete Project"
                                    : "Owner has access to delete it."
                                }`}
                                onClick={() =>
                                  handleDeleteProject(
                                    project.id,
                                    project?.role === ROLE_OWNER
                                  )
                                }
                              >
                                <RiDeleteBin6Line />
                              </span>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                ) : (
                  <div className="no-project-message">
                    <p>Please create the project</p>
                  </div>
                )}
              </div>
              <div className="create-project-container">
                <Button
                  // onClick={() => navigate("/en/create-project")}
                  onClick={() => setShowCreateModal(true)}
                  className="create-project-btn btn-with-blue-border-white-bg fs-16"
                >
                  Create Project
                </Button>
              </div>
            </div>
          </div>
        </div>
        {showModal && <RwireProjectModal open={showModal} />}
        {showCreateModal && (
          <CreateProject
            isOpen={showCreateModal}
            onClose={() => setShowCreateModal(false)}
            // projects={data}
            {...props}
          />
        )}
      </div>
    </>
  );
};

export default ExistingProject;
