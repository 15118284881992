/* eslint-disable no-console */
import { fetch } from "../../service/fetch";
import { getProjectDashboard } from "./report-dashboard";

export const setReportString = (payload) => ({
  type: "SET_STRING",
  payload: {
    ...payload,
  },
});

export const addString =
  ({ body, publicationCount, applicationCount, order, projectId }) =>
  async (dispatch) => {
    try {
      await fetch(
        {
          url: "/api/project-strings",
          body: {
            data: [
              {
                body,
                publicationCount,
                applicationCount,
                order,
              },
            ],
            projectId,
          },
        },
        "POST"
      );
      dispatch(getProjectDashboard());
    } catch (e) {}
  };

export const getReportProjectString =
  ({ projectId }) =>
  async (dispatch) => {
    try {
      const { result: data } = await fetch(
        {
          url: `/api/project-strings/project/${projectId}`,
        },
        "GET"
      );

      const filteredData = data.map((i) => {
        let Counts = "";
        if (i.body) Counts = `${i.publicationCount}/${i.applicationCount}`;

        return {
          title: i.body,
          body: Counts,
          id: i.id,
          sortOrder: i.sortOrder,
        };
      });
      dispatch(setReportString({ reportTableData: filteredData }));
    } catch (e) {}
  };

export const updateReportProjectStringRow =
  ({ id, body, publicationCount, applicationCount, order }) =>
  async (dispatch) => {
    try {
      await fetch(
        {
          url: `/api/project-strings/${id}`,
          body,
          publicationCount,
          applicationCount,
          order,
        },
        "PUT"
      );
      dispatch(getProjectDashboard());
    } catch (error) {
      console.log("error>>>", error);
    }
  };

export const updateReportProjectStringSortOrder =
  (body) => async (dispatch) => {
    try {
      await fetch(
        {
          url: `/api/project-strings/sort-order`,
          body,
        },
        "POST"
      );
    } catch (error) {
      console.log("error>>>", error);
    }
  };

export const deleteRow = (id) => async (dispatch) => {
  try {
    await fetch(
      {
        url: `/api/project-strings/${id}`,
      },
      "DELETE"
    );
    dispatch(getProjectDashboard());
  } catch (error) {
    console.log("error>>>", error);
  }
};
