const initialState = {
  itemsPerPage: 20,
  totalPage: 1,
  page: 1,
  limit: 10,
  totalRecordsCount: 0,
  selectedRows: [],
  data: [],
  selectedSearchType: "fielded",
  selectedItemList: [],
  totalApplicationCount: 0,
  selectedRowsForCombine: [],
  historySortDirection: "DESC",
};

const historyTable = (state = initialState, { type, payload }) => {
  switch (type) {
    case "HISTORY_SET":
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default historyTable;
