import React, { useState } from "react";
import { Table } from "react-bootstrap";
import moment from "moment";
import ReleventResultAddModal from "../report/rwire-report-relevant-results/relevant-result-add-modal";
import ReleventResultFeaturesEditModal from "../report/rwire-report-relevant-results/relevent-result-features-edit-modal";
import { PlusIconNew } from "../../assets/images/report";
import { Image, SlidingSidePanel } from "../../ui-components";
import { deleteIcon, RightBlackIcon } from "../../assets/images/report";
import { openPatentInNewTab } from "../../common/open-new-tab";
import { useParams } from "react-router-dom";
import { Modal } from "rsuite";
import { IoMdClose } from "react-icons/io";
import ReportCollaboration from "../../container/report/report-collaboration";

const RelevantResultSection = (props) => {
  const {
    relevantResultReportTableData,
    reportTableData,
    onRwireSearchAPI,
    onRemove,
    onSetFilter,
    setUserInput,
    userInput,
    isGenerateListCalled,
  } = props;

  const [isModalOpen, setModalData] = useState(false);
  const [openTabs, setOpenTabs] = useState([]);
  const [isVisibleMessageModal, setVisibilityMessageModal] = useState(false);
  const [messageModalContent, setMessageModalContent] = useState("");
  const [commentingRowID, setCommentingRowID] = useState(null);
  const projectId = sessionStorage.getItem("projectId");
  let { locale } = useParams();

  const handlePatentNoModal = () => {
    setModalData(true);
  };

  const handleCloseModal = () => {
    setUserInput({ ...userInput, isModalOpen: false, relevantResultId: 0 });
  };

  const handleRedirect = (id) => {
    onSetFilter({ isViewPageModalOpen: false, isSmartReaderOpen: false });
    sessionStorage.setItem("patentId", id);
    openPatentInNewTab(
      id,
      locale,
      openTabs,
      setOpenTabs,
      setMessageModalContent,
      setVisibilityMessageModal
    );
  };

  const getData = (featuresData = []) => {
    let greenElement = [];
    let redElement = [];
    const featuresId = featuresData.map((i) => i.keyFeature);
    reportTableData
      .filter((f) => f.body)
      .forEach((i, key) => {
        if (featuresId.includes(i.id)) {
          greenElement.push(key + 1);
        } else {
          redElement.push(key + 1);
        }
      });

    return (
      <>
        {greenElement && greenElement.length > 0 && (
          <div className="success">
            {greenElement.map((ele, index) => (
              <span key={ele}>
                {ele} {index + 1 === greenElement.length ? "" : ", "}
              </span>
            ))}
          </div>
        )}
        <span
          className={`${
            greenElement.length && redElement.length ? "px-1" : ""
          }`}
        ></span>
        {redElement && redElement.length > 0 && (
          <div className="danger text-break">
            {redElement.map((ele, index) => (
              <span key={ele}>
                {ele} {index + 1 === redElement.length ? "" : ", "}
              </span>
            ))}
          </div>
        )}
      </>
    );
  };

  const handleRemove = (row) => {
    onRemove({ patentNumber: row.patentNumber, id: row.id });
  };

  const relevantItem = relevantResultReportTableData.find(
    (i) => i.id === commentingRowID
  );
  return (
    <>
      <div className="bg-white px-3 py-2">
        <div className="px-4 pt-1 pb-2 strings-section border">
          <div className="d-flex justify-content-between align-items-center mb-2 ml-2">
            <p className="relevant-result-header-text fs-16 mb-0">Results</p>
            <p
              className="mr-3 new-add-btn cursor-pointer"
              title="Add Patent"
              onClick={handlePatentNoModal}
            >
              <img src={PlusIconNew} alt="" />
            </p>
          </div>
          <div className="strings-table">
            <div className="relevant-result-table">
              <Table bordered={false}>
                <thead>
                  <tr>
                    <th className=" checkbox-column fs-16 text-nowrap column-width-10 text-start">
                      Publication Number
                    </th>
                    <th className="fs-16 text-nowrap column-width-15">
                      Publication Information
                    </th>
                    <th className="fs-16 column-width-20">Title</th>
                    <th className="fs-16 column-width-10">Comments</th>
                    <th className="fs-16 column-width-15">Checklist</th>
                    <th className="column-width-5"></th>
                  </tr>
                </thead>
                <tbody>
                  {isGenerateListCalled &&
                  relevantResultReportTableData.length === 0 ? (
                    <tr>
                      <td colSpan="6" className="text-center fs-16 py-4">
                        No results found
                      </td>
                    </tr>
                  ) : relevantResultReportTableData.length === 0 ? (
                    <tr>
                      <td colSpan="6" className="text-center fs-16 py-4">
                        Please add the patents
                      </td>
                    </tr>
                  ) : (
                    relevantResultReportTableData.map((item, index) => (
                      <tr key={item.id}>
                        <td
                          className="fs-16 column-width-10 publication-no fw-bold pn-hyperlink"
                          onClick={() => handleRedirect(item.patentNumber)}
                        >
                          {item.patentNumber}
                        </td>
                        <td className="fs-16 column-width-15">
                          <div>
                            <span className="result_table_dates mr-2">
                              Publication:
                            </span>
                            {item.publicationDate
                              ? moment(item.publicationDate).format(
                                  "DD/MM/YYYY"
                                )
                              : "N/A"}
                          </div>
                          <div>
                            <span className="result_table_dates mr-2">
                              Priority:
                            </span>
                            {item.priorityDate
                              ? moment(item.priorityDate).format("DD/MM/YYYY")
                              : "N/A"}
                          </div>
                        </td>
                        <td className="fs-16 column-width-12">{item.title}</td>
                        <td className="fs-16 column-width-10"></td>
                        <td className="fs-16 column-width-15">
                          {getData(item.projectRelevantResultKeyFeatureData)}
                        </td>
                        <td className="column-width-10">
                          <div className="icon-container d-flex justify-content-center align-items-center">
                            <div
                              className="cursor-pointer"
                              onClick={() => handleRemove(item)}
                            >
                              <Image src={deleteIcon} title="Delete" />
                            </div>
                            <div className="ml-2">
                              <Image
                                src={RightBlackIcon}
                                title="Collaboration"
                                tabIndex={0}
                                onClick={() => setCommentingRowID(item.id)}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <ReleventResultAddModal
          isModalOpen={isModalOpen}
          onCloseModal={() => setModalData(false)}
          onRwireSearchAPI={onRwireSearchAPI}
          userInput={userInput}
          setUserInput={setUserInput}
        />
      )}

      {userInput.isModalOpen && (
        <ReleventResultFeaturesEditModal
          isModalOpen={userInput.isModalOpen}
          onCloseModal={handleCloseModal}
          userInput={userInput}
          projectId={projectId}
          setUserInput={setUserInput}
          {...props}
        />
      )}

      <Modal
        className="modalClassMultitab"
        static="static"
        keyboard={true}
        open={isVisibleMessageModal}
      >
        <div
          className="highlight-modal-close-btn"
          onClick={() => setVisibilityMessageModal(false)}
        >
          <IoMdClose className="modal__close" title="Close" />
        </div>
        <div>{messageModalContent}</div>
      </Modal>

      <SlidingSidePanel
        isOpen={Boolean(relevantItem)}
        onClose={() => setCommentingRowID(null)}
        id="report-collab-modal"
      >
        <ReportCollaboration
          commentingRowID={commentingRowID}
          onClose={() => setCommentingRowID(null)}
          workspaceId={6}
        />
      </SlidingSidePanel>
    </>
  );
};

export default RelevantResultSection;
