import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckboxGroup, Checkbox } from "rsuite";
import RwireTextInputWithSearchIcon from "../common-functions/rwire-textInput-withIcon";

function RwireFiltersSettings(props) {
  const { onSetFilter, filterList } = props;
  const [value, setValue] = useState(filterList);
  const [error, setError] = useState(null);
  const [query, setQuery] = useState("");

  const checkList = {
    standardized_assignee_name: "Current Assignee",
    inventor_name: "Inventor",
    ipc: "IPC",
    cpc: "CPC",
    us_class: "US Class-Main-Divided",
    ipc_cpc: "IPC/CPC",
    priority_country: "Priority Country",
    publication_country: "Publication Country",
    publication_year: "Publication Year",
    agent_name: "Attorney/Agent",
    application_year: "Application Year",
    application_country: "Application Country",
    us_series_code: "US Series Code",
    assignee_country: "Assignee Country",
    assignee_standardized_english: "Assignee Standardized",
    original_assignee_english: "Assignee Original",
    original_assignee_first_english: "Assignee Original First",
    assignee_normalized_english: "Assignee Normalized",
    botanic_latin_name: "Botanic Name",
    botanic_variety: "Botanic Variety",
    us_class_further_classification_divided: "US Class-Further-Divided",
    locarno_class_main_classification: "Locarno Class-Main",
    locarno_class_further_classification: "Locarno Class-Further",
    cpc_primary: "CPC-Primary",
    cpc_12_digit: "CPC-12 Digit",
    cpc_8_digit: "CPC-8 Digit",
    cpc_4_digit: "CPC-4 Digit",
    cpc_version: "CPC-Version",
    cpc_assigning_office: "CPC-Assigning Office",
    ecla_class_divided: "ECLA-Divided",
    jp_fi_f_terms: "JP-FI/F-Terms",
    ipc_12_digit: "IPC-12 Digit",
    ipc_8_digit: "IPC-8 Digit",
    ipc_4_digit: "IPC-4 Digit",
    ipcr_version: "IPC-Version",
    current_assignee_standardized_english: "Current Assignee Standardized",
    current_assignee_normalized_english: "Current Assignee Normalized",
    designated_contracting_states_ep: "Contracting-States - EP",
    designated_extension_states_ep: "Extension-States - EP",
    designated_validation_states_ep: "Validation-States - EP",
    designated_up_participating_states_ep: "UP-Participating-States - EP",
    designated_states_all_ep: "Designated States (All) - EP",
    designated_regional_country: "Regional Country - PCT",
    designated_regional_any_other_state: "Regional Any-Other-State - PCT",
    designated_regional_region_country: "Designated Regional-Region-Country",
    designated_pct_national: "PCT - National",
    designated_pct_new_designation_country: "New-Designation-Country - PCT",
    designated_states_all_pct: "Designated States (All) -PCT",
    primary_examiner: "Examiner",
    applicant_first_english_organization: "Applicant First Organization",
    applicant_first_english_individual: "Applicant First Individual",
    correspondent_english: "Correspondent",
    inventor_first_english: "Inventor First",
    earliest_priority_year: "Earliest Priority Year",
    priority_year: "Priority Year",
    applicant_type: "Applicant Type",
    legal_state: "Legal State",
    legal_status: "Legal Status",
    publication_type: "Publication Type",
    assignee_applicant_original: "Assignee/Applicant - Original",
    assignee_applicant_standardized: "Assignee/Applicant - Standardized",
    assignee_applicant_normalized: "Assignee/Applicant - Normalized",
  };

  const { t } = useTranslation();

  const handleChange = (value) => {
    if (value.length === 0) {
      setError("Select at least one filter");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    let temp = value.slice(0, 15);
    if (value.length > 15) {
      setError("Maximum 15 Selections are allowed");
      setTimeout(() => {
        setError("");
      }, 3000);
    } else {
      setError(null);
    }
    onSetFilter({ filterList: temp });
    setValue(temp);
  };
  return (
    <div className="filter-setting-modal">
      {error && (
        <div className="d-flex justify-content-center">
          <p
            className={`max-filter-error ${
              error === "Select at least one filter" ? "new-error" : ""
            }`}
          >
            {error}
          </p>
        </div>
      )}
      <RwireTextInputWithSearchIcon
        onChange={(e) => setQuery(e.toLowerCase())}
        iconClassName="filter-settings-search-icon"
        inputClassName="filter-settings-search-input"
        placeholder="Search For Filter"
      />
      <div className="filter-list-container">
        <CheckboxGroup
          name="checkboxList"
          value={value}
          onChange={handleChange}
        >
          <ul className="filter-settings-list d-flex flex-column ">
            {checkList &&
              Object.entries(checkList)
                .filter(([key, value]) => {
                  return value.toLowerCase().includes(query.toLowerCase());
                })
                .map(([key, value]) => (
                  <Checkbox key={key} value={key}>
                    {t(`${key}`)}
                  </Checkbox>
                ))}
          </ul>
        </CheckboxGroup>
      </div>
    </div>
  );
}

export default RwireFiltersSettings;
