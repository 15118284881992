import EditQueryPopup from "../../components/edit-query-popup/edit-query-popup";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setApp } from "../../action/patentAndPublication";
import { setEditQuery } from "../../action/edit-query-popup";
import { validationQuery, performSearch } from "../../action/rwire-search-api";
import { setAllField, setQuery } from "../../action/search-query";
import { fetchHistory, updateQueryCount } from "../../action/history";
import { setCombineQuery } from "../../action/combine-query-popup";

const Container = (props) => {
  return <EditQueryPopup {...props} />;
};
const mapStateToProps = ({
  editQuery,
  app: { editQuery: query },
  searchQuery: { syntaxqueryError, syntaxqueryCorrect, queryFields },
}) => ({
  query,
  ...editQuery,
  syntaxqueryError,
  syntaxqueryCorrect,
  queryFields,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onSetApp: setApp,
      onSetEditQueryPopup: setEditQuery,
      validationQuery: validationQuery,
      onSetQuery: setQuery,
      onUpdateQueryCount: updateQueryCount,
      onFetchHistory: fetchHistory,
      onPerformSearch: performSearch,
      onSetAllField: setAllField,
      onSetCombineQueryPopup: setCombineQuery,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Container);
