import React from "react";
import RwireClickableField from "../../../container/rwire-clickable-field";

const RwireCurrentAssignee = (props) => {
  const {
    currentAssignee,
    assigneeToggle,
    onPerformSearch,
    isViewPage = false,
  } = props;

  const currentAssigneeList = assigneeToggle
    ? currentAssignee
    : currentAssignee.slice(0, 2);

  return (
    <>
      {currentAssignee && (
        <div
          className={`d-flex justify-content-between ${
            currentAssignee.length > 2 ? "menu" : ""
          }`}
        >
          <div>
            {currentAssigneeList &&
              currentAssigneeList.map((currentAssignee, index) => (
                <RwireClickableField
                  name={currentAssignee}
                  isLast={
                    currentAssignee && currentAssigneeList.length === index + 1
                  }
                  onPerformSearch={onPerformSearch}
                  shortCode="CA"
                  joiningCharacter=" , "
                  isViewPage={isViewPage}
                />
              ))}
          </div>
          {!assigneeToggle && currentAssignee.length > 2 ? (
            <div className="ms-1 inventor"> +{currentAssignee.length - 2}</div>
          ) : null}
        </div>
      )}
    </>
  );
};

export default RwireCurrentAssignee;
