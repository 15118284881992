import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "rsuite";
import RWireButton from "../rwire-ui/rwire-button/rwire-button";
import RWIRE_IMAGES from "../common/common-functions/rwire-images";
import { Input } from "rsuite";
import "./combine-query-popup.scss";
import CloseIcon from "@rsuite/icons/Close";
import {
  addHistory,
  constructQueries,
  getHistoryDetailsById,
  getQueryCount,
} from "../../action/history";
import ContentEditable from "../common/content-editable/history-new-content-editable";
const CombineQueryPopup = (props) => {
  const {
    isOpenCombineQueryModal,
    onSetCombineQueryPopup,
    onSetQuery,
    syntaxqueryCorrect,
    syntaxqueryError,
    validationQuery,
    selectedQueryRows,
    selectedCombineQueryRows,
    selectedCombineQuery,
    selectedCombineQueryId,
    onSetApp,
    onFetchHistory,
    selectedItemList,
    onPerformSearch,
    onSetItemPerPage,
    onSetExportSelected,
    onSetSelectedRows,
  } = props;
  const [combinequeryValue, setCombineQueryValue] = useState("");
  const [combinequeryNumber, setCombineQueryNumber] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [errorText, setErrorText] = useState("");
  const navigate = useNavigate();
  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };
  useEffect(() => {
    if (selectedCombineQuery) {
      setCombineQueryNumber(selectedCombineQuery);
    } else if (selectedCombineQueryRows.length === 1) {
      setCombineQueryNumber(selectedCombineQueryRows[0]);
    } else if (selectedCombineQueryRows.length > 1) {
      setErrorText("");
      setCombineQueryNumber(
        combinequeryNumber +
          " OR " +
          selectedCombineQueryRows[selectedCombineQueryRows.length - 1]
      );
      setCombineQueryNumber(selectedCombineQueryRows.join(" OR "));
    } else {
      setErrorText("");
      setCombineQueryNumber("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCombineQueryRows, selectedCombineQuery, isOpenCombineQueryModal]);
  const [clearAll, setClearAll] = useState(false);
  const handleClear = () => {
    setCombineQueryNumber("");
    setCombineQueryValue("");
    setClearAll(true);
    onSetCombineQueryPopup({
      isOpenCombineQueryModal: false,
    });
  };
  const handleChange = (str) => {
    setCombineQueryValue(str);
    onSetQuery({ syntaxqueryCorrect: "", syntaxqueryError: "" });
  };
  const handleNumberChange = (str) => {
    setCombineQueryNumber(str);
  };
  const queryValidation = () => {
    const queryToCheck = combinequeryValue;
    return validationQuery(queryToCheck);
  };
  const getUpdatedCombinedQuery = async () => {
    try {
      let numbers = combinequeryNumber.match(/\d+/g);
      let operators = combinequeryNumber.match(/(OR|AND|NOT)/gim);
      if (!numbers || !operators || numbers.length !== operators.length + 1) {
        onSetQuery({ syntaxqueryError: "Please check the input" });
        throw new Error(
          "Invalid query format. Unable to extract numbers or operators."
        );
      }

      // Fetch the history details for the given numbers
      let detailsArray = await getHistoryDetailsById({ ids: numbers });

      const query = constructQueries(numbers, operators, detailsArray);

      if (query[2].includes(selectedCombineQueryId)) {
        // eslint-disable-next-line no-alert
        alert("Can't add current query in combine query.");
        throw new Error("Can't add current query in combine query.");
      }

      const resultTotal = await getQueryCount(
        query[1].replaceAll("( ", "(").replaceAll(" )", ")")
      );

      return {
        query: query[1].replaceAll("( ", "(").replaceAll(" )", ")"),
        numberOfHits: resultTotal.count,
        operators: operators,
        ids: query[2],
      };
    } catch (error) {
      // Handle errors
      onSetQuery({ syntaxqueryError: "Please check the input" });
      console.error(
        "Error occurred while getting updated combined query:",
        error.message
      );
      return null;
    }
  };
  const handleSaveAsNew = async () => {
    try {
      const { query, numberOfHits, operators, ids } =
        await getUpdatedCombinedQuery();

      if (!query) {
        // Handle the case where getUpdatedCombinedQuery returns null (indicating an error)
        throw new Error("Failed to get updated combined query.");
      }
      onSetCombineQueryPopup({
        isOpenCombineQueryModal: false,
      });

      await addHistory({
        query: query,
        numberOfHits: numberOfHits,
        operators: operators,
        combineId: ids,
      });

      await onSetApp({
        queryEvaluatorText: query,
        queryEvaluatorExpertSearchError: "",
      });

      setTimeout(async () => {
        await onFetchHistory();
      }, 1000);
      onSetCombineQueryPopup({
        selectedQueryRows: [],
        selectedCombineQueryRows: [],
        selectedCombineQuery: "",
        selectedCombineQueryId: 0,
      });
    } catch (error) {
      // Handle errors
      console.error("Error occurred while saving as new:", error.message);
    }
  };
  const handleSearch = async () => {
    let numbers = combinequeryNumber.match(/\d+/g);
    let invalidFlag = false;
    numbers.map((id, index) => {
      if (selectedCombineQueryRows.includes(id) === false) {
        setErrorText(
          "Please enter search query number from selected list only !"
        );
        invalidFlag = true;
      }
    });
    if (invalidFlag === true) {
      return;
    }
    const isValid = await queryValidation();
    if (isValid !== false) {
      if (selectedCombineQueryRows.length === 1) {
        setErrorText(" Please select minimum 2 queries for combine");
      } else if (combinequeryNumber) {
        let numbers = combinequeryNumber.match(/\d+/g);
        let operators = combinequeryNumber.match(/(OR|AND|NOT)/gim);
        if (numbers.length - 1 !== operators.length) {
          setErrorText(
            "Invalid Combine Query! Please recheck the operators and query numbers."
          );
          return;
        }
        let searchQuery = [];
        let queryLists = {};
        selectedItemList.forEach((query) => {
          queryLists = { ...queryLists, [query.userSearchNumber]: query.query };
        });
        invalidFlag = false;
        let finalNumbers = [];
        // eslint-disable-next-line array-callback-return
        numbers.map((id, index) => {
          if (selectedCombineQueryRows.includes(id)) {
            finalNumbers.push(id);
            // eslint-disable-next-line no-useless-concat
            let realQuery =
              "(" +
              queryLists[id] +
              ")" +
              " " +
              (operators ? (operators[index] ? operators[index] : "") : "");
            searchQuery.push(
              realQuery.replaceAll("( ", "(").replaceAll(" )", ")")
            );
          } else {
            invalidFlag = true;
            // eslint-disable-next-line no-console
            setErrorText(
              "Please enter search query number from selected list only !"
            );
          }
        });
        searchQuery = searchQuery.join(" ");

        if (!invalidFlag) {
          onSetCombineQueryPopup({
            isOpenCombineQueryModal: false,
          });
          onSetApp({ searchQuery: searchQuery });
          const ids = [];
          selectedItemList.forEach((obj) => {
            if (finalNumbers.includes(`${obj.userSearchNumber}`)) {
              ids.push(obj.id);
            }
          });
          try {
            const data = await onPerformSearch(
              searchQuery,
              {
                isResetHighlight: true,
                isCombinedSearch: true,
                combinedIds: ids,
                operators: operators,
              },
              navigate,
              "/en/rwire-patents"
            );

            if (data) {
              onSetItemPerPage({ selectedRowsForCombine: [] });
              onSetSelectedRows({ selectedRows: [] });
              onSetExportSelected({ selectedItemList: [] });
            } else {
              console.log(props.error);
            }
          } catch (err) {
            // Handle the error appropriately here
            console.error("An error occurred:", err);
            setErrorText(`Invalid Combine Query! ${err?.message}`);
          }
        }
      }
    } else {
      return;
    }
  };
  useEffect(() => {
    setTimeout(() => {
      onSetQuery({ syntaxqueryCorrect: "", syntaxqueryError: "" });
    }, 2000);
  }, [syntaxqueryCorrect, syntaxqueryError]);
  useEffect(() => {
    const result = selectedItemList.map((item) => item.query).join(" OR ");
    setCombineQueryValue(result);
  }, [selectedItemList, isOpenCombineQueryModal]);
  useEffect(() => {
    setTimeout(() => {
      setErrorText("");
    }, 2000);
  }, [errorText]);
  return (
    <Modal
      open={isOpenCombineQueryModal}
      className="modalClassFilter combine-query-modal"
      onClose={() => {
        onSetCombineQueryPopup({
          isOpenCombineQueryModal: false,
        });
      }}
      static="static"
    >
      <div className="d-flex flex-column justify-content-center px-3 py-3">
        <div className="d-flex flex-row align-items center py-3 justify-content-between">
          <div className="popup-title">Combine</div>
          <div>
            <div
              className="popup-close"
              onClick={() => {
                onSetCombineQueryPopup({
                  isOpenCombineQueryModal: false,
                });
              }}
            >
              <CloseIcon />
            </div>
          </div>
        </div>
        <ContentEditable
          className={`query-edit-field`}
          classNameNumber={`query-number-field mb-2`}
          // value={combinequeryValue}
          selectedItemList={selectedItemList}
          selectedCombineQueryRows={selectedCombineQueryRows}
          // numberValue={combinequeryNumber}
          setCombineQueryNumber={setCombineQueryNumber}
          setCombineQueryValue={setCombineQueryValue}
        />
        <div className="d-flex flex-row justify-content-between">
          <div
            className="mt-2 d-flex flex-row align-items-center justify-content-start"
            onClick={queryValidation}
          >
            <span className="check-syntax-span cursor-pointer">
              Syntax Check
            </span>
            <input
              type="checkbox"
              className="mx-2"
              checked
              onChange={queryValidation}
            />
            {syntaxqueryCorrect ? (
              <span
                className={`
                  correct-syntax-span    
                `}
              >
                {syntaxqueryCorrect}
              </span>
            ) : (
              <span
                className={`
                  incorrect-syntax-span
                }`}
                dangerouslySetInnerHTML={{
                  __html: syntaxqueryError,
                }}
              ></span>
            )}
          </div>
          <div className="d-flex flex-row justify-content-end mt-2 align-items-center">
            <div className="mr-2 clear-btn" onClick={handleClear}>
              Clear All
            </div>
            <div className="search-query">
              <button
                className={`input-button-text-form save-btn mr-2`}
                onClick={handleSaveAsNew}
              >
                Save
              </button>
            </div>
            <div className="search-query">
              <RWireButton
                cNameDiv="search-query"
                buttonCName="input-button-text-form"
                name="Search"
                buttonImg={RWIRE_IMAGES.RwireSearchBlackIcon}
                onClick={handleSearch}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CombineQueryPopup;
