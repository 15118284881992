import jsPDF from "jspdf";
import "jspdf-autotable";
import LogoImg from "../../../assets/images/logo.png";

export const PDFExportHelper = (data, isDownload = false) => {
  var doc = new jsPDF();

  // Function to add header text
  function addHeaderText() {
    doc.addImage(LogoImg, "PNG", 12, 3, 40, 10);
    doc.setFontSize(16);
    doc.setFont(undefined, "bold");
    doc.text(`Project Name: ${data[0].projectName}`, 15, 22);
    doc.text(`Report Date: ${getCurrentDate()}`, 15, 30);
    doc.setFont(undefined, "normal");
    doc.setFontSize(12);
  }

  // Function to get current date in the format "DD/MM/YYYY"
  function getCurrentDate() {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();
    return `${dd}/${mm}/${yyyy}`;
  }

  // Function to add table
  function addTable(sheet, startY) {
    if (sheet.details && sheet.details.length > 0) {
      var tableData = [Object.keys(sheet.details[0])];
      sheet.details.forEach(function (detail) {
        tableData.push(Object.values(detail));
      });

      doc.autoTable({
        columnStyles: {
          0: {
            cellWidth: sheet.sheetName === "Relevant Search Strings" ? 140 : 40,
          },
          2: {
            cellWidth: sheet.sheetName === "Relevant Results" ? 50 : "auto",
          },
        },

        didParseCell: function (data) {
          // Remove extra spaces when rendering
          data.cell.text = data.cell.text.map(function (text) {
            return text
              .replace(/\s*\(\s*/g, "(")
              .replace(/\s*\)\s*/g, ")")
              .replace(/\s*\.\s*/g, ".")
              .trim();
          });
        },

        headStyles: {
          halign: "left",
          fillColor: "#E0E0E0",
          textColor: "#000000",
          halign: "left",
          lineWidth: 0.1,
          lineColor: "#E0E0E0",
        },

        bodyStyles: {
          lineWidth: 0.1,
          lineColor: "#E0E0E0",
        },
        tableLineColor: "#E0E0E0",
        tableLineWidth: 0.1,

        head: [tableData[0]],
        body: [...tableData.slice(1)],
        startY: startY + 20,
      });
    }

    // Return the final Y-coordinate of this table
    return doc.lastAutoTable.finalY || startY;
  }

  // Add header text
  addHeaderText();

  // Initialize startY after adding header text
  var startY = 20;

  // Add tabular data
  const filteredData = data
    .filter((sheet) => sheet.sheetName)
    .filter((sheet) => sheet.details.length > 0);
  if (filteredData.length > 0) {
    filteredData.forEach(function (sheet) {
      startY = addTable(sheet, startY);
      // Add some space between tables
      doc.text("", 20, startY + 10);
    });

    if (isDownload) {
      // Save the document with the project name as the filename
      return doc.save(`${data[0].projectName}.pdf`);
    } else {
      return doc.output("blob");
    }
  } else {
    // eslint-disable-next-line no-alert
    alert("Add Some Project Data");
  }
};
