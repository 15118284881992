import { combineReducers } from "redux";
// import { reducer as form } from "redux-form";

import app from "./app";
import chart from "./chart";
import downloadCenter from "./download-center";
import exportField from "./export";
import login from "./login";
import forgot from "./forgot";
import reset from "./reset";
import resultTable from "./result-table";
import historyTable from "./history";
import analyze from "./analyze";
import generateChart from "./generated-charts";
import searchQuery from "./search-query";
import incrementalQuery from "./incremental-query";
import number from "./number";
import classesSuggestor from "./classes-suggestor";
import keywordSuggestor from "./keyword-suggestor";
import fullView from "./rwire-view";
import note from "./note";
import highlight from "./highlight";
import editQuery from "./edit-query-popup";
import combineQuery from "./combine-query-popup";
import {
  reportClasses,
  reportNotes,
  reportKeyword,
  reportAssignee,
  reportInventor,
  reportString,
  reportRelevantResults,
  project,
  collab,
  reportDashboard,
} from "./report";

export default combineReducers({
  app,
  chart,
  downloadCenter,
  exportField,
  number,
  resultTable,
  historyTable,
  incrementalQuery,
  login,
  analyze,
  generateChart,
  searchQuery,
  forgot,
  reset,
  classesSuggestor,
  keywordSuggestor,
  fullView,
  note,
  highlight,
  editQuery,
  combineQuery,
  //Report generator reducers
  reportClasses,
  reportNotes,
  reportKeyword,
  reportAssignee,
  reportInventor,
  reportString,
  reportRelevantResults,
  project,
  collab,
  reportDashboard,
});
