import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  getProjectDashboard,
  getReportProjectRelevantResults,
  getReportProjectString,
} from "../../action/report/report-dashboard";
import { useEffect } from "react";
import {
  getReportProjectDataById,
  getReportProjectNameById,
  setProjectReport,
} from "../../action/report/report-project";
import { getReportProjectNotes } from "../../action/report/report-notes";
import {
  addUserToProject,
  getAllProjectUsers,
  removeAccessOfUserFromProject,
  updateRoleOfUserInProject,
} from "../../action/report/report-users";
import Dashboard from "../../components/report-new/dashboard";
import { performSearch, rwireSearchAPI } from "../../action/rwire-search-api";
import { setFilterFields } from "../../action/result-table";
import { setApp } from "../../action/app";
import { setAllField } from "../../action/search-query";

const Container = (props) => {
  const {
    onGetProjectDashboard,
    onGetReportProjectRelevantResults,
    onGetReportProjectNameById,
  } = props;
  const projectId = sessionStorage.getItem("projectId");

  useEffect(() => {
    const fetchData = async () => {
      if (projectId) {
        await onGetReportProjectNameById(projectId);
        await onGetProjectDashboard(projectId);
        await onGetReportProjectRelevantResults(projectId);
      }
    };
    fetchData();
  }, [
    projectId,
    onGetProjectDashboard,
    onGetReportProjectRelevantResults,
    onGetReportProjectNameById,
  ]);

  return <Dashboard {...props} />;
};

const mapStateToProps = ({
  reportDashboard,
  reportNotes: { reportTableData: keyFeaturesList = [] },
  project,
  app: { userName, smartSearchWord },
  searchQuery: { tabWiseSearchQuery },
}) => ({
  ...reportDashboard,
  keyFeaturesList,
  ...project,
  userName,
  smartSearchWord,
  tabWiseSearchQuery,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onGetProjectDashboard: getProjectDashboard,
      onGetReportProjectString: getReportProjectString,
      onGetReportProjectDataById: getReportProjectDataById,
      onSetProjectReport: setProjectReport,
      onGetReportProjectNotes: getReportProjectNotes,
      onGetReportProjectNameById: getReportProjectNameById,
      onAddUsersToProject: addUserToProject,
      onGetAllProjectUsers: getAllProjectUsers,
      onUpdateRoleOfUserInProject: updateRoleOfUserInProject,
      onRemoveAccessOfUserFromProject: removeAccessOfUserFromProject,
      onRwireSearchAPI: rwireSearchAPI,
      onSetFilter: setFilterFields,

      onSetApp: setApp,
      onPerformSearch: performSearch,
      onSetAllField: setAllField,
      onGetReportProjectRelevantResults: getReportProjectRelevantResults,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Container);
