import React, { useState } from "react";
import Select from "react-select";
import "./styles.scss";

const options = [
  { value: "AND", label: "AND" },
  { value: "OR", label: "OR" },
  { value: "NOT", label: "NOT" },
];

const ContentEditable = (props) => {
  const {
    className,
    selectedItemList,
    classNameNumber,
    selectedCombineQueryRows,
    setCombineQueryNumber,
    setCombineQueryValue,
  } = props;

  // Maintain separate state for each operator
  const [operators, setOperators] = useState(
    Array(selectedItemList.length - 1).fill({ value: "OR", label: "OR" })
  );

  const handleSelect = (event, index) => {
    const updatedOperators = [...operators];
    updatedOperators[index] = event;
    setOperators(updatedOperators);

    // Combine the queries with their respective operators
    const textResult = selectedItemList
      .map((item, i) =>
        i < updatedOperators.length
          ? `${item.query} ${updatedOperators[i].value}`
          : item.query
      )
      .join(" ");
    setCombineQueryValue(textResult);
    const numberResult = selectedCombineQueryRows
      .map((item, i) =>
        i < updatedOperators.length
          ? `${item} ${updatedOperators[i].value}`
          : item
      )
      .join(" ");
    setCombineQueryNumber(numberResult); // Update the combined rows number logic if needed
  };

  const customComponents = {
    DropdownIndicator: () => null,
    IndicatorSeparator: () => null, // optionally hide the separator as well
  };

  return (
    <>
      <span className={`${classNameNumber}`}>
        <div className="d-flex flex-row align-items-center">
          {selectedCombineQueryRows.map((item, index) => (
            <>
              <span key={`row-${index}`}>{item}</span>
              {index !== selectedCombineQueryRows.length - 1 && (
                <span className="operator-bold">
                  &nbsp;
                  {operators[index]?.value}
                  &nbsp;
                </span>
              )}
            </>
          ))}
        </div>
      </span>
      <span className={`${className}`}>
        {selectedItemList.map((item, index) => (
          <div key={`item-${index}`}>
            <span>{item.query}</span>
            <br />
            {index !== selectedItemList.length - 1 && (
              <Select
                options={options}
                className={`select-dropdown `}
                classNamePrefix="select-dropdown"
                defaultValue={operators[index] || { value: "OR", label: "OR" }}
                onChange={(event) => handleSelect(event, index)}
                placeholder={"OR"}
                value={operators[index]}
                components={customComponents}
                isSearchable={false}
              />
            )}
          </div>
        ))}
      </span>
    </>
  );
};

export default ContentEditable;
