import React, { useState, useRef, useEffect } from "react";
import "./style.scss";
import ClaimChartModalBody from "./claim-chart-modal-body";
import ClaimChartMatchingTable from "./claim-chart-matching-table";
import { BsFilter } from "react-icons/bs";
import { generate } from "./generate-docx";
import { removeTags } from "../../ui-components/functions/report-view/utils";

const ClaimChartGenerator = (props) => {
  const {
    onGetReportProjectNotes,
    reportTableData,
    onGetClaimChartMappings,
    isClaimMappingsGenerating,
    detailsData,
  } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [section, setSection] = useState("keyfeatures");
  const [keyFeatures, setKeyFeatures] = useState([]);
  const [checkedFeatures, setCheckedFeatures] = useState([]);
  const [mappings, setMappings] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [isEdited, setIsEdited] = useState(false);
  const modalRef = useRef(null);
  const headerRef = useRef(null);
  const checklistElements = ["Title", "Abstract", "Claims", "Description"];
  const shortcodeMapping = {
    Title: "TI_EN",
    Claims: "CL_EN",
    Abstract: "AB_EN",
    Description: "DESC_EN",
  };
  useEffect(() => {
    const fetchKeyFeatures = async () => {
      if (isModalOpen) {
        await onGetReportProjectNotes();
        setKeyFeatures(reportTableData);
      } else return;
    };
    fetchKeyFeatures();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen, onGetReportProjectNotes]);

  let resizing = false;
  let startX, startY;
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setSection("keyfeatures");
    setKeyFeatures([]);
    setCheckedFeatures([]);
    setCheckedItems([]);
    setIsEdited(false);
  };

  const onDrag = (event) => {
    if (!modalRef.current) return;
    if (resizing) return;
    const { movementX, movementY } = event;
    const left = modalRef.current.offsetLeft + movementX;
    const top = modalRef.current.offsetTop + movementY;
    modalRef.current.style.left = `${left}px`;
    modalRef.current.style.top = `${top}px`;
  };

  const handleMouseDown = () => {
    headerRef.current.style.cursor = "all-scroll";
    window.addEventListener("mousemove", onDrag);
  };

  const handleMouseUp = () => {
    headerRef.current.style.cursor = "default";
    window.removeEventListener("mousemove", onDrag);
  };

  const startResize = (event) => {
    resizing = true;
    startX = event.clientX;
    startY = event.clientY;
    window.addEventListener("mousemove", resizeModal);
    window.addEventListener("mouseup", stopResize);
  };

  const resizeModal = (event) => {
    if (!resizing) return;
    const newWidth = modalRef.current.offsetWidth + (event.clientX - startX);
    const newHeight = modalRef.current.offsetHeight + (event.clientY - startY);
    modalRef.current.style.width = `${newWidth}px`;
    modalRef.current.style.height = `${newHeight}px`;
    startX = event.clientX;
    startY = event.clientY;
  };

  const stopResize = () => {
    resizing = false;
    window.removeEventListener("mousemove", resizeModal);
    window.removeEventListener("mouseup", stopResize);
  };
  const handleEditClick = () => {
    if (isEdited) {
      // eslint-disable-next-line no-alert
      const isConfirmed = window.confirm(
        "Are you sure you want to go back, you will lost your edited work?"
      );

      if (isConfirmed) {
        setSection("keyfeatures");
      }
    } else {
      setSection("keyfeatures");
    }
  };
  const handleCheckboxChange = (id) => {
    if (checkedFeatures.includes(id)) {
      // If already checked, remove it
      setCheckedFeatures(
        checkedFeatures.filter((featureId) => featureId !== id)
      );
    } else {
      // If not checked, add it
      setCheckedFeatures([...checkedFeatures, id]);
    }
  };
  // Function to handle content edit
  const handleContentEdit = (id, newContent) => {
    const updatedKeyFeatures = keyFeatures.map((feature) =>
      feature.id === id ? { ...feature, body: newContent } : feature
    );
    setKeyFeatures(updatedKeyFeatures);
  };

  function generateTextOutput(checkedItems) {
    let output = "";
    checkedItems.forEach((item) => {
      if (checklistElements.includes(item)) {
        const shortcode = shortcodeMapping[item];
        const text = detailsData[shortcode];
        output += `${item}:\n${text}\n\n`;
      }
    });
    return output.trim();
  }
  const handleGenerate = async () => {
    setSection("results");
    try {
      let response = await onGetClaimChartMappings(
        keyFeatures.filter((feature) => checkedFeatures.includes(feature.id)),
        ` ${generateTextOutput(checkedItems)}`
      );
      // Check if mappings length is less than keyFeatures length
      if (response.length < keyFeatures.length) {
        // Calculate the number of missing elements
        let diff = keyFeatures.length - response.length;

        // Add "No mappings found" for missing elements
        for (let i = 0; i < diff; i++) {
          response.push("No mappings found");
        }
      }
      setMappings(response);
      setIsEdited(false);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const handleFieldCheckboxChange = (id) => {
    if (checkedItems.includes(id)) {
      // If already checked, remove it
      setCheckedItems(checkedItems.filter((featureId) => featureId !== id));
    } else {
      // If not checked, add it
      setCheckedItems([...checkedItems, id]);
    }
  };
  const handleDownload = () => {
    const tempKeyFeatures = keyFeatures.filter((feature) =>
      checkedFeatures.includes(feature.id)
    );
    const newData =
      tempKeyFeatures &&
      tempKeyFeatures.length > 0 &&
      tempKeyFeatures.map((feature, index) => {
        const row = {
          keyFeature: removeTags(feature.body.toString()) || "",
          mapping: removeTags(mappings[index].toString()) || "No mapping found",
        };
        return row;
      });
    generate(newData);
  };

  // Function to handle mapping edit
  const handleMappingEdit = (id, newContent) => {
    let updatedMappings = [...mappings];
    updatedMappings[id] = newContent;
    setMappings(updatedMappings);
    setIsEdited(true);
  };

  const handleCheckboxAll = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      const allIds = keyFeatures.map((feature) => feature.id);
      setCheckedFeatures(allIds);
    } else {
      setCheckedFeatures([]);
    }
  };
  return (
    <div className="resDrag-modal-container">
      <button
        className="ms-2 yellow-primary-button-color patent-summary-button py-1 px-3"
        onClick={openModal}
      >
       AI Claim Chart
      </button>
      {isModalOpen && (
        <div className="resDrag-modal">
          <div className="resDrag-modal-body" ref={modalRef}>
            <div className="resDrag-resize-handle" onMouseDown={startResize}>
              <BsFilter />
            </div>
            <div
              ref={headerRef}
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
              className="position-relative modal-header"
            >
              Claim Generator
              <span onClick={closeModal}>&times;</span>
            </div>
            {section === "keyfeatures" && (
              <ClaimChartModalBody
                keyFeatures={keyFeatures}
                checkedFeatures={checkedFeatures}
                onCheckboxChange={handleCheckboxChange}
                onContentEdit={handleContentEdit}
                handleCheckboxAll={handleCheckboxAll}
              />
            )}
            {section === "results" && (
              <ClaimChartMatchingTable
                keyFeatures={keyFeatures.filter((feature) =>
                  checkedFeatures.includes(feature.id)
                )}
                isClaimMappingsGenerating={isClaimMappingsGenerating}
                mappings={mappings}
                onMappingEdit={handleMappingEdit}
              />
            )}
            <div className="claim-generate-input-groups d-flex py-2 pe-2 gap-2 ps-4">
              {section === "keyfeatures" && (
                <>
                  <div className="error-message">
                    {checkedFeatures.length === 0
                      ? "Please Select at least 1 key feature"
                      : checkedItems.length === 0
                      ? "Please Select at least 1 field"
                      : ""}
                  </div>
                  Select Fields:
                  <div className="d-flex gap-3">
                    {checklistElements.map((field, i) => (
                      <div key={i} className="claim-generate-input-group">
                        <label className="m-0">
                          <input
                            type="checkbox"
                            name={field}
                            checked={checkedItems.includes(field) || false}
                            onChange={() => handleFieldCheckboxChange(field)}
                          />
                          {field}
                        </label>
                      </div>
                    ))}
                  </div>
                </>
              )}
              <button
                onClick={closeModal}
                className="footer-btn white-secondary-button-color ms-auto"
              >
                Close
              </button>
              {section === "keyfeatures" && (
                <button
                  onClick={handleGenerate}
                  className={`footer-btn blue-primary-button-color border ${
                    checkedFeatures.length === 0 || checkedItems.length === 0
                      ? "opacity-75"
                      : ""
                  }`}
                  disabled={
                    checkedFeatures.length === 0 || checkedItems.length === 0
                  }
                >
                  Generate
                </button>
              )}
              {section === "results" && (
                <button
                  onClick={handleEditClick}
                  className="footer-btn  white-secondary-button-color"
                  disabled={isClaimMappingsGenerating}
                >
                  Edit
                </button>
              )}
              {section === "results" && (
                <button
                  className="footer-btn blue-primary-button-color border"
                  onClick={handleDownload}
                  disabled={isClaimMappingsGenerating}
                >
                  Download
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClaimChartGenerator;
