import React, { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import ContentEditableInput from "./ContentEditableInput";
import SortableTable from "./sortable-table";
import { arrayMove } from "react-sortable-hoc";
import { updateSortOrder } from "../../ui-components/functions/notes/utils";
import { ReportKeywordModal } from "../../container/report";
import ReportSynonymsSuggestorModal from "../../container/report/report-synonyms-suggestor-modal";

const KeywordSection = (props) => {
  const {
    reportTableData,
    onSetReportKeyword,
    onUpdateKeywordsSortOrder,
    onUpdateReportProjectKeywordsRow,
    onAddRowToreportProject,
    onDeleteRowFromReportProject,
    onGetReportProjectKeywords,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [showSynonymModal, setShowSynonymModal] = useState(false);
  const [items, setItems] = useState(reportTableData);

  useEffect(() => {
    setItems(reportTableData);
  }, [reportTableData]);

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    onSetReportKeyword({
      reportTableData: arrayMove(reportTableData, oldIndex, newIndex),
    });
    const bodyData = updateSortOrder(reportTableData, oldIndex, newIndex);
    await onUpdateKeywordsSortOrder(bodyData);
  };

  const handleInputChange = (event, rowIndex, options) => {
    const value = event.target.value;
    const updatedTableData = reportTableData.map((i) => {
      if (i.id === rowIndex && options.isChangeText) {
        return {
          ...i,
          title: value,
        };
      } else if (i.id === rowIndex && options.isChangeDesc) {
        return {
          ...i,
          body: value,
        };
      }
      return {
        ...i,
      };
    });

    onSetReportKeyword({
      reportTableData: [...updatedTableData],
    });
  };
  const handleSaveInDb = async (value, rowIndex, idx, options) => {
    if (options.isChangeText) {
      await onUpdateReportProjectKeywordsRow({
        id: rowIndex,
        body: {
          title: value,
        },
      });
    } else if (options.isChangeDesc) {
      await onUpdateReportProjectKeywordsRow({
        id: rowIndex,
        body: {
          body: value,
        },
      });
    }
  };
  const handleAddRow = async (rowIndex) => {
    const _id = sessionStorage.getItem("projectId");
    await onAddRowToreportProject({
      data: [
        {
          title: "",
          body: "",
          order: parseInt(`${rowIndex + 1}`),
        },
      ],
      projectId: parseInt(_id),
    });

    await onGetReportProjectKeywords();
  };

  const handleDeleteRow = async (id) => {
    if (reportTableData.length === 1) {
      return;
    }

    await onDeleteRowFromReportProject(id);
    await onGetReportProjectKeywords();
  };

  const renderContent = (item, onChange, onSaveInDb, idx) => {
    return (
      <>
        <td className="column-1">
          <ContentEditableInput
            text={item.title}
            onCellChange={onChange}
            rowIndex={item.id}
            isChangeText={true}
            onSaveInDb={onSaveInDb}
            idx={idx}
            cellClassName="w-100"
          />
        </td>
        <td className="column-2">
          <ContentEditableInput
            text={item.body}
            onCellChange={onChange}
            rowIndex={item.id}
            isChangeDesc={true}
            onSaveInDb={onSaveInDb}
            idx={idx}
            cellClassName="w-100"
          />
        </td>
      </>
    );
  };

  return (
    <>
      <div className="keyword-section border">
        <div className="d-flex justify-content-between align-items-center">
          {/* <div className="search-bar p-0 fs-14 position-relative">
            <input
              type="text"
              className="ps-2 pe-3"
              placeholder="Type keyword here for suggestions"
            />
            <FaSearch className="search-button position-absolute" />
          </div> */}
          <button
            className="keyword-generator fs-14 px-3"
            onClick={() => setShowSynonymModal(true)}
          >
            Keyword Suggestion
          </button>
          <button
            className="keyword-generator fs-14 px-3"
            onClick={() => setIsOpen(true)}
          >
            Keyword Generator
          </button>
        </div>
        <SortableTable
          items={items}
          onSortEnd={onSortEnd}
          renderContent={renderContent}
          modal={ReportKeywordModal}
          isModalOpen={isOpen}
          setModalOpen={setIsOpen}
          onChange={handleInputChange}
          onSaveInDb={handleSaveInDb}
          workspaceId={2}
          onDelete={handleDeleteRow}
          onAddRow={handleAddRow}
          headers={["Keywords", "Synonyms"]}
        />
        {showSynonymModal && (
          <ReportSynonymsSuggestorModal
            isOpen={showSynonymModal}
            onClose={() => setShowSynonymModal(false)}
          />
        )}
      </div>
    </>
  );
};

export default KeywordSection;
