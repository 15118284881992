import React, { useEffect, useState } from "react";
import { FaSearch, FaUser } from "react-icons/fa";
import ProjectDropdown from "../../container/report/project-dropdown";
import { useNavigate } from "react-router-dom";
import { AiOutlineUserAdd } from "react-icons/ai";
const MetricCard = ({ metricId, label, onClick, isActive = false, counts }) => {
  const number = counts?.[metricId]?.count || 0; // Default to 0 if count doesn't exist
  return (
    <div
      className={`col ${isActive ? "" : "inactive-tab"} cursor-pointer`}
      onClick={onClick}
    >
      <div className="card h-100">
        <div className="card-body">
          <p className="rw-card-title">{number}</p>
          <p className="card-text m-0">{label}</p>
        </div>
      </div>
    </div>
  );
};
const smartSearchField = "SS";
const Header = (props) => {
  const {
    section = "first",
    setSection,
    keyFeature,
    relevantResult,
    string,
    keywords,
    classes,
    inventor,
    assignee,

    onSetApp,
    smartSearchWord,
    onPerformSearch,
    onSetAllField,
    tabWiseSearchQuery,
    projectUsers,
  } = props;
  const [displayValue, setDisplayValue] = useState(smartSearchWord);
  const navigate = useNavigate();
  useEffect(() => {
    if (smartSearchWord) {
      setDisplayValue(smartSearchWord);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smartSearchWord]);
  const citation = { count: props?.citedReferences?.length };
  const metrics = [
    { label: "Key features", id: "keyFeature" },
    { label: "Results", id: "relevantResult" },
    { label: "Strings", id: "string" },
    { label: "Citations", id: "citation" },
    { label: "Keywords", id: "keywords" },
    { label: "Classes", id: "classes" },
    { label: "Inventors", id: "inventor" },
    { label: "Assignees", id: "assignee" },
  ];

  const firstSection = metrics.slice(0, 2); // First 2 cards
  const secondSection = metrics.slice(2, 4); // Next 2 cards
  const thirdSection = metrics.slice(4); // Last 4 cards

  const handleSearch = async () => {
    localStorage.removeItem("tableScrollPosition");
    if (displayValue) {
      const querySearch = `${smartSearchField}=(${displayValue})`;

      onSetApp({
        smartSearchWord: displayValue,
        searchQuery: querySearch,
        isSearchFromIncremental: false,
      });
      onSetAllField({
        tabWiseSearchQuery: { ...tabWiseSearchQuery, fielded: querySearch },
      });
      onSetApp({ selectedIncludes: [] });
      await onPerformSearch(
        querySearch,
        { isResetHighlight: true },
        navigate,
        "/en/rwire-patents"
      ).then((data) => {
        if (!data) {
          // eslint-disable-next-line no-console
          console.log("Error:", props.error);
        }
      });
    }
  };

  const handleClick = async () => {
    await handleSearch();
  };

  const handleEnter = async (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      if (!displayValue) {
        return false;
      }

      await handleSearch();
    }

    return;
  };

  const accessMapping = {
    ACCESS_EDITOR: "Editor",
    ACCESS_COMMENTOR: "Commentor",
    ACCESS_VIEWER: "Viewer",
  };

  const getCustomInitials = (name = "") => {
    const parts = name.split(" ");

    // For multiple words, take first letter of the first word and first letter of second word
    if (parts.length > 1) {
      return (
        parts[0].charAt(0).toUpperCase() + parts[1].charAt(0).toUpperCase()
      );
    } else {
      return (
        parts[0].charAt(0).toUpperCase() + parts[0].charAt(1).toUpperCase()
      );
    }
  };

  return (
    <>
      <>
        <nav className="navbar navbar-light">
          <div className="container-fluid">
            <div className="home-page-breadcrumb fs-14">
              <ProjectDropdown />
            </div>
            <form className="d-flex flex-grow-1 mx-4">
              <div className="input-group">
                <input
                  className="form-control smart-search mt-0 px-2 fs-16 py-1 text-dark"
                  type="search"
                  placeholder="Smart Search"
                  aria-label="Search"
                  value={displayValue}
                  onChange={(e) => setDisplayValue(e.target.value)}
                  onKeyDown={handleEnter}
                />
                <button
                  className="btn btn-outline-secondary shadow-none z-0 px-2 py-1"
                  onClick={handleClick}
                >
                  <FaSearch />
                </button>
              </div>
            </form>
            <div className="d-flex align-items-center gap-5">
              <div className="circle-group d-flex align-items-center justify-content-center">
                {projectUsers &&
                  projectUsers.length > 0 &&
                  projectUsers.map((user) => (
                    <div
                      className="circle bg-primary text-white circle-1"
                      title={user.userName}
                    >
                      {getCustomInitials(user.userName)}
                    </div>
                  ))}
              </div>
              <div className="d-flex align-items-center fw-bold">
                <button
                  className="user-icon"
                  onClick={() => navigate("/en/report/users")}
                  title="Add Users"
                >
                  <AiOutlineUserAdd />
                </button>
                <span className="ms-2" title="Project Access">
                  {accessMapping[sessionStorage.getItem("userProjectAccess")]}
                </span>
              </div>
            </div>
          </div>
        </nav>
        <div className="metrics-container">
          <div className="row row-cols-2 row-cols-md-4 row-cols-lg-7 g-3">
            {firstSection.map((metric, index) => (
              <MetricCard
                key={metric.id}
                metricId={metric.id}
                label={metric.label}
                onClick={() => setSection("first")}
                isActive={section === "first"}
                counts={{
                  keyFeature,
                  relevantResult,
                  string,
                  citation,
                  keywords,
                  classes,
                  inventor,
                  assignee,
                }}
              />
            ))}
            <div className="col px-0 vertical-line"></div>{" "}
            {/* Vertical Divider */}
            {secondSection.map((metric, index) => (
              <MetricCard
                key={metric.id}
                metricId={metric.id}
                label={metric.label}
                onClick={() => setSection("second")}
                isActive={section === "second"}
                counts={{
                  keyFeature,
                  relevantResult,
                  string,
                  citation,
                  keywords,
                  classes,
                  inventor,
                  assignee,
                }}
              />
            ))}
            <div className="col px-0 vertical-line"></div>{" "}
            {/* Vertical Divider */}
            {thirdSection.map((metric, index) => (
              <MetricCard
                key={metric.id}
                metricId={metric.id}
                label={metric.label}
                onClick={() => setSection("third")}
                isActive={section === "third"}
                counts={{
                  keyFeature,
                  relevantResult,
                  string,
                  citation,
                  keywords,
                  classes,
                  inventor,
                  assignee,
                }}
              />
            ))}
          </div>
        </div>
      </>
    </>
  );
};

export default Header;
