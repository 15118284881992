import React, { useState } from "react";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import {
  MinusBlackIcon,
  PlusBlackIcon,
  RightBlackIcon,
  SixDots,
} from "../../assets/images/report";
import { Image, SlidingSidePanel } from "../../ui-components";
import ReportCollaboration from "../../container/report/report-collaboration";

const DragHandle = SortableHandle(() => (
  <td className="sortable-dots cursor-grab drag-icon drag-column" tabIndex={0}>
    <Image width={20} height={20} src={SixDots} className="mx-auto" />
  </td>
));

const ActionHandle = ({
  id = null,
  setCommentingRowID,
  onDelete,
  onAddRow,
  rowIndex,
}) => (
  <>
    <td
      className="sortable-dots px-0 cursor-pointer drag-icon drag-column text-center"
      tabIndex={0}
      onClick={() => setCommentingRowID(id)}
      title="Collaboration"
    >
      <Image width={14} height={14} src={RightBlackIcon} className="mx-0" />
    </td>
    <td
      className="sortable-dots px-0 cursor-pointer drag-icon drag-column text-center"
      tabIndex={0}
      onClick={() => onAddRow(rowIndex)}
      title="Add row"
    >
      <Image width={16} height={16} src={PlusBlackIcon} className="mx-0" />
    </td>
    <td
      className="sortable-dots px-0 cursor-pointer drag-icon drag-column text-center"
      tabIndex={0}
      onClick={() => onDelete(id)}
      title="Delete row"
    >
      <Image width={16} height={16} src={MinusBlackIcon} className="mx-0" />
    </td>
  </>
);

const SortableItem = SortableElement((props) => {
  const {
    value,
    renderContent,
    onChange,
    onSaveInDb,
    idx,
    commentingRowID,
    setCommentingRowID,
    workspaceId = 1,
    onDelete,
    onAddRow,
    rowIndex,
  } = props;
  return (
    <tr>
      <DragHandle />
      {renderContent(value, onChange, onSaveInDb, idx)}
      <ActionHandle
        id={value?.id}
        setCommentingRowID={setCommentingRowID}
        onDelete={onDelete}
        onAddRow={onAddRow}
        rowIndex={rowIndex}
      />
      <SlidingSidePanel
        isOpen={value?.id === commentingRowID ? true : false}
        onClose={() => setCommentingRowID(null)}
        id="report-collab-modal"
      >
        <ReportCollaboration
          commentingRowID={commentingRowID}
          onClose={() => setCommentingRowID(null)}
          workspaceId={workspaceId}
        />
      </SlidingSidePanel>
    </tr>
  );
});

const SortableList = SortableContainer(
  ({
    items,
    renderContent,
    onChange,
    onSaveInDb,
    workspaceId,
    onDelete,
    onAddRow,
  }) => {
    const [commentingRowID, setCommentingRowID] = useState(null);
    return (
      <tbody>
        {items &&
          items.length > 0 &&
          items.map((item, index) => (
            <SortableItem
              key={`item-${item?.id}`}
              index={index}
              value={item}
              renderContent={renderContent}
              onChange={onChange}
              onSaveInDb={onSaveInDb}
              idx={parseInt(item?.sortOrder)}
              commentingRowID={commentingRowID}
              setCommentingRowID={setCommentingRowID}
              workspaceId={workspaceId}
              onDelete={onDelete}
              onAddRow={onAddRow}
              rowIndex={index + 1}
            />
          ))}
      </tbody>
    );
  }
);

const SortableTable = ({
  items,
  onSortEnd,
  renderContent,
  modal: Modal,
  isModalOpen,
  setModalOpen,
  onChange,
  onSaveInDb,
  onDelete,
  onAddRow,
  headers = ["Column 1", "COlumn 2"],
}) => {
  return (
    <>
      <div className="border rounded dashboard-table-height">
        <table className="keyword-table fs-16">
          <thead>
            <tr>
              <th className="drag-column"></th>
              <th className="column-1">{headers[0]}</th>
              <th className="column-2">{headers[1]}</th>
              <th className="drag-column"></th>
              <th className="drag-column"></th>
              <th className="drag-column"></th>
            </tr>
          </thead>
          <SortableList
            items={items}
            lockAxis="y"
            lockToContainerEdges={true}
            onSortEnd={onSortEnd}
            useDragHandle
            pressDelay={100}
            renderContent={renderContent}
            onChange={onChange}
            onSaveInDb={onSaveInDb}
            onDelete={onDelete}
            onAddRow={onAddRow}
          />
        </table>
      </div>
      {isModalOpen && (
        <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
      )}
    </>
  );
};

export default SortableTable;
