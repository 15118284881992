import { queryHighlightWordsColors } from "../resources/data/highlight-colors";

const initialState = {
  itemsPerPage: 50,
  pageSize: 50,
  totalPage: 1,
  limit: 50,
  isDetailsOpen: false,
  detailsData: [],
  totalRecordsCount: 0,
  dataSize: 50,
  dataFrom: 0,
  selectedRows: [],
  displayFieldsOnResultTable: [
    "PN_B",
    "TI_EN",
    "AB_EN",
    "EPRD",
    "AS_EN",
    "score",
    "AN_B",
  ],
  includeFieldsOnResult: [
    "PN_B",
    "TI_EN",
    "AB_EN",
    "ALD",
    "PD",
    "AD",
    "EPRD",
    "CA_EN",
    "CA_FR",
    "CA_JP",
    "CA_KR",
    "CA_DE",
    "CA_ES",
    "CA_CN",
    "IN_EN",
    "IN_FR",
    "IN_JP",
    "IN_KR",
    "IN_DE",
    "IN_ES",
    "IN_CN",
    "PNC",
    "PRC",
    "AAPN",
    "AAPS",
    "AAPO",
    "AN_B",
    "AN_O",
    "AN_D",
    "AN_DA",
    "AN_EPO",
    "AN_T",
    "AN_L",
    "PRN_B",
    "PRN_O",
    "PRN_D",
    "PRN_DA",
    "PRN_EPO",
    "PRN_T",
    "PRN_L",
    "PKC",
    "ED",
    "PTS",
    "AO_EN",
    "AO_FR",
    "AO_JP",
    "AO_KR",
    "AO_DE",
    "AO_ES",
    "AO_CN",
    "AS_EN",
    "AS_FR",
    "AS_JP",
    "AS_KR",
    "AS_DE",
    "AS_ES",
    "AS_CN",
    "ANZ_EN",
    "ANZ_FR",
    "ANZ_JP",
    "ANZ_KR",
    "ANZ_DE",
    "ANZ_ES",
    "ANZ_CN",
  ],
  filterFields: ["CPC", "PRY", "AS_EN"],
  esHighlightwords: [],
  highlightword: [],
  displayData: [],
  sortBy: "_score",
  sortType: "desc",
  stdAssigneeNameSelected: [],
  inventorNameSelected: [],
  ipcSelected: [],
  cpcSelected: [],
  usClassSelected: [],
  ipcCpcSelected: [],
  priorityCountrySelected: [],
  publicationCountrySelected: [],
  lapseYearSelected: [],
  publicationYearSelected: [],
  publicationStateSelected: [],
  agentNameSelected: [],
  applicationYearSelected: [],
  filterOptionsData: {},
  assigneeCountrySelected: [],
  usSeriesCodeSelected: [],
  applicationCountrySelected: [],
  originalAssigneeSelected: [],
  standardizedAssigneeSelected: [],
  originalAssigneeFirstEngSelected: [],
  assigneeNormalizedSelected: [],
  botanicLatinNameSelected: [],
  botanicVarietySelected: [],
  usClassfurtherClassificationSelected: [],
  LCMSelected: [],
  LCFSelected: [],
  CPCPSelected: [],
  CPC12Selected: [],
  CPC8Selected: [],
  CPC4Selected: [],
  CPCVSelected: [],
  CPCOSelected: [],
  ECLDSelected: [],
  JCLSelected: [],
  IPCDSelected: [],
  IPC12Selected: [],
  IPC8Selected: [],
  IPC4Selected: [],
  IPCRVSelected: [],
  CAS_ENSelected: [],
  CAN_ENSelected: [],
  DSEP_CSTSelected: [],
  DSEP_ESTSelected: [],
  DSEP_VSTSelected: [],
  DSEP_PSTSelected: [],
  DSEPSelected: [],
  DSPCT_RGCNSelected: [],
  DSPCT_AOSTSelected: [],
  DSPCT_RGNCNSelected: [],
  DSPCT_NTSelected: [],
  DSPCT_NDSCNSelected: [],
  DSPCTSelected: [],
  AEXSelected: [],
  PEXSelected: [],
  APFO_ENSelected: [],
  APFI_ENSelected: [],
  CR_ENSelected: [],
  INF_ENSelected: [],
  EPRYSelected: [],
  PRYSelected: [],
  LegalStateSelected: [],
  LegalStatusSelected: [],
  PublicationTypeSelected: [],
  AAPOSelected: [],
  AAPSSelected: [],
  AAPNSelected: [],
  APTSelected: [],
  openedFilterName: "",
  filtersSelected: {},
  filterList: [
    "standardized_assignee_name",
    "inventor_name",
    "ipc",
    "cpc",
    "us_class",
    "priority_country",
    "publication_country",
    // "lapse_year",
    "publication_year",
    "agent_name",
    "application_year",
  ],
  totalApplicationCount: 0,
  clickedOnClear: false,
  patentInformationList: [
    "publication_date",
    "application_date",
    "Earliest_Priority_Date",
    "Current Assignee",
    "Inventor",
    // "Jurisdiction", DO NOT REMOVE
    "Publication Country",
    "Priority Country",
  ],
  patentReamainingList: [
    "publication_no",
    "patent_information",
    "title",
    "abstract",
  ],
  filtersSearchText: {},
  isViewPageModalOpen: false,
  isSmartReaderOpen: false,
  highlighterkeywords: [],
  totalHighlightedWords: [],
  activePages: 1,
  isExact: true,
  aggregations: [],
  queryKeywordsHighlightColor: queryHighlightWordsColors,
  isLoadingFilterData: false,
  isLoadingResult: false,
  classesTableData: {
    ipc: [],
    cpc: [],
    us: [],
    ecla: [],
  },
  columnWidths: {},
  selectedFamily: null,
  quickViewHighlights: [],
  isAggregationCountsLoading: false,
  sizeLimit: {},
};

const resultTable = (state = initialState, { type, payload }) => {
  switch (type) {
    case "RESULT_SET":
      return {
        ...state,
        ...payload,
      };
    case "RESET_RESULT_SET":
      const newState = {};
      for (const key in initialState) {
        if (payload.includes(key)) {
          newState[key] = state[key];
        } else {
          newState[key] = initialState[key];
        }
      }
      return newState;
    default:
      return state;
  }
};

export default resultTable;
