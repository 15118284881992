/* eslint-disable no-console */
import { displayError } from "../../service/display-error";
import { classes_suggestor, filters_options } from "../../components/constant";
import { search } from "../../service/elasticSearch";
import { fetch } from "../../service/fetch";
import { getProjectDashboard } from "./report-dashboard";
export const setReportClasess = (payload) => ({
  type: "CLASSES_SET",
  payload: {
    ...payload,
  },
});

export const getPagination =
  (total, limit, page = 1) =>
  (dispatch) => {
    const totalPage = Math.ceil(total / limit);
    dispatch(setReportClasess({ totalPage, limit }));
  };

export const setPage = (page) => (dispatch) => {
  dispatch(setReportClasess({ page }));
};

export const reportClasessSearch = () => async (dispatch, getState) => {
  const {
    reportClasses: { formData = {}, classesType, dataFrom, limit },
  } = getState();
  let queryObj = {};
  let keys = [];
  try {
    if (formData.keywords) {
      let ctbSearchObj = {
        queryToSearch: `TAC=(${formData.keywords})`,
        isNumberWithIncludeSearch: false,
        selectedIncludes: [],
        fields: [`${classesType.toUpperCase()}D`],
        filtersSearchText: {
          [`${classesType.toUpperCase()}D`]: formData.classes
            ? formData.classes
            : "",
        },
        collapsableField: "PN_B",
        filters: [],
      };
      const ctbSearchBody = JSON.stringify(ctbSearchObj);
      const ctbSearchData = await search(ctbSearchBody, filters_options);
      keys = ctbSearchData?.data?.aggregations?.[
        `${classesType.toUpperCase()}D`
      ]?.buckets?.map((item) => item.key);
    }

    queryObj = {
      classesAndkeywords: {
        ...formData,
        classes: formData.keywords ? keys.join(" or ") : formData.classes,
      },
      classesType,
      dataFrom,
      dataSize: limit,
    };
    const body = JSON.stringify(queryObj);
    const data = await search(body, classes_suggestor);
    const dataResponse = data.data;
    const tableData = dataResponse.hits.hits;
    const total = dataResponse.hits.total.value;

    dispatch(
      setReportClasess({
        clasessData: tableData,
        totalRecordsCount: total,
        isNoResultFound: tableData.length > 0 ? false : true,
      })
    );
    return tableData;
  } catch (error) {
    displayError(error);
  }
};

export const getReportProjectClasses = () => async (dispatch) => {
  try {
    var response = {};
    const id = sessionStorage.getItem("projectId");
    response = await fetch(
      {
        url: `/api/project-classes/project/${id}`,
      },
      "GET"
    );
    const outputObject = {};

    response.result.forEach((item) => {
      const type = item.type.toLowerCase();

      if (!outputObject[type]) {
        outputObject[type] = [];
      }
      const newItem = { ...item, title: item.classes };
      delete newItem.classes;
      outputObject[type].push(newItem);
    });

    dispatch(
      setReportClasess({
        reportTableData: outputObject,
      })
    );
  } catch (error) {
    console.log("error>>>", error);
  }
};

export const updateReportProjectClassesRow =
  ({ id, body }) =>
  async (dispatch) => {
    try {
      await fetch(
        {
          url: `/api/project-classes/${id}`,
          body,
        },
        "PUT"
      );
      dispatch(getProjectDashboard());
    } catch (error) {
      console.log("error>>>", error);
    }
  };

export const updateReportProjectClassesSortOrder =
  (body) => async (dispatch) => {
    try {
      await fetch(
        {
          url: `/api/project-classes/sort-order`,
          body,
        },
        "POST"
      );
    } catch (error) {
      console.log("error>>>", error);
    }
  };

export const addRowToClassesReportProject = (body) => async (dispatch) => {
  try {
    await fetch(
      {
        url: `/api/project-classes`,
        body,
      },
      "POST"
    );
    dispatch(getProjectDashboard());
  } catch (error) {
    console.log("error>>>", error);
  }
};

export const deleteRowClassesFromReportProject = (id) => async (dispatch) => {
  try {
    await fetch(
      {
        url: `/api/project-classes/${id}`,
      },
      "DELETE"
    );
    dispatch(getProjectDashboard());
  } catch (error) {
    console.log("error>>>", error);
  }
};
