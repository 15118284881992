import CombineQueryPopup from "../../components/combine-query-popup/combine-query-popup";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setApp } from "../../action/patentAndPublication";
import { setCombineQuery } from "../../action/combine-query-popup";
import {
    validationQuery,
    performSearch,
} from "../../action/rwire-search-api";
import { setAllField, setQuery } from "../../action/search-query";
import {
    setSelectedRows,
    setHistory,
    fetchHistory,
    updateQueryCount,
} from "../../action/history";
const Container = (props) => {
    return <CombineQueryPopup {...props} />
}
const mapStateToProps = ({
    combineQuery,
    app: {
        editQuery: query,
    },
    searchQuery: { syntaxqueryError, syntaxqueryCorrect },
    historyTable: { selectedItemList }
}) => ({
    query,
    ...combineQuery,
    syntaxqueryError,
    syntaxqueryCorrect,
    selectedItemList
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            onSetApp: setApp,
            onSetCombineQueryPopup: setCombineQuery,
            onPerformSearch: performSearch,
            validationQuery: validationQuery,
            onSetSelectedRows: setSelectedRows,
            onSetExportSelected: setHistory,
            onSetItemPerPage: setHistory,
            onUpdateQueryCount: updateQueryCount,
            onFetchHistory: fetchHistory,
            onSetQuery: setQuery,
            onSetAllField: setAllField,
            onSetItemPerPage: setHistory
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Container);