export const keywordsMapping = {
  Keywords: "title",
  Synonyms: "body",
};
export const cpcMapping = {
  CPC: "classes",
  Definition: "body",
};
export const ipcMapping = {
  IPC: "classes",
  Definition: "body",
};
export const usMapping = {
  US: "classes",
  Definition: "body",
};
export const inventorsMapping = {
  Inventors: "body",
};
export const assigneesMapping = {
  Assignees: "body",
};
export const keyFeaturesMapping = {
  "Key Features": "index",
  "Definition": "body",
};
export const stringsMapping = {
  Strings: "body",
  Count: "publicationCount/applicationCount",
};

export const keyFeaturePatentsMapping = {
  "Relevant Results": "relevance",
  Definition: "Defination",
  Patents: "patents",
};

export const patentKeyFeatureMapping = {
  "Publication Number": "publicationNumber",
  "Available Key Features No.": "availableKeyFeatures",
  "Not Available Key Features No.": "notAvailableKeyFeatures",
};

export const relevantResultsMapping = {
  "Publication Number": "patentNumber",
  "Publication Information": "publicationInfo",
  "Title": "title",
  "Comments": "comments",
  "Checklist": "checklist", 
};

export const citationMapping = {
  "Sr.No": "srNo",
  "Publication Number": "PN",
  "Pub date": "PD",
  "App date": "AD",
  "Priority": "EPRD",
  "Current Assignee": "CA",
  "Inventors": "IN",
};

